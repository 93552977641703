import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../../RoomContext';
import { showNumberWithComma } from '../../../../../utils';
import { CONSUMPTION_TAX } from '../../../../../const';
import Box from '@material-ui/core/Box';

// Message
import { FormattedMessage } from 'react-intl';

// components
import TicketExpiryText from './TicketExpiryText';

const styles = () =>
	({
		purchaseItemLine : {
			display  : 'flex',
			minWidth : '300px'
		},
		purchaseItemCell : {
			border      : '1px solid #9E9E9E',
			paddingLeft : '3px',
			fontFamily  : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
		},
		purchaseItemCellSecond : {
			borderLeft : 'none'
		},
		purchaseItemCellAbove : {
			borderBottom : 'none'
		},
		ticketInfoLeft : {
			width    : '25%',
			maxWidth : '250px'
		},
		ticketInfoRight : {
			width    : '75%',
			maxWidth : '750px'
		}
	});

const TicketInfoTable = ({
	classes,
	ticket
}) =>
{

	return (
		<>
			<Box className={classes.purchaseItemLine}>
				<Box
					className={`${classes.purchaseItemCell} ${classes.purchaseItemCellAbove} ${classes.ticketInfoLeft}`}
				>
					<FormattedMessage
						id='paidservice.validTime'
						defaultMessage='Time plan'
					/>
				</Box>
				<Box
					className={`${classes.purchaseItemCell} ${classes.purchaseItemCellSecond} ${classes.purchaseItemCellAbove} ${classes.ticketInfoRight}`}
				>
					{ (ticket.minutes === -1 || ticket.duration === -1) ?
						<FormattedMessage
							id='paidservice.unlimited'
							defaultMessage='Unlimitd'
						/>
						:
						<FormattedMessage
							id='paidservice.time'
							defaultMessage='{minutesPurchased} minutes'
							values={{
								minutesPurchased : ticket.minutes
							}}
						/>
					}
				</Box>
			</Box>
			<Box className={classes.purchaseItemLine}>
				<Box
					className={`${classes.purchaseItemCell} ${classes.purchaseItemCellAbove} ${classes.ticketInfoLeft}`}
				>
					<FormattedMessage
						id='paidservice.period'
						defaultMessage='Expiration'
					/>
				</Box>
				<Box
					className={`${classes.purchaseItemCell} ${classes.purchaseItemCellSecond}  ${classes.purchaseItemCellAbove} ${classes.ticketInfoRight}`}
				>
					<TicketExpiryText
						expiryTerm={ticket.expiryTerm}
						expiryTermAfterUse={ticket.expiryTermAfterUse}
						singleRow={false}
					/>
				</Box>
			</Box>
			<Box className={classes.purchaseItemLine}>
				<Box
					className={`${classes.purchaseItemCell} ${classes.ticketInfoLeft}`}
				>
					<FormattedMessage
						id='paidservice.purchaseFee'
						defaultMessage='price'
					/>
				</Box>
				<Box
					className={`${classes.purchaseItemCell} ${classes.purchaseItemCellSecond} ${classes.ticketInfoRight}`}
				>
					<FormattedMessage
						id='paidservice.fee'
						defaultMessage='{price} yen ({includeTax} yen tax included)'
						values={{
							price : showNumberWithComma(
								ticket.salesAmount
							),
							includeTax : showNumberWithComma(
								Math.floor(
									ticket.salesAmount*(1+CONSUMPTION_TAX)
								)
							)
						}}
					/>
				</Box>
			</Box>
		</>
	);
};

TicketInfoTable.propTypes =
{
	classes : PropTypes.object.isRequired,
	ticket  : PropTypes.object.isRequired
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.room.monitoring === next.room.monitoring &&
				prev.room.monitoringTimer === next.room.monitoringTimer
			);
		}
	}
)(withStyles(styles)(TicketInfoTable)));
