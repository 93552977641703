// React
import React, { useState } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// Firestore
import { auth, firestore, functions } from '../../../lib/firebase';

// util
import { getDomainWithoutSubdomain } from '../../../utils';

const styles = (theme) =>
	({
		root : {
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper : {
			padding                        : '1% 2%',
			width                          : '20vw',
			[theme.breakpoints.down('lg')] : {
				width : '45vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '65vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '80vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		titleIcon : {
			marginBottom : '1%',
			fontSize     : '2.5rem'
		},
		titleText : {
			color      : '#999898',
			fontSize   : '1rem',
			fontWeight : 'bold'
		},
		messageGroup : {
			width         : '100%',
			paddingBottom : '1rem',
			alignItems    : 'center'
		},
		infoGroup : {
			width          : '100%',
			display        : 'flex',
			paddingBottom  : '1rem',
			alignItems     : 'center',
			justifyContent : 'space-around',
			position       : 'relative'
		},
		btnGroup : {
			width      : '90%',
			marginLeft : '10%',
			marginTop  : '8px'
		},
		explanationBox :
		{
			marginBottom : '25px'
		},
		explanationText :
		{
			color    : '#999898',
			fontSize : '0.8rem'
		},
		contentText :
		{
			width    : '30%',
			color    : '#999898',
			fontSize : '0.8rem'
		},
		messageText :
		{
			width     : '100%',
			color     : '#999898',
			fontSize  : '0.8rem',
			textAlign : 'center'
		},
		input : {
			width        : '70%',
			background   : '#999898',
			padding      : '0.4rem 0.6rem',
			borderRadius : '0.3rem',
			color        : 'white',
			textAlign    : 'left',
			border       : 'none',
			'&:focus'    : {
				outline : 'none'
			},
			'&:disabled' : {
				color : '#F1F1F1'
			},
			'&::-ms-reveal' : {
				display : 'none'
			}
		},
		visibilityButton : {
			position  : 'absolute',
			top       : '0',
			bottom    : '16px',
			right     : '-6px',
			color     : '#FFF',
			'&:hover' : {
				backgroundColor : 'transparent'
			}
		},
		circle : {
			width           : '4rem',
			height          : '4rem',
			borderRadius    : '4rem',
			backgroundColor : '#999898',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			margin          : '0.5rem 0'
		},
		buttonWrapper :
		{
			width          : '14rem',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		button : {
			width           : '4rem',
			height          : '4rem',
			borderRadius    : '4rem',
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : '#8eba14',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#f50057'
			}
		},
		backButton : {
			width           : '4rem',
			height          : '4rem',
			borderRadius    : '4rem',
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : 'var(--button-color)',
			color           : 'var(--text-color)',
			border          : 'solid 1px #dddede',
			'&:hover'       : {
				backgroundColor : 'var(--next-button-color)',
				color           : '#fff'
			}
		},
		forgotPasswordLabel :
		{
			fontSize     : '0.6rem',
			color        : '#999898',
			textAlign    : 'center',
			marginBottom : '16px'
		},
		link :
		{
			color  : 'var(--submit-button-color)',
			cursor : 'pointer'
		},
		loadingWraper :
		{
			width           : '100%',
			height          : '150px',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		},
		loadingIndicator :
		{
			color : 'var(--text-color)'
		},
		errorMsg : {
			width        : '100%',
			color        : 'red',
			fontSize     : '0.8rem',
			textAlign    : 'center',
			marginBottom : '10px'
		}
	});

const theme = createTheme({
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'rgba(25,25,25,0.95)'
			}
		},
		MuiDialogTitle : {
			root : {
				backgroundColor : '#fff',
				color           : '#999898',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none',
				padding        : '0.5rem 0'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				'&::-ms-reveal' : {
					display : 'none'
				}
			}
		}
	}
});

const PortalLoginDialog = ({
	portalLoginDialogOpen,
	setPortalLoginDialogOpen,
	classes,
	portalId
}) =>
{
	const [ page, setPage ]= useState('login');

	const [ errorMsg, setErrorMsg ] = useState('');

	const [ portalLoginId, setPortaLoginlId ] = useState('');

	const [ email, setEmail ] = useState('');

	const [ password, setPassword ] = useState('');

	const [ loading, setLoading ] = useState(false);

	const [ passwordVisible, setPasswordVisible ] = useState(false);

	const [ newPasswordVisible, setNewPasswordVisible ] = useState(false);

	const [ newPasswordConfirmVisible, setNewPasswordConfirmVisible ] = useState(false);

	const [ newPassword, setNewPassword ] = useState('');

	const [ newPasswordConfirm, setNewPasswordConfirm ] = useState('');

	const handleClosePopup = async () =>
	{
		setPage('login');
		setErrorMsg('');
		setLoading(false);
		setPortaLoginlId('');
		setEmail('');
		setPassword('');
		setPasswordVisible(false);
		setNewPasswordVisible(false);
		setNewPasswordConfirmVisible(false);
		setPortalLoginDialogOpen(false);
	};

	const showLoginPage = async () =>
	{
		setPage('login');
		setErrorMsg('');
		setLoading(false);
		setPortaLoginlId('');
		setEmail('');
		setPassword('');
		setPasswordVisible(false);
		setNewPasswordVisible(false);
		setNewPasswordConfirmVisible(false);
	};

	const showPaswordForgotPage = () =>
	{
		setErrorMsg('');
		setLoading(false);
		setEmail('');
		setPage('passForgot');
	};

	const updateLoginId = (e) =>
	{
		setEmail(e.target.value);
	};

	const updatePassword = (e) =>
	{
		setPassword(e.target.value);
	};

	const updateNewPassword = (e) =>
	{
		setNewPassword(e.target.value);
	};

	const updateNewPasswordConfirm = (e) =>
	{
		setNewPasswordConfirm(e.target.value);
	};

	const togglePasswordVisible = () =>
	{
		setPasswordVisible((state) =>
		{
			return !state;
		});
	};

	const toggleNewPasswordVisible = () =>
	{
		setNewPasswordVisible((state) =>
		{
			return !state;
		});
	};

	const toggleNewPasswordConfirmVisible = () =>
	{
		setNewPasswordConfirmVisible((state) =>
		{
			return !state;
		});
	};

	const handleLogin = async () =>
	{
		setErrorMsg('');
		setLoading(true);

		const loginEmail = `${email}.${portalId}.portallogin.2nddoor.net`;

		try
		{

			const userCredential = await auth.signInWithEmailAndPassword(loginEmail, password);

			if (userCredential?.user?.uid)
			{
				try
				{
					const portalLoginDoc = await firestore.collection('portalLoginIds')
						.doc(userCredential?.user?.uid)
						.get();

					const portalLoginIdData = portalLoginDoc.data();

					if (portalLoginIdData.passwordChanged)
					{
						handleClosePopup();
						localStorage.setItem('portalLoginTimestamp', Date.now());
					}
					else
					{
						setPage('initialPassChange');
						setPortaLoginlId(userCredential.user.uid);
						setLoading(false);
					}

					setLoading(false);
				}
				catch (e)
				{
					setLoading(false);
					setErrorMsg([ 'ログインに失敗しました。', 'ログインIDとパスワードを確認してください。' ]);
				}
			}
			else
			{
				setLoading(false);
				setErrorMsg([ 'ログインに失敗しました。', 'ログインIDとパスワードを確認してください。' ]);
			}
		}
		catch (e)
		{
			setErrorMsg([ 'ログインに失敗しました。', 'ログインIDとパスワードを確認してください。' ]);
			setLoading(false);
		}
	};

	const handleChangePassword = async () =>
	{

		const reg = new RegExp(/^[a-zA-Z0-9]+$/);

		if (newPassword !== newPasswordConfirm)
		{
			setErrorMsg('パスワードが一致しません');
		}
		else if (reg.test(newPassword) === false)
		{
			setErrorMsg('使用可能な文字は半角英数字です。');
		}
		else if (newPassword.length < 8)
		{
			setErrorMsg('設定できるパスワードは8文字以上です。');
		}
		else
		{
			setLoading(true);
			setErrorMsg('');
			try
			{
				const changePortalLoginPasswordAPI = functions.httpsCallable('changePortalLoginPassword');

				const result = await changePortalLoginPasswordAPI(
					{
						password      : newPassword,
						portalLoginId : portalLoginId
					});

				if (result.data.success)
				{
					const loginEmail = `${email}.${portalId}.portallogin.2nddoor.net`;

					// sign in with new password (log out by password change)
					await auth.signInWithEmailAndPassword(loginEmail, newPassword);

					localStorage.setItem('portalLoginTimestamp', Date.now());
					handleClosePopup();
				}
				else
				{
					setErrorMsg('エラーが発生しました');
				}

				setLoading(false);
			}
			catch
			{
				setErrorMsg('エラーが発生しました');
				setLoading(false);
			}
		}
	};

	const handlePasswordReset = async () =>
	{
		setLoading(true);
		setErrorMsg('');

		try
		{
			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

			if (!email)
			{
				setErrorMsg('メールアドレスが入力されていません');
				setLoading(false);
			}
			else if (emailPattern.test(email) === false)
			{
				setErrorMsg('メールアドレスが正しくありません。');
				setLoading(false);
			}
			else
			{
				const domain = getDomainWithoutSubdomain();

				const resetPortalLoginPasswordAPI = functions.httpsCallable('resetPortalLoginPassword');

				const result = await resetPortalLoginPasswordAPI(
					{
						domain   : domain,
						email    : email,
						portalId : portalId
					});

				if (result.data.success)
				{
					setPage('passForgotEnd');
				}
				else if (result.data.reason === 'emailNotFound')
				{
					setErrorMsg('メールアドレスが登録されていません');
				}
				else
				{
					setErrorMsg('エラーが発生しました');
				}

				setLoading(false);
			}
		}
		catch
		{
			setErrorMsg('エラーが発生しました');
			setLoading(false);
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={portalLoginDialogOpen}
				onClose={() => {}}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				{ page === 'initialPassChange' ?
					<>
						<DialogTitle id='form-dialog-title'>
							<Box
								display='flex'
								alignItems='center'
								flexDirection='column'
							>
								<Box>
									< LockIcon className={classes.titleIcon}/>
								</Box>
								<Typography className={classes.titleText}>
									<FormattedMessage
										id='label.changePassword'
										defaultMessage='Change Password'
									/>
								</Typography>
							</Box>
						</DialogTitle>
						<DialogContent>
							{errorMsg &&
							<p className={classes.errorMsg}>{errorMsg}</p>
							}

							{ loading ?

								<Box className={classes.loadingWraper}>
									<CircularProgress className={classes.loadingIndicator}/>
								</Box>
								:
								<>
									<Box className={classes.explanationBox}>
										<Typography className={classes.explanationText}>
											仮パスワードを変更してください。
										</Typography>
										<Typography className={classes.explanationText}>
											パスワードは8文字以上の半角英数字としてください。
										</Typography>
									</Box>

									<Box className={classes.infoGroup}>
										<Typography className={classes.contentText}>
											新しいパスワード
										</Typography>
										<input
											value={newPassword}
											className={classes.input}
											onChange={(e) => updateNewPassword(e)}
											type={newPasswordVisible ? 'text' :'password'}
										/>
										<IconButton
											aria-label='toggleNewPasswordVisibility'
											onClick={toggleNewPasswordVisible}
											className={classes.visibilityButton}
										>
											{newPasswordVisible
												? <VisibilityOffIcon fontSize={'small'} className={classes.visibilityIcon}/>
												: <VisibilityIcon fontSize={'small'} className={classes.visibilityIcon}/>}
										</IconButton>
									</Box>
									<Box className={classes.infoGroup}>
										<Typography className={classes.contentText}>
											パスワード確認
										</Typography>
										<input
											value={newPasswordConfirm}
											className={classes.input}
											onChange={(e) => updateNewPasswordConfirm(e)}
											type={newPasswordConfirmVisible? 'text' :'password'}
										/>
										<IconButton
											aria-label='toggleNewPasswordConfirmVisibility'
											onClick={toggleNewPasswordConfirmVisible}
											className={classes.visibilityButton}
										>
											{newPasswordConfirmVisible
												? <VisibilityOffIcon fontSize={'small'} className={classes.visibilityIcon}/>
												: <VisibilityIcon fontSize={'small'} className={classes.visibilityIcon}/>}
										</IconButton>
									</Box>
								</>
							}
						</DialogContent>
						<DialogActions>
							{ !loading &&

							<Box className={classes.buttonWrapper}>
								<Button
									className={`${classes.backButton}`}
									onClick={showLoginPage}
									color='primary'
								>
									<FormattedMessage
										id='label.back'
										defaultMessage='Back'
									/>
								</Button>
								<Button
									className={`${classes.button}`}
									onClick={handleChangePassword}
									color='primary'
								>
									<FormattedMessage
										id='label.update'
										defaultMessage='Update'
									/>
								</Button>
							</Box>
							}
						</DialogActions>
					</>
					: page === 'passForgot' ?
						<>
							<DialogTitle id='form-dialog-title'>
								<Box
									display='flex'
									alignItems='center'
									flexDirection='column'
								>
									<Box>
										< LockIcon className={classes.titleIcon}/>
									</Box>
									<Typography className={classes.titleText}>
										<FormattedMessage
											id='label.resetPassword'
											defaultMessage='Reset Password'
										/>
									</Typography>
								</Box>
							</DialogTitle>
							<DialogContent>
								{errorMsg &&
								<p className={classes.errorMsg}>{errorMsg}</p>
								}

								{ loading ?

									<Box className={classes.loadingWraper}>
										<CircularProgress className={classes.loadingIndicator}/>
									</Box>
									:
									<>
										<Box className={classes.messageGroup}>
											<Typography className={classes.messageText}>
												パスワード変更手続きをeメールでご案内します。
											</Typography>
										</Box>
										<Box className={classes.infoGroup}>

											<Typography className={classes.contentText}>
												メールアドレス
											</Typography>
											<input
												value={email}
												className={classes.input}
												onChange={(e) => updateLoginId(e)}
												type='text'
											/>
										</Box>
									</>
								}
							</DialogContent>
							<DialogActions>
								{ !loading &&
									<Box className={classes.buttonWrapper}>
										<Button
											className={`${classes.backButton}`}
											onClick={showLoginPage}
											color='primary'
										>
											<FormattedMessage
												id='label.back'
												defaultMessage='Back'
											/>
										</Button>

										<Button
											className={`${classes.button}`}
											onClick={handlePasswordReset}
											color='primary'
										>
											<FormattedMessage
												id='label.send'
												defaultMessage='Send'
											/>
										</Button>
									</Box>
								}
							</DialogActions>
						</>
						: page === 'passForgotEnd' ?
							<>
								<DialogTitle id='form-dialog-title'>
									<Box
										display='flex'
										alignItems='center'
										flexDirection='column'
									>
										<Box>
											< LockIcon className={classes.titleIcon}/>
										</Box>
										<Typography className={classes.titleText}>
											<FormattedMessage
												id='label.resetPassword'
												defaultMessage='Reset Password'
											/>
										</Typography>
									</Box>
								</DialogTitle>
								<DialogContent>
									<Box className={classes.messageGroup}>
										<Typography className={classes.messageText}>
											{email}にパスワード変更用URLを送信しました。
										</Typography>
										<Typography className={classes.messageText}>
											送信されたURLで手続きしてください。
										</Typography>
									</Box>
								</DialogContent>
								<DialogActions>
									{ !loading &&
									<Button
										className={`${classes.backButton}`}
										onClick={showLoginPage}
										color='primary'
									>
										<FormattedMessage
											id='label.back'
											defaultMessage='Back'
										/>
									</Button>
									}
								</DialogActions>
							</>
							:
							<>
								<DialogTitle id='form-dialog-title'>
									<Box
										display='flex'
										alignItems='center'
										flexDirection='column'
									>
										<Box>
											< LockIcon className={classes.titleIcon}/>
										</Box>
										<Typography className={classes.titleText}>
											<FormattedMessage
												id='label.login'
												defaultMessage='Login'
											/>
										</Typography>
									</Box>
								</DialogTitle>
								<DialogContent>
									{errorMsg &&
									<p className={classes.errorMsg}>{errorMsg}</p>
									}

									{ loading ?

										<Box className={classes.loadingWraper}>
											<CircularProgress className={classes.loadingIndicator}/>
										</Box>
										:
										<>
											<Box className={classes.infoGroup}>
												<Typography className={classes.contentText}>
													メールアドレス
												</Typography>
												<input
													value={email}
													className={classes.input}
													onChange={(e) => updateLoginId(e)}
													type='text'
												/>
											</Box>
											<Box className={classes.infoGroup}>
												<Typography className={classes.contentText}>
													ログインパスワード
												</Typography>
												<input
													value={password}
													className={classes.input}
													onChange={(e) => updatePassword(e)}
													type={passwordVisible ? 'text' :'password'}
												/>
												<IconButton
													aria-label='togglePasswordVisibility'
													onClick={togglePasswordVisible}
													className={classes.visibilityButton}
												>
													{passwordVisible
														? <VisibilityOffIcon fontSize={'small'} className={classes.visibilityIcon}/>
														: <VisibilityIcon fontSize={'small'} className={classes.visibilityIcon}/>}
												</IconButton>
											</Box>
										</>
									}
								</DialogContent>
								<DialogActions>
									{ !loading &&
									<Button
										className={`${classes.button}`}
										onClick={handleLogin}
										color='primary'
									>
										<FormattedMessage
											id='label.send'
											defaultMessage='Send'
										/>
									</Button>
									}
								</DialogActions>

								<DialogActions>
									<Typography className={classes.forgotPasswordLabel}>
										パスワードをお忘れの方は
										<span
											className={classes.link}
											onClick={() => showPaswordForgotPage()}
										>
											こちら
										</span>
									</Typography>
								</DialogActions>
							</>
				}
			</Dialog>
		</MuiThemeProvider>
	);
};

PortalLoginDialog.propTypes =
{
	portalLoginDialogOpen    : PropTypes.bool.isRequired,
	setPortalLoginDialogOpen : PropTypes.func.isRequired,
	classes                  : PropTypes.object.isRequired,
	portalId                 : PropTypes.string
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
	}
)(withStyles(styles)(PortalLoginDialog)));