// React
import React, { useState } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';

// Message
import { FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// Firebase
import { functions } from '../../../lib/firebase';

const styles = (theme) =>
	({
		root : {
			// display : 'flex',
			// width   : '100%',
			// height  : '100%'
		},
		dialogRoot : {
			pointerEvents : 'none'
		},
		dialogPaper : {
			padding                        : '1% 2%',
			width                          : '30vw',
			pointerEvents                  : 'auto',
			minWidth                       : '250px',
			[theme.breakpoints.down('lg')] : {
				width : '40vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '70vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		header : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		icon : {
			width        : 'auto',
			height       : '2rem',
			marginBottom : '5px'
		},
		title : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			fontWeight : 'bold'
		},
		inputRow :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		devideline : {
			color        : 'var(--text-color)',
			width        : '95%',
			marginBottom : '2rem'
		},
		inputGroup : {
			width          : '90%',
			display        : 'flex',
			justifyContent : 'space-between',
			marginBottom   : '1.5rem'
		},
		contentTitle : {
			color      : 'var(--text-color)',
			paddingTop : '6px',
			fontSize   : '0.8rem'
		},
		inputBg : {
			width           : '60%',
			border          : 'none',
			marginBottom    : '1.5rem',
			backgroundColor : 'var(--text-color)',
			borderRadius    : '0.5rem',
			'&:placeholder' : {
				color : 'white'
			}
		},
		input : {
			width        : '60%',
			borderRadius : '0.5rem',
			border       : '1px solid var(--text-color)'
		},
		inputWithIcon : {
			width        : '100%',
			borderRadius : '0.5rem',
			border       : '1px solid var(--text-color)'
		},
		button : {
			backgroundColor : 'var(--next-button-color)',
			marginTop       : '5px',
			width           : '7rem',
			'&:hover'       : {
				backgroundColor : 'var(--next-button-color)'
			}
		},
		buttonChanging : {
			backgroundColor : '#ED9B53',
			marginTop       : '5px',
			width           : '7rem',
			'&:hover'       : {
				backgroundColor : '#ED9B53'
			}
		},
		cancelButton : {
			backgroundColor : 'var(--button-color)',
			marginTop       : '1rem',
			marginBottom    : '2rem',
			width           : '7rem',
			color           : 'var(--text-color)',
			border          : 'solid 1px #dddede',
			'&:hover'       : {
				backgroundColor : 'var(--next-button-color)',
				color           : '#fff'
			}
		},
		control : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		msgWrapper :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			margin         : '15px 0'
		},
		errorMsgWrapper :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			margin         : '10px 0 -5px 0'
		},
		errorMsg : {
			width     : '400px',
			height    : '1.2rem',
			color     : 'red',
			fontSize  : '0.9rem',
			textAlign : 'center'
		},
		singleMsg : {
			width     : '400px',
			height    : '1.2rem',
			color     : 'var(--text-color)',
			marginTop : '5px',
			fontSize  : '0.9rem',
			textAlign : 'center'
		},
		errorMsgCodeView : {
			marginTop : '20px'
		},
		MuiDialogActions : {
			root : {
				flexDirection  : 'column',
				justifyContent : 'center'
			},
			spacing : {
				'& > :not(:first-child)' : {
					marginLeft : '0'
				}
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.8rem',
				marginRight : '0.8rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding      : '0.4rem 0.8rem',
				borderRadius : '0.5rem'
			},
			notchedOutline : {
				border : 'none'
			}
		},
		warningGroup : {
			display      : 'flex',
			marginTop    : '0.5rem',
			marginBottom : '0'
		},
		warningIcon : {
			marginTop : '24px',
			width     : 'auto',
			height    : '2rem'
		},
		warningContent : {
			color      : 'var(--text-color)',
			fontSize   : '0.67rem',
			marginLeft : '2rem',
			paddingTop : '0.6rem'
		},
		messageWrapper : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		message : {
			width        : '80%',
			marginBottom : '25px',
			color        : 'var(--text-color)',
			fontSize     : '0.8rem'
		},
		loading :
		{
			width           : '100vw',
			height          : '100vh',
			zIndex          : 9999999,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		},
		passwordInput :
		{
			width     : '60%',
			position  : 'relative',
			maxHeight : '30px'
		},
		visibilityButton : {
			position  : 'absolute',
			top       : '0',
			bottom    : '0',
			right     : '-6px',
			'&:hover' : {
				backgroundColor : 'transparent'
			}
		},
		visibilityIcon : {
			color    : 'var(--text-color)',
			fontSize : '1.1rem !important'
		}
	});

const theme = createTheme({
	overrides : {
		MuiBox : {
			root : {
				whiteSpace : 'nowrap'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		},
		MuiDialogContent : {
			root : {
				padding : '1rem 0'
			}
		},
		MuiDialogActions : {
			spacing : {
				'& > :not(:first-child)' : {
					marginLeft : '0'
				}
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.8rem',
				marginRight : '0.8rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding         : '0.4rem 0.8rem',
				borderRadius    : '0.5rem',
				'&::-ms-reveal' : {
					display : 'none'
				}
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const OperatorResetPasswordDialog = ({
	classes,
	target,
	pageType,
	loginId,
	email,
	afterClose,
	hostId,
	documentId,
	passwordChangeKey,
	errorToShow
}) =>
{

	// if error exist, show it
	const [ errorMsg, setErrorMsg ] = useState(errorToShow);

	const [ password1, setPassword1 ] = useState('');
	const [ password2, setPassword2 ] = useState('');

	const [ complete, setComplete ] = useState(false);

	const [ saving, setSaving ] = useState(false);

	const [ passwordVisible1, setPasswordVisible1 ]= useState(false);
	const [ passwordVisible2, setPasswordVisible2 ]= useState(false);

	const handleSubmit = async () =>
	{

		if (target === 'portal')
		{
			if (!documentId || !email || !passwordChangeKey)
			{
				setErrorMsg('パスワードの変更に失敗しました。');

				return;
			}
		}
		// target === 'host' 
		else if (!loginId || !email || !hostId || !passwordChangeKey)
		{
			setErrorMsg('パスワードの変更に失敗しました。');

			return;
		}

		if (password1 !== password2)
		{
			setErrorMsg('パスワードと確認用パスワードが異なっています。');

			return;
		}

		if (!password1 || password1.length < 8)
		{
			setErrorMsg('パスワードは8文字以上に設定してください。');

			return;
		}

		const reg = new RegExp(/^[a-zA-Z0-9]+$/);

		if (reg.test(password1) === false)
		{
			setErrorMsg('パスワードに使用可能な文字は半角英数字です。');

			return;
		}

		if (target === 'host')
		{
			const uppercaseReg = new RegExp(/[A-Z]/);

			if (uppercaseReg.test(password1) === false)
			{
				setErrorMsg('パスワードには大文字アルファベットを含む必要があります。');

				return;
			}

			if (password1 === loginId)
			{
				setErrorMsg('ログインIDと同じ文字列は設定できません');

				return;
			}

			const emailStringArray = email.split('@');

			if (password1 === emailStringArray[0])
			{
				setErrorMsg('メールアドレスの@より前の部分と同一の文字列は設定できません');

				return;
			}
		}

		setSaving(true);

		let result = false;

		try
		{

			if (target === 'serviceAccount')
			{
				const changeServiceLoginPasswordAPI = functions.httpsCallable('servicePasswordChange');

				result = await changeServiceLoginPasswordAPI({
					email             : email,
					documentId        : documentId,
					passwordChangeKey : passwordChangeKey,
					newPassword       : password1,
					target            : target
				});
			}
			else if (target === 'serviceGuest')
			{
				const changeServiceLoginPasswordAPI = functions.httpsCallable('servicePasswordChange');

				result = await changeServiceLoginPasswordAPI({
					email             : email,
					documentId        : documentId,
					passwordChangeKey : passwordChangeKey,
					newPassword       : password1,
					target            : target
				});
			}
			else if (target === 'portal')
			{
				const changePortalLoginPasswordAPI = functions.httpsCallable('changePortalLoginPasswordByReset');

				result = await changePortalLoginPasswordAPI({
					email             : email,
					portalLoginId     : documentId,
					passwordChangeKey : passwordChangeKey,
					newPassword       : password1
				});
			}
			else // host
			{
				const parts = window.location.hostname.split('.');
				const subdomain = parts.shift();

				const changeHostPasswordAPI = functions.httpsCallable('changeHostPassword');

				result = await changeHostPasswordAPI({
					subdomain         : subdomain,
					email             : email,
					loginId           : loginId,
					hostId            : hostId,
					passwordChangeKey : passwordChangeKey,
					newPassword       : password1
				});
			}
		}
		catch
		{
			setSaving(false);
			setErrorMsg('パスワードの変更に失敗しました。');
		}

		setSaving(false);

		if (result.data?.success)
		{
			setErrorMsg('');
			setComplete(true);
		}
		else if (result.data?.error === 'passwordExpired')
		{
			setErrorMsg('パスワード変更申し込みから12時間以上経過しています。再度手続きしてください');
		}
		else
		{
			setErrorMsg('パスワードの変更に失敗しました');
		}
	};

	const togglePasswordVisible1 = () =>
	{
		setPasswordVisible1((state) =>
		{
			return !state;
		});
	};

	const togglePasswordVisible2 = () =>
	{
		setPasswordVisible2((state) =>
		{
			return !state;
		});
	};

	const handleChangePassword1= (e) =>
	{
		if (e.target)
		{
			setPassword1(e.target.value);
		}
	};

	const handleChangePassword2= (e) =>
	{
		if (e.target)
		{
			setPassword2(e.target.value);
		}
	};

	return (
		<MuiThemeProvider theme={theme}>

			{ saving &&
				<Box className={classes.loading}>
					<CircularProgress />
				</Box>
			}

			<Dialog
				open
				scroll={'paper'}
				classes={{
					root  : classes.dialogRoot,
					paper : classes.dialogPaper
				}}
				BackdropProps={{
					style : {
						// backgroundColor : 'transparent'
					}
				}}
			>

				<DialogTitle>
					<Box className={classes.header}>
						<img
							src='images/user_setting_icon.png'
							className={classes.icon}
							alt=''
						/>
						{
							complete ?
								<Typography className={classes.title}>
									パスワード再発行
								</Typography>
								: pageType === 'initial' ?
									<Typography className={classes.title}>
										ログインパスワード初期設定
									</Typography>
									: errorToShow ?

										<Typography className={classes.title}>
											ログインパスワード設定
										</Typography>
										:
										<Typography className={classes.title}>
											ログインパスワード再発行
										</Typography>
						}
					</Box>
				</DialogTitle>
				<DialogContent>
					{ errorToShow ?
						<div className={classes.msgWrapper}>
							<Typography className={classes.singleMsg}>
								{errorToShow}
							</Typography>
						</div>
						: complete ?
							<div className={classes.msgWrapper}>
								<Typography className={classes.singleMsg}>
									パスワード再発行手続きを完了いたしました。
								</Typography>
							</div>
							:
							<>
								{
									pageType === 'initial' &&
									<Box className={classes.messageWrapper}>
										<Typography className={classes.message}>
											現在のログインパスワードは仮のパスワードです。お客様ご自身でパスワードを変更いただきますよう、お願いいたします。
										</Typography>
									</Box>
								}
								<Box className={classes.inputRow}>
									<Box className={classes.inputGroup}>
										<Typography className={classes.contentTitle}>
											ログインID
										</Typography>
										<TextField
											value={loginId}
											variant='outlined'
											classes={{
												root : classes.inputBg
											}}
											inputProps={{
												classes : {
													input : classes.inputBg
												},
												style : {
													textAlign : 'left',
													fontSize  : '0.75rem',
													color     : 'white'
												}
											}}
											disabled
										/>
									</Box>
								</Box>
								<Box className={classes.inputRow}>
									<hr className={classes.devideline}/>
								</Box>

								<Box className={classes.inputRow}>
									<Box className={classes.inputGroup}>
										<Typography className={classes.contentTitle}>
											新しいパスワード
										</Typography>
										<Box className={classes.passwordInput}>
											<TextField
												type={passwordVisible1 ? 'text' :'password'}
												variant='outlined'
												classes={{
													root : classes.inputWithIcon
												}}
												inputProps={{
													classes : {
														input : classes.inputWithIcon
													},
													style : {
														textAlign : 'left',
														fontSize  : '0.75rem',
														color     : 'var(--text-color)'
													}
												}}
												value={password1}
												onChange={handleChangePassword1}
												autoComplete='off'
											/>
											<IconButton
												aria-label='togglePasswordVisibility'
												onClick={togglePasswordVisible1}
												className={classes.visibilityButton}
											>
												{passwordVisible1
													? <VisibilityOffIcon fontSize={'small'} className={classes.visibilityIcon}/>
													: <VisibilityIcon fontSize={'small'} className={classes.visibilityIcon}/>}
											</IconButton>
										</Box>
									</Box>
								</Box>
								<Box className={classes.inputRow}>
									<Box className={classes.inputGroup}>
										<Typography className={classes.contentTitle}>
											新しいパスワード(確認)
										</Typography>
										<Box className={classes.passwordInput}>
											<TextField
												type={passwordVisible2 ? 'text' :'password'}
												variant='outlined'
												classes={{
													root : classes.inputWithIcon
												}}
												inputProps={{
													classes : {
														input : classes.inputWithIcon
													},
													style : {
														textAlign : 'left',
														fontSize  : '0.75rem',
														color     : 'var(--text-color)'
													}
												}}
												value={password2}
												onChange={handleChangePassword2}
												autoComplete='off'
											/>
											<IconButton
												aria-label='togglePasswordVisibility'
												onClick={togglePasswordVisible2}
												className={classes.visibilityButton}
											>
												{passwordVisible2
													? <VisibilityOffIcon fontSize={'small'} className={classes.visibilityIcon}/>
													: <VisibilityIcon fontSize={'small'} className={classes.visibilityIcon}/>}
											</IconButton>
										</Box>
									</Box>
								</Box>
								<Box className={classes.inputRow}>
									<Box className={classes.warningGroup}>
										<img
											src='images/caution_icon.png'
											className={classes.warningIcon}
											alt=''
										/>
										{ target === 'portal' ?
											<ul type='disc' className={classes.warningContent}>
												<li>設定できるパスワードは8文字以上です。</li>
												<li>使用可能な文字は半角英数字です。</li>
												<li>セキュリティの観点より、他人に特定されやすい文字列は避けてください。</li>
												<li>パスワードの変更は、お手続き後すぐに反映されます。</li>
											</ul>
											:
											<ul type='disc' className={classes.warningContent}>
												<li>設定できるパスワードは8文字以上です。</li>
												<li>使用可能な文字は半角英数字です。1文字以上の英大文字を含んだ設定が必須です。</li>
												<li>メールアドレスの@より前の部分と同一の文字列は設定できません。</li>
												<li>セキュリティの観点より、他人に特定されやすい文字列は避けてください。</li>
												<li>パスワードの変更は、お手続き後すぐに反映されます。</li>
											</ul>
										}
									</Box>
								</Box>
								{ errorMsg &&
								<div className={classes.errorMsgWrapper}>
									<Typography className={classes.errorMsg}>
										{errorMsg}
									</Typography>
								</div>
								}
							</>
					}

				</DialogContent>
				<DialogActions>
					{ complete ?
						<Box className={classes.control}>
							<Button
								variant='contained'
								color='primary'
								classes={{
									root : classes.button
								}}
								onClick={afterClose}
							>
								終了
							</Button>
						</Box>
						: errorToShow ?
							null
							: saving ?
								<Box className={classes.control}>
									<Button
										variant='contained'
										color='secondary'
										classes={{
											root : classes.buttonChanging
										}}
									>
										変更中
									</Button>
								</Box>
								:
								<Box className={classes.control}>
									<Button
										variant='contained'
										color='secondary'
										classes={{
											root : classes.button
										}}
										onClick={handleSubmit}
									>
										<FormattedMessage
											id='label.send'
											defaultMessage='Send'
										/>
									</Button>
								</Box>
					}
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

OperatorResetPasswordDialog.propTypes =
{

	classes           : PropTypes.object.isRequired,
	target            : PropTypes.string.isRequired,
	pageType          : PropTypes.string.isRequired,
	loginId           : PropTypes.string,
	email             : PropTypes.string,
	hostId            : PropTypes.string,
	documentId        : PropTypes.string,
	passwordChangeKey : PropTypes.string,
	afterClose        : PropTypes.func.isRequired,
	errorToShow       : PropTypes.string
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(OperatorResetPasswordDialog)));
