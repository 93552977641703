export function getSignalingUrl(
	peerId,
	roomId,
	subdomain,
	clientType,
	roomIdToReturn,
	serviceLoginId,
	serverUrl
)
{
	let hostname = '';

	// join support call (possiblly another server)
	if (clientType === 'subHost' && serverUrl)
	{
		hostname = serverUrl;
	}
	else
	{
		hostname = window.config.serverHostname || window.location.hostname;
	}

	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;

	let roomIdToReturnStr = roomIdToReturn;

	if (!roomIdToReturn)
	{
		roomIdToReturnStr = '';
	}

	const url =
		process.env.NODE_ENV !== 'production' ?
			`ws://${hostname}:${port}/?peerId=${peerId}&roomId=${roomId}&subdomain=${subdomain}&clientType=${clientType}&roomIdToReturn=${roomIdToReturnStr}&serviceLoginId=${serviceLoginId}`
			:
			`wss://${hostname}:${port}/?peerId=${peerId}&roomId=${roomId}&subdomain=${subdomain}&clientType=${clientType}&roomIdToReturn=${roomIdToReturnStr}&serviceLoginId=${serviceLoginId}`;

	return url;
}

// developmentから本番サーバーへの接続用

// export function getSignalingUrl(peerId, roomId, subdomain, clientType, roomIdToReturn)
// {
// 	const hostname = 'likeness-online.com';
// 	const port = 443;

// 	let roomIdToReturnStr = roomIdToReturn;

// 	if (!roomIdToReturn)
// 	{
// 		roomIdToReturnStr = '';
// 	}

// 	const url = `wss://${hostname}:${port}/?peerId=${peerId}&roomId=${roomId}&subdomain=${subdomain}&clientType=${clientType}&roomIdToReturn=${roomIdToReturnStr}`;

// 	return url;
// }

export function requestJoinUrl(serverName)
{
	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;

	const url =
		process.env.NODE_ENV !== 'production' ?
			`ws://${serverName}:${port}/?clientType=joinRequest`
			:
			`wss://${serverName}:${port}/?clientType=joinRequest`;

	return url;
}

export function callSupportUrl(peerId, serverName, roomId, subdomain)
{
	const port =
		process.env.NODE_ENV !== 'production' ?
			window.config.developmentPort
			:
			window.config.productionPort;

	const url =
		process.env.NODE_ENV !== 'production' ?
			`ws://${serverName}:${port}/?peerId=${peerId}&roomId=${roomId}&subdomain=${subdomain}&clientType=supportCall`
			:
			`wss://${serverName}:${port}/?peerId=${peerId}&roomId=${roomId}&subdomain=${subdomain}&clientType=supportCall`;

	return url;
}

export function directJoinUrl({
	subdomain,
	portalId,
	portalKey,
	portalConnectionId,
	serviceParamKey,
	serviceConnectionId
})
{
	let port = '';

	const hostName = window.config.serverHostname || window.location.hostname;

	const hostNameAr = hostName.split('.');

	let hostNameWithoutSubdomain = '';

	if (process.env.NODE_ENV === 'development')
	{
		port = `:${window.location.port}`;
		// with subdomain
		if (hostNameAr.length >=2)
		{
			// ['subdomain', 'localhost']
			hostNameWithoutSubdomain = hostNameAr[1];
		}
		else
		{
			// ['localhost']
			hostNameWithoutSubdomain = hostNameAr[0];
		}
	}
	else if (hostNameAr.length >=3) // production
	{
		// ['subdomain', 'online-call', 'com']
		hostNameWithoutSubdomain = `${hostNameAr[1]}.${hostNameAr[2]}`;
	}
	else // production hostNameAr.length < 3
	{
		// ['online-call', 'com']
		hostNameWithoutSubdomain = `${hostNameAr[0]}.${hostNameAr[1]}`;
	}

	if (portalKey && portalId)
	{
		return `https://${subdomain}.${hostNameWithoutSubdomain}${port}?dctCall=true&portalId=${portalId}&portalKey=${portalKey}&portalConnectionId=${portalConnectionId}`;
	}
	else if (portalId)
	{
		return `https://${subdomain}.${hostNameWithoutSubdomain}${port}?dctCall=true&portalId=${portalId}&portalConnectionId=${portalConnectionId}`;
	}
	else if (serviceParamKey)
	{
		return `https://${subdomain}.${hostNameWithoutSubdomain}${port}?dctCall=true&serviceParamKey=${serviceParamKey}&serviceConnectionId=${serviceConnectionId}`;
	}
}
// export function getHostNameWithoutSubdomain()
// {
// 	const hostName = window.config.serverHostname || window.location.hostname;

// 	const hostNameAr = hostName.split('.');

// 	let returnUrl = '';

// 	if (process.env.NODE_ENV === 'development')
// 	{
// 		// with subdomain
// 		if (hostNameAr.length >=2)
// 		{
// 			// ['subdomain', 'localhost:4443']
// 			returnUrl = hostNameAr[1];
// 		}
// 		else
// 		{
// 			// ['localhost:4443']
// 			returnUrl = hostNameAr[0];
// 		}

// 	}
// 	else if (hostNameAr.length >=3) // production
// 	{
// 		// ['subdomain', 'online-call', 'com']
// 		returnUrl = `${hostNameAr[1]}.${hostNameAr[2]}`;
// 	}
// 	else // production hostNameAr.length < 3
// 	{
// 		// ['online-call', 'com']
// 		returnUrl = `${hostNameAr[0]}.${hostNameAr[1]}`;
// 	}

// 	return returnUrl;

// }