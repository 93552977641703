import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { Config } from '../services/config';
import 'firebase/compat/analytics';

export const firebaseApp = firebase.initializeApp(Config.firebase);
export const functions = firebase.functions();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const fieldValue = firebase.firestore.FieldValue;

if (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production')
{
	firebase.analytics();
}
// export const fs = firebase.storage()
export const firebaseTimestamp = () => firebase.firestore.Timestamp.now();
export const serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();