import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// firestore
import { firestore } from '../../../../lib/firebase';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			padding         : '1% 1%',
			width           : 500,
			minHeight       : 500,
			backgroundColor : '#666666'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'white',
			fontSize : '1.1rem'
		},
		cancelBtn :
		{
			width           : '40%',
			border          : '1px solid #fff',
			backgroundColor : 'transparent',
			color           : 'white'
		},
		accountsList : {
			width : '100%'
		},
		accountsLine : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		accountName : {
			fontSize : '0.9rem'
		},
		noAccount : {
			width          : '100%',
			height         : '100%',
			minHeight      : '40vh',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		noAccountText : {
			color     : 'white',
			fontSize  : '1.1rem',
			textAlign : 'center'
		},
		loading :
		{
			width           : '100%',
			height          : '100%',
			minHeight       : '40vh',
			zIndex          : 9999999,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0)'
		},
		loadingCircular : {
			color : 'white'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'transparent'
			}
		},
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : '#9ec317',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				color          : 'white',
				justifyContent : 'start',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiTypography : {
			colorTextSecondary : {
				color : 'white'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.6rem'
			}
		}
	}
});

const BrokageAccountsDialog = ({
	classes,
	show,
	closeMethod,
	paramKey
}) =>
{
	const [ accounts, setAccounts ] = useState([]);

	const [ loading, setLoading ] = useState(true);

	const getBrokageAccounts = useCallback(async () =>
	{
		setLoading(true);

		const brokageAccounts = await firestore.collection('serviceAccounts')
			.where('brokerAccountParamKey', '==', paramKey)
			.get();

		try
		{
			if (brokageAccounts.docs && brokageAccounts.docs.length > 0)
			{
				const accountsData = [];

				brokageAccounts.docs.forEach((ac) =>
				{
					const accountData = ac.data();

					accountsData.push({
						id : ac.id,
						...accountData
					});

				});

				setAccounts([ ...accountsData ]);
			}
		}
		catch
		{
			// do nothing
		}

		setLoading(false);

	}, [ paramKey ]);

	useEffect(() =>
	{
		getBrokageAccounts();

	}, [ getBrokageAccounts ]);

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={() => {}}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<div className={classes.title}>
						<div className={classes.titleText}>
							仲介企業一覧
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					{ loading ?
						<Box className={classes.loading}>
							<CircularProgress className={classes.loadingCircular}/>
						</Box>
						: accounts.length === 0 ?
							<Box className={classes.noAccount} >
								<Typography>
									アカウントが見つかりません
								</Typography>
							</Box>
							:
							<Box className={classes.accountsList}>
								{	accounts.map((ac) => (
									<Box
										className={classes.accountsLine}
										key={ac.id}
									>
										<Typography
											className={classes.accountName}
										>
											{ac.accountName}
										</Typography>
									</Box>
								))
								}
							</Box>
					}
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.cancelBtn}
						onClick={closeMethod}
						color='primary'
					>
						<FormattedMessage
							id='label.close'
							defaultMessage='Close'
						/>
					</Button>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

BrokageAccountsDialog.propTypes =
{
	classes     : PropTypes.object.isRequired,
	show        : PropTypes.bool.isRequired,
	closeMethod : PropTypes.func.isRequired,
	paramKey    : PropTypes.string
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(BrokageAccountsDialog)));