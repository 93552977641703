import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			padding         : '1% 1%',
			width           : 500,
			minHeight       : 500,
			backgroundColor : '#666666'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'white',
			fontSize : '1.1rem'
		},
		promoteBtn :
		{
			width           : '40%',
			border          : '1px solid #8eba14',
			backgroundColor : '#8eba14',
			color           : 'white'
		},
		cancelBtn :
		{
			width           : '40%',
			border          : '1px solid #fff',
			backgroundColor : 'transparent',
			color           : 'white'
		},
		topData : {
			width          : '100%',
			height         : '1.75rem',
			display        : 'flex',
			margin         : '1% 0',
			justifyContent : 'space-between',
			lineHeight     : 1
		},
		selectActiveSection : {
			marginBottom : '4%'
		},
		awayBelow : {
			marginBottom : '3%'
		},
		inputLabel : {
			width         : '50%',
			fontSize      : '0.8rem',
			paddingTop    : '10px',
			// whiteSpace    : 'nowrap',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		label : {
			color        : 'white',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'transparent'
			}
		},
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : '#9ec317',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				color          : 'white',
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiTypography : {
			colorTextSecondary : {
				color : 'white'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.6rem'
			}
		}
	}
});

const CustomSwitch = withStyles({
	switchBase : {
		color       : '#CCC',
		'&$checked' : {
			color : 'var(--submit-button-color)'
		},
		'&$checked + $track' : {
			backgroundColor : 'var(--submit-button-color)'
		}
	},
	checked : {},
	track   : {}
})(Switch);

const EditRoleDialog = ({
	classes,
	show,
	customRoles,
	setCustomRoles,
	roleCode,
	roleNumber,
	closeMethod
}) =>
{
	const [ roleActive, setRoleActive ] = useState(false);
	const [ roleMonitoring, setRoleMonitoring ] = useState(false);
	const [ roleExpel, setRoleExpel ] = useState(false);
	const [ roleSetPriority, setRoleSetPriority ] = useState(false);
	const [ roleWatchPersonalInfo, setRoleWatchPersonalInfo ] = useState(false);
	const [ roleShowRecordedData, setRoleShowRecordedData ] = useState(false);
	const [ roleDownloadRecordedData, setRoleDownloadRecordedData ] = useState(false);

	useEffect(() =>
	{
		if (customRoles && customRoles[roleCode])
		{
			setRoleActive(customRoles[roleCode].active);
			setRoleMonitoring(customRoles[roleCode].monitoring);
			setRoleExpel(customRoles[roleCode].expel);
			setRoleSetPriority(customRoles[roleCode].setPriority);
			setRoleWatchPersonalInfo(customRoles[roleCode].watchPersonalInfo);
			setRoleShowRecordedData(customRoles[roleCode].showRecordedData);
			setRoleDownloadRecordedData(customRoles[roleCode].downloadRecordedData);
		}

	}, [ customRoles, roleCode ]);

	const handleChangeRoleActive = (e) =>
	{
		setRoleActive(e.target.checked);
	};

	const handleChangeRoleMonitoring = (e) =>
	{
		setRoleMonitoring(e.target.checked);
	};

	const handleChangeRoleExpel = (e) =>
	{
		setRoleExpel(e.target.checked);
	};

	const handleChangeRoleSetPriority = (e) =>
	{
		setRoleSetPriority(e.target.checked);
	};

	const handleChangeRoleWatchPersonalInfo = (e) =>
	{
		setRoleWatchPersonalInfo(e.target.checked);
	};

	const handleChangeRoleShowRecordedData = (e) =>
	{
		setRoleShowRecordedData(e.target.checked);
	};

	const handleChangeRoleDownloadRecordedData = (e) =>
	{
		setRoleDownloadRecordedData(e.target.checked);
	};

	const handleSetCallingRole = () =>
	{
		setCustomRoles((state) =>
		{
			const rolesData = { ...state };

			rolesData[roleCode] = {
				...rolesData[roleCode],
				active               : roleActive,
				enterRoom            : true,
				monitoring           : roleMonitoring,
				expel                : roleExpel,
				setPriority          : roleSetPriority,
				watchPersonalInfo    : roleWatchPersonalInfo,
				showRecordedData     : roleShowRecordedData,
				downloadRecordedData : roleDownloadRecordedData
			};

			return rolesData;
		});

		closeMethod();
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={() => {}}
				classes={{
					paper : classes.dialogPaper
				}}
			>
				<DialogTitle id='form-dialog-title'>
					<div className={classes.title}>
						<div className={classes.titleText}>
							{ (customRoles && customRoles[roleCode]?.supervise) ? 'SV' : 'オペレータ'}{roleNumber}設定
						</div>
					</div>
				</DialogTitle>
				<DialogContent>
					<Box className={`${classes.topData} ${classes.selectActiveSection}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>新規作成</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<CustomSwitch
								checked={roleActive}
								onChange={handleChangeRoleActive}
								color='secondary'
								name='supervise'
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
						</Box>
					</Box>

					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>個人情報閲覧</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<CustomSwitch
								checked={roleWatchPersonalInfo}
								onChange={handleChangeRoleWatchPersonalInfo}
								color='secondary'
								name='supervise'
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
						</Box>
					</Box>
					<Box className={classes.topData}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>録音データへのアクセス</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<CustomSwitch
								checked={roleShowRecordedData}
								onChange={handleChangeRoleShowRecordedData}
								color='secondary'
								name='supervise'
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
						</Box>
					</Box>
					<Box className={`${classes.topData} ${classes.awayBelow}`}>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>録音データダウンロード</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<CustomSwitch
								checked={roleDownloadRecordedData}
								onChange={handleChangeRoleDownloadRecordedData}
								color='secondary'
								name='supervise'
								inputProps={{ 'aria-label': 'primary checkbox' }}
							/>
						</Box>
					</Box>

					{ (customRoles && customRoles[roleCode]?.supervise) &&
					<>
						<Box className={classes.topData}>
							<Box className={classes.inputLabel}>
								<Typography className={classes.label}>モニタリング</Typography>
							</Box>
							<Box className={classes.inputValue}>
								<CustomSwitch
									checked={roleMonitoring}
									onChange={handleChangeRoleMonitoring}
									color='secondary'
									name='monitoring'
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</Box>
						</Box>

						<Box className={classes.topData}>
							<Box className={classes.inputLabel}>
								<Typography className={classes.label}>強制退室</Typography>
							</Box>
							<Box className={classes.inputValue}>
								<CustomSwitch
									checked={roleExpel}
									onChange={handleChangeRoleExpel}
									color='secondary'
									name='supervise'
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</Box>
						</Box>

						<Box className={`${classes.topData} ${classes.awayBelow}`}>
							<Box className={classes.inputLabel}>
								<Typography className={classes.label}>room優先度設定</Typography>
							</Box>
							<Box className={classes.inputValue}>
								<CustomSwitch
									checked={roleSetPriority}
									onChange={handleChangeRoleSetPriority}
									color='secondary'
									name='supervise'
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							</Box>
						</Box>
					</>
					}
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.cancelBtn}
						onClick={closeMethod}
						color='primary'
					>
						<FormattedMessage
							id='label.close'
							defaultMessage='Close'
						/>
					</Button>

					<Button
						className={classes.promoteBtn}
						onClick={handleSetCallingRole}
						color='primary'
					>
						変更
					</Button>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

EditRoleDialog.propTypes =
{
	classes        : PropTypes.object.isRequired,
	show           : PropTypes.bool.isRequired,
	customRoles    : PropTypes.object.isRequired,
	setCustomRoles : PropTypes.func.isRequired,
	closeMethod    : PropTypes.func.isRequired,
	roleCode       : PropTypes.string.isRequired,
	roleNumber     : PropTypes.number.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(EditRoleDialog)));