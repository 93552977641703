// React
import React, { useState, useEffect, useCallback } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import { Box, Button, MenuItem, Select, Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

// Components
import MultiBtnPageSetting from '../MultiBtnPageSetting';
import MultiBtnsSetting from '../MultiBtnsSetting';

// firebase
import { firestore, functions, storage } from '../../../../lib/firebase';
import { getStorage, ref, deleteObject } from 'firebase/storage';

// util
import { getDomainWithoutSubdomain } from '../../../../utils';

// Mui icon
import ImageIcon from '@material-ui/icons/Image';

// Components
import Dropzone from 'react-dropzone';

// const 
import { PURCHASE_PAGE_ONLY, MANAGE_PAGE_ONLY, PURCHASE_AND_MANAGE_PAGE } from '../../../../const';

import randomString from 'random-string';

const styles = () =>
	({
		root : {
			width           : '100%',
			height          : 'calc(100% - 3rem)',
			minWidth        : '1100px',
			position        : 'relative',
			display         : 'flex',
			flexDirection   : 'column',
			borderRadius    : '20px',
			backgroundColor : '#e5e5e2'
		},
		wrapper : {
			width           : '100%',
			backgroundColor : 'white',
			boxShadow       : '3px 3px 10px #aaa',
			padding         : '0.5% 1%'
		},
		group : {
			marginBottom : '2%'
		},
		pageTitle : {
			color      : 'var(--text-color)',
			fontSize   : '1.2rem',
			lineHeight : '1.4rem'
		},
		top : {
			display       : 'flex',
			flexDirection : 'row'
		},
		topLeft : {
			width : '50%'
		},
		topRight : {
			width : '50%'
		},
		topData : {
			height     : '2rem',
			display    : 'flex',
			margin     : '4% 0',
			alignItems : 'center',
			lineHeight : 1
		},
		defaultTermColumn : {
			height         : '2rem',
			display        : 'flex',
			justifyContent : 'start',
			margin         : '5px'
		},
		inputLabel : {
			width          : '20%',
			fontSize       : '0.8rem',
			whiteSpace     : 'nowrap',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'flex-end',
			justifyContent : 'center'
		},
		defaultTermLabel :
		{
			marginRight : '15px',
			fontSize    : '0.8rem'
		},
		label : {
			color        : 'var(--text-color)',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		labelSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.8rem',
			overflowWrap : 'break-word'
		},
		subLabel : {
			color        : 'var(--text-color)',
			fontSize     : '0.7rem',
			lineHeight   : '0.8rem',
			overflowWrap : 'break-word'
		},
		subLabelSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.6rem',
			lineHeight   : '0.7rem',
			overflowWrap : 'break-word'
		},
		subLabelExSmall : {
			color        : 'var(--text-color)',
			fontSize     : '0.5rem',
			lineHeight   : '0.6rem',
			overflowWrap : 'break-word'
		},
		inputValue : {
			width          : '80%',
			fontSize       : '0.8rem',
			padding        : '0 3%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		defaultTermInput :
		{
			fontSize : '0.8rem',
			width    : '100px !important'
		},
		dualItemRow :
		{
			display        : 'flex',
			justifyContent : 'space-between'
		},
		brokerId :
		{
			width : '35% !important'
		},
		brokerName : {
			width : '60% !important'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '20%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#DFDFDF'
			}
		},
		priceListInput :
		{
			minWidth : '95px'
		},
		groupTitleLine :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		fullWidth : {
			width : '100%'
		},
		emailField : {
			width : 'calc( 100% - 75px )'
		},
		editEmailBtn : {
			marginLeft : '5px',
			padding    : '2px 4px',
			fontSize   : '0.9rem'
		},
		btnRight : {
			marginRight : '10px'
		},
		inputList : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '12rem'
		},
		expiryInputCell : {
			display : 'flex'
		},
		itemTermInput : {
			fontSize : '0.9rem',
			width    : '70px !important'
		},
		actionGroup : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end',
			paddingBottom  : '1rem'
		},
		inputBtn : {
			marginLeft      : '4%',
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.3rem',
			padding         : '0.1rem 2rem',
			fontSize        : '0.8rem',
			color           : 'var(--text-color)'
		},
		inputValueTermType : {
			padding  : '0 10px',
			fontSize : '0.8rem'
		},
		inputListTermType : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '1.6rem'
		},
		inputListShowPage : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '3rem'
		},
		imageGroup : {
			width   : '100%',
			display : 'flex'
		},
		imageArea : {
			height : '10rem'
		},
		imageNameGroup : {
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'flex-start'
		},
		imageIcon : {
			color       : 'var(--text-color)',
			marginRight : '0.5rem'
		},
		imageBorder : {
			borderBottom : '1px solid var(--text-color)',
			padding      : '0 0.5rem'
		},
		imageName : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			whiteSpace : 'nowrap'
		},
		imageBox : {
			width          : '100%',
			height         : '10rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			padding        : '5% 3%',
			'&> img'       : {
				objectFit : 'contain'
			}
		},
		actionCell : {
			minWidth : '210px'
		},
		timeTicketButton : {
			marginRight : '10px'
		},
		noItemRow : {
			color : ' var(--text-color)'
		},
		errMsg :
		{
			color        : 'red',
			fontSize     : '0.9rem',
			lineHeight   : '0.9rem',
			marginBottom : '3px'
		},
		errorBox :
		{
			marginTop : '8px'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiSelect : {
			select : {
				'&:focus' : {
					borderRadius : '0.4rem'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		},
		MuiTableCell : {
			root : {
				padding : '8px'
			}
		}
	}
});

const AdminServiceAccountEditView = ({
	classes,
	closeCb,
	serviceAccount,
	serviceAccounts,
	admin,
	errors,
	setErrors,
	setConfirmDialogState,
	setLoading,
	fetchServiceAccounts
}) =>
{
	const [ id, setId ] = useState('');
	const [ paramKey, setParamKey ] = useState('');
	const [ accountName, setAccountName ] = useState('');
	const [ contactPerson, setContactPerson ] = useState('');
	const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ mobilePhoneNumber, setMobilePhoneNumber ] = useState();
	const [ address, setAddress ] = useState('');

	const [ browserTitle, setBrowserTitle ] = useState();

	const [ email, setEmail ] = useState('');

	const [ disabled, setDisabled ] = useState(false);

	const [ selfTicketPurchase, setSelfTicketPurchase ] = useState(false);

	const [ brokerAccountParamKey, setBrokerAccountParamKey ] = useState('');

	const [ brokerAccountName, setBrokerAccountName ] = useState('');

	const [ commissionPercentage, setCommissionPercentage ] = useState(10);

	const [ brokerCommissionPercentage, setBrokerCommissionPercentage ] = useState(0);
	// countFrom => purchase and use
	const [ defaultExpiryTerm, setDefaultExpiryTerm ] = useState({ value: 3, type: 'month', countFrom: 'p' });

	const [ defaultExpiryTermAfterUse, setDefaultExpiryTermAfterUse ] = useState({ value: 3, type: 'month', countFrom: 'pu' });

	// logo Image 
	const [ logoImageFile, setLogoImageFile ] = useState();
	const [ uploadLogoImageUrl, setUploadLogoImageUrl ] = useState();

	// // BG File for PC
	// const [ uploadBgPCFile, setUploadBgPCFile ] = useState();
	// const [ uploadBgPCUrl, setUploadBgPCUrl ] = useState('');

	// // BG File for SP
	// const [ uploadBgSPFile, setUploadBgSPFile ] = useState();
	// const [ uploadBgSPUrl, setUploadBgSPUrl ] = useState('');

	const [ btnAreaContentObj, setBtnAreaContentObj ] = useState({
		PC : {
			columns           : 2,
			btnMargin         : '15px 15px',
			btnPadding        : '15px 40px',
			btnFontSize       : '1.5vw',
			btnFontSizeSecond : '1vw'
		},
		SP : {
			columns           : 1,
			btnMargin         : '15px 15px',
			btnPadding        : '15px 40px',
			btnFontSize       : '2vh',
			btnFontSizeSecond : '1.5vh'
		}
	});

	const [ buttons, setButtons ] = useState([]);

	const [ priceList, setPriceList ] = useState([
		{ salesAmount: 1000, holesaleAmount: 800, minutes: 30, showPage: PURCHASE_AND_MANAGE_PAGE, type: 'time' },
		{ salesAmount: 1500, holesaleAmount: 1200, minutes: 60, showPage: PURCHASE_AND_MANAGE_PAGE, type: 'time' },
		{ salesAmount: 2000, holesaleAmount: 1600, minutes: 90, showPage: PURCHASE_AND_MANAGE_PAGE, type: 'time' },
		{ salesAmount: 2500, holesaleAmount: 2000, minutes: 120, showPage: PURCHASE_AND_MANAGE_PAGE, type: 'time' },
		{ salesAmount: 3000, holesaleAmount: 2400, minutes: 150, showPage: PURCHASE_AND_MANAGE_PAGE, type: 'time' }
	]);

	const [ priceListTerm, setPriceListTerm ] = useState(
		[]
	);

	const [ priceListExpireAfterUse, setPriceListExpireAfterUse ] = useState([]);

	const [ termsPage, setTermsPage ] = useState('default');

	const [ useDefaultDesign, setUseDefaultDesign ] = useState(false);

	const [ defaultDesignAccountParamKey, setDefaultDesignAccountParamKey ] = useState('');

	const [ issueFreeTicket, setIssueFreeTicket ] = useState(true);

	const [ editEmail, setEditEmail ] = useState(false);

	useEffect(() =>
	{
		if (!serviceAccount)
		{
			setId('');
			setAccountName('');
			setContactPerson('');
			setPhoneNumber('');
			setMobilePhoneNumber('');
			setAddress('');
			setParamKey('');
			setEmail('');
			setBrowserTitle('');
			setBrokerAccountParamKey('');
			setBrokerAccountName('');
			setCommissionPercentage(10);
			setBrokerCommissionPercentage(0);
			setDefaultExpiryTerm({ value: 3, type: 'month', countFrom: 'p' });
			setDefaultExpiryTermAfterUse({ value: 3, type: 'month', countFrom: 'pu' });
			setDisabled(false);
			setSelfTicketPurchase(false);
			setUseDefaultDesign(false);
			setDefaultDesignAccountParamKey('');
			setPriceList([ { salesAmount: 1000, holesaleAmount: 800, minutes: 30, type: 'time', showPage: PURCHASE_AND_MANAGE_PAGE },
				{ salesAmount: 1500, holesaleAmount: 1200, minutes: 60, type: 'time', showPage: PURCHASE_AND_MANAGE_PAGE },
				{ salesAmount: 2000, holesaleAmount: 1600, minutes: 90, type: 'time', showPage: PURCHASE_AND_MANAGE_PAGE },
				{ salesAmount: 2500, holesaleAmount: 2000, minutes: 120, type: 'time', showPage: PURCHASE_AND_MANAGE_PAGE },
				{ salesAmount: 3000, holesaleAmount: 2400, minutes: 150, type: 'time', showPage: PURCHASE_AND_MANAGE_PAGE } ]);
			setPriceListTerm([]);
			setPriceListExpireAfterUse([]);
			setUploadLogoImageUrl('');
			setIssueFreeTicket(true);
			// setUploadBgPCUrl('');
			// setUploadBgSPUrl('');
			setButtons([]);

			setBtnAreaContentObj({
				PC : {
					areaWidth         : '50vw',
					areaHeight        : '25vh',
					columns           : 2,
					btnMargin         : '15px 15px',
					btnPadding        : '15px 40px',
					btnFontSize       : '1.5vw',
					btnFontSizeSecond : '1vw'
				},
				SP : {
					areaWidth         : '90vw',
					areaHeight        : '25vh',
					columns           : 1,
					btnMargin         : '15px 15px',
					btnPadding        : '15px 40px',
					btnFontSize       : '2vh',
					btnFontSizeSecond : '1.5vh'
				}
			});
		}
		else
		{
			setId(serviceAccount.id ? serviceAccount.id : '');
			setAccountName(serviceAccount.accountName ? serviceAccount.accountName : '');
			setContactPerson(serviceAccount.contactPerson ? serviceAccount.contactPerson : '');
			setPhoneNumber(serviceAccount.phoneNumber ? serviceAccount.phoneNumber : '');
			setMobilePhoneNumber(serviceAccount.mobilePhoneNumber ? serviceAccount.mobilePhoneNumber : '');
			setAddress(serviceAccount.address ? serviceAccount.address : '');
			setParamKey(serviceAccount.paramKey ? serviceAccount.paramKey : '');
			setEmail(serviceAccount.email ? serviceAccount.email : '');
			setBrowserTitle(serviceAccount.browserTitle ? serviceAccount.browserTitle : '');
			setBrokerAccountParamKey(serviceAccount.brokerAccountParamKey ? serviceAccount.brokerAccountParamKey : '');
			setBrokerAccountName(serviceAccount.brokerAccountName ? serviceAccount.brokerAccountName : '');
			setCommissionPercentage(
				(serviceAccount.commissionPercentage
				|| serviceAccount.commissionPercentage === 0) ?
					serviceAccount.commissionPercentage : 10
			);
			setBrokerCommissionPercentage(serviceAccount.brokerCommissionPercentage
				? serviceAccount.brokerCommissionPercentage : 0
			);
			setDefaultExpiryTerm(serviceAccount.defaultExpiryTerm ?
				{ ...serviceAccount.defaultExpiryTerm, countFrom: 'p' } : { value: 3, type: 'month', countFrom: 'p' });
			setDefaultExpiryTermAfterUse(serviceAccount.defaultExpiryTermAfterUse ?
				{ ...serviceAccount.defaultExpiryTermAfterUse, countFrom: 'pu' } : { value: 3, type: 'month', countFrom: 'pu' });
			setDisabled(serviceAccount.disabled ? true: false);
			setSelfTicketPurchase(serviceAccount.selfTicketPurchase ? true :false);
			setTermsPage(serviceAccount.termsPage ? serviceAccount.termsPage : 'default');
			setUseDefaultDesign(serviceAccount.useDefaultDesign ? true :false);
			setDefaultDesignAccountParamKey(serviceAccount.defaultDesignAccountParamKey ? serviceAccount.defaultDesignAccountParamKey : '');
			setPriceList(serviceAccount.priceList ? serviceAccount.priceList : []);
			setPriceListTerm(serviceAccount.priceListTerm ? serviceAccount.priceListTerm : []);
			setPriceListExpireAfterUse(serviceAccount.priceListExpireAfterUse ?
				serviceAccount.priceListExpireAfterUse : []);

			setUploadLogoImageUrl(serviceAccount.logoImageUrl ? serviceAccount.logoImageUrl : '');
			// setUploadBgPCUrl(serviceAccount.bgPCUrl ? serviceAccount.bgPCUrl : '');
			// setUploadBgSPUrl(serviceAccount.bgSPUrl ? serviceAccount.bgSPUrl : '');
			setButtons(serviceAccount.buttons ? serviceAccount.buttons : []);

			setIssueFreeTicket(serviceAccount.issueFreeTicket ? true : false);

			setBtnAreaContentObj(
				serviceAccount.btnAreaContent
					&& serviceAccount.btnAreaContent.PC
					&& serviceAccount.btnAreaContent.SP
					? serviceAccount.btnAreaContent
					: {
						PC : {
							columns           : 2,
							btnMargin         : '15px 15px',
							btnPadding        : '15px 40px',
							btnFontSize       : '1.5vw',
							btnFontSizeSecond : '1vw'
						},
						SP : {
							columns           : 1,
							btnMargin         : '15px 15px',
							btnPadding        : '15px 40px',
							btnFontSize       : '2vw',
							btnFontSizeSecond : '1.5vw'
						}
					});
		}
	// eslint-disable-next-line
	}, [ serviceAccount ]);

	const handleChangeParamKey = (e) =>
	{
		const paramKeyReg = new RegExp(/^[a-z0-9-_]+$/);

		const value = e.target.value;

		if (!value || paramKeyReg.test(value))
		{
			setParamKey(value);
		}
	};

	const handleChangeEmail = (e) =>
	{
		setEmail(e.target.value);
	};

	const handleChangeAccountName = (e) =>
	{
		setAccountName(e.target.value);
	};

	const handleChangeContactPerson = (e) =>
	{
		setContactPerson(e.target.value);
	};

	const handleChangePhoneNumber = (e) =>
	{
		setPhoneNumber(e.target.value);
	};

	const handleChangeMobilePhoneNumber = (e) =>
	{
		setMobilePhoneNumber(e.target.value);
	};

	const handleChangeAddress = (e) =>
	{
		setAddress(e.target.value);
	};

	const handleChangeBrowserTitle = (e) =>
	{
		setBrowserTitle(e.target.value);
	};

	const handleChangeBrokerAccountParamKey = (e) =>
	{
		setBrokerAccountParamKey(e.target.value);
	};

	const handleChangeCommissionPercentage = (e) =>
	{
		setCommissionPercentage(Number(e.target.value));
	};

	const handleChangeBrokerCommissionPercentage = (e) =>
	{
		setBrokerCommissionPercentage(Number(e.target.value));
	};

	const handleChangeIssueFreeTicket = (e) =>
	{
		let value = false;

		if (e.target.value)
		{
			value = true;
		}

		setIssueFreeTicket(value);
	};

	const handleChangeDisabled = (e) =>
	{
		let value = false;

		if (e.target.value)
		{
			value = true;
		}

		setDisabled(value);
	};

	const handleChangeSelfTicketPurchase = (e) =>
	{
		let value = false;

		if (e.target.value)
		{
			value = true;
		}

		setSelfTicketPurchase(value);
	};

	const handleChangeTermsPage = (e) =>
	{
		setTermsPage(e.target.value);
	};

	const handleChangeUseDefaultDesign = (e) =>
	{
		let value = false;

		if (e.target.value)
		{
			value = true;
		}

		setUseDefaultDesign(value);
	};

	const handleChangeDefaultDesignAccountParamKey = (e) =>
	{
		setDefaultDesignAccountParamKey(e.target.value);
	};

	const handleChangeDefaultExpiryValue = (e) =>
	{
		const value = e.target.value;

		setDefaultExpiryTerm((state) =>
		{
			return { ...state, value: Number(value) };
		});
	};

	const handleChangeDefaultExpiryType = (e) =>
	{
		const value = e.target.value;

		setDefaultExpiryTerm((state) =>
		{
			return { ...state, type: value };
		});
	};

	const handleChangeDefaultExpiryValueAfterUse = (e) =>
	{
		const value = e.target.value;

		setDefaultExpiryTermAfterUse((state) =>
		{
			return { ...state, value: Number(value) };
		});
	};

	const handleChangeDefaultExpiryTypeAfterUse = (e) =>
	{
		const value = e.target.value;

		setDefaultExpiryTermAfterUse((state) =>
		{
			return { ...state, type: value };
		});
	};

	// const uploadBgPC = async () =>
	// {
	// 	// 保存済み
	// 	if (id && paramKey)
	// 	{
	// 		let url = uploadBgPCUrl ? uploadBgPCUrl : '';

	// 		if (uploadBgPCFile && uploadBgPCFile.name)
	// 		{
	// 			const storageref 
	//  = storage.ref(`/images/serviceAccountImages/${paramKey}/logo_${uploadBgPCFile.name}`);
	// 			const snapshot = await storageref.put(uploadBgPCFile);

	// 			url = await snapshot.ref.getDownloadURL();
	// 			setUploadBgPCUrl(url);
	// 		}

	// 		return url;
	// 	}
	// };

	// const uploadBgSP = async () =>
	// {
	// 	// 保存済み
	// 	if (id && paramKey)
	// 	{
	// 		let url = uploadBgSPUrl ? uploadBgSPUrl : '';

	// 		if (uploadBgSPFile && uploadBgSPFile.name)
	// 		{
	// 			const storageref 
	//             = storage.ref(`/images/serviceAccountImages/${paramKey}/logo_${uploadBgSPFile.name}`);
	// 			const snapshot = await storageref.put(uploadBgSPFile);

	// 			url = await snapshot.ref.getDownloadURL();
	// 			setUploadBgSPUrl(url);
	// 		}

	// 		return url;
	// 	}
	// };

	const addPriceListItem = () =>
	{
		if (priceList.length < 100)
		{
			setPriceList((state) =>
			{

				const priceListAr = [ ...state ];

				priceListAr.push({ minutes: 0, salesAmount: 0, holesaleAmount: 0, showPage: PURCHASE_AND_MANAGE_PAGE, type: 'time' });

				return [ ...priceListAr ];
			});
		}
		else
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : 'プライスリストの項目は100個までです。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true
				}
			);
		}
	};

	const addPriceListItemTerm = () =>
	{
		if (priceListTerm.length < 100)
		{
			setPriceListTerm((state) =>
			{

				const priceListAr = [ ...state ];

				priceListAr.push({ minutes: 0, salesAmount: 0, holesaleAmount: 0, expiryTerm: { value: 0, type: 'day', countFrom: 'p' }, showPage: PURCHASE_AND_MANAGE_PAGE, type: 'term' });

				return [ ...priceListAr ];
			});
		}
		else
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : 'プライスリストの項目は100個までです。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true
				}
			);
		}
	};

	const addPriceListItemExpireAfterUse = () =>
	{
		if (priceListExpireAfterUse.length < 100)
		{
			setPriceListExpireAfterUse((state) =>
			{

				const priceListAr = [ ...state ];

				priceListAr.push({ minutes: 0, salesAmount: 0, holesaleAmount: 0, expiryTerm: defaultExpiryTermAfterUse, expiryTermAfterUse: { value: 0, type: 'day' }, showPage: PURCHASE_AND_MANAGE_PAGE, type: 'afterUse' });

				return [ ...priceListAr ];
			});
		}
		else
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : 'プライスリストの項目は100個までです。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true
				}
			);
		}
	};

	const deletePriceListItem = (index) =>
	{
		if (index >= 0)
		{
			setPriceList((state) =>
			{

				const priceListAr = [ ...state ];

				priceListAr.splice(index, 1);

				return [ ...priceListAr ];
			});
		}
	};

	const deletePriceListTermItem = (index) =>
	{
		if (index >= 0)
		{
			setPriceListTerm((state) =>
			{

				const priceListAr = [ ...state ];

				priceListAr.splice(index, 1);

				return [ ...priceListAr ];
			});
		}
	};

	const deletePriceListItemAfterUse = (index) =>
	{
		if (index >= 0)
		{
			setPriceListExpireAfterUse((state) =>
			{

				const priceListAr = [ ...state ];

				priceListAr.splice(index, 1);

				return [ ...priceListAr ];
			});
		}
	};

	const toggleInfiniteDurationPLItem = (index) =>
	{
		if (index >= 0)
		{
			setPriceList((state) =>
			{

				const priceListAr = [ ...state ];

				const currentValue = priceListAr[index].minutes;

				if (currentValue === -1)
				{
					priceListAr[index].minutes = 0;
				}
				else
				{
					priceListAr[index].minutes = -1;
				}

				return [ ...priceListAr ];

			});
		}
	};

	const toggleInfiniteDurationPLTermItem = (index) =>
	{
		if (index >= 0)
		{
			setPriceListTerm((state) =>
			{

				const priceListAr = [ ...state ];

				const currentValue = priceListAr[index].minutes;

				if (currentValue === -1)
				{
					priceListAr[index].minutes = 0;
				}
				else
				{
					priceListAr[index].minutes = -1;
				}

				return [ ...priceListAr ];

			});
		}
	};

	const toggleInfiniteDurationPLAfterUseItem = (index) =>
	{
		if (index >= 0)
		{
			setPriceListExpireAfterUse((state) =>
			{

				const priceListAr = [ ...state ];

				const currentValue = priceListAr[index].minutes;

				if (currentValue === -1)
				{
					priceListAr[index].minutes = 0;
				}
				else
				{
					priceListAr[index].minutes = -1;
				}

				return [ ...priceListAr ];

			});
		}
	};

	const handleChangePriceListItem = (key, index, e) =>
	{
		if (index >= 0 && e?.target?.value)
		{
			const value = e.target.value;

			setPriceList((state) =>
			{
				const priceListAr = [ ...state ];

				priceListAr[index][key] = Number(value);

				return [ ...priceListAr ];
			});
		}
	};

	const handleChangeExpiryTermAfterUseItem = (type, index, e) =>
	{
		if (index >= 0 && e?.target?.value)
		{
			let newValue = e.target.value;

			if (type === 'value')
			{
				newValue = Number(newValue);
			}

			setPriceListExpireAfterUse((state) =>
			{
				const priceListArAfterUse = [ ...state ];

				if (!priceListArAfterUse[index]['expiryTermAfterUse'])
				{
					priceListArAfterUse[index]['expiryTermAfterUse'] = {};
				}

				priceListArAfterUse[index]['expiryTermAfterUse'][type] = newValue;

				return [ ...priceListArAfterUse ];
			});
		}
	};

	const handleChangePriceListTermItem = (key, index, e) =>
	{
		if (index >= 0)
		{
			const value = e.target.value;

			setPriceListTerm((state) =>
			{

				const priceListAr = [ ...state ];

				priceListAr[index][key] = Number(value);

				return [ ...priceListAr ];
			});
		}
	};

	const handleChangePriceListItemAfterUse = (key, index, e) =>
	{
		if (index >= 0 && e?.target?.value)
		{
			const value = e.target.value;

			setPriceListExpireAfterUse((state) =>
			{
				const priceListAr = [ ...state ];

				priceListAr[index][key] = Number(value);

				return [ ...priceListAr ];
			});
		}
	};

	const handleChangePriceListTermExpiryTermValue = (index, e) =>
	{
		if (index >= 0)
		{
			const value = e.target.value;

			setPriceListTerm((state) =>
			{

				const priceListAr = [ ...state ];

				priceListAr[index]['expiryTerm']['value'] = Number(value);

				return [ ...priceListAr ];
			});
		}
	};

	const onDropLogo = useCallback((acceptedFiles) =>
	{

		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setUploadLogoImageUrl(src);
			setLogoImageFile(acceptedFiles[0]);
		}

	}, [ setLogoImageFile, setUploadLogoImageUrl ]);

	const uploadLogo = async () =>
	{
		if (serviceAccount)
		{
			let url = uploadLogoImageUrl ? uploadLogoImageUrl : '';

			if (logoImageFile && logoImageFile.name)
			{
				const storageref = storage.ref(`/images/servicesImages/${paramKey}/logo_${logoImageFile.name}`);
				const snapshot = await storageref.put(logoImageFile);

				url = await snapshot.ref.getDownloadURL();
				setUploadLogoImageUrl(url);
			}

			return url;
		}
	};

	const deleteLogo = () =>
	{

		if (serviceAccount && uploadLogoImageUrl && id)
		{
			setLoading(true);

			firestore.collection('serviceAccounts').doc(id)
				.update({
					logoImageUrl : ''
				})
				.then(() =>
				{
					setUploadLogoImageUrl('');

					fetchServiceAccounts();
					setLoading(false);

					const storageObj = getStorage();

					// url decode
					const uploadLogoImageUrlValue = decodeURIComponent(uploadLogoImageUrl);

					const fileNameReg = new RegExp(/\/images\/.+\?/);

					// get file directory by Array
					const fileDirectoryAr = uploadLogoImageUrlValue.match(fileNameReg) || [ '' ];

					let fileDirectory = fileDirectoryAr[0];

					// remove last letter(?)
					fileDirectory = fileDirectory.slice(0, fileDirectory.length -1);

					// /images/servicesImages/****/までで28文字
					if (fileDirectory.length > 30)
					{
						// Create a reference to the file to delete
						const deletetRef = ref(storageObj, fileDirectory);

						deleteObject(deletetRef);
					}
				})
				.catch(() =>
				{
					setLoading(false);
					setErrors('削除に失敗しました');
				});

		}
	};

	const handleSave = async () =>
	{
		// 作成時
		if (!serviceAccount)
		{
			if (!paramKey)
			{
				setErrors({
					show   : true,
					errors : [ 'IDがありません' ]
				});

				return;
			}

			if (!mobilePhoneNumber)
			{
				setErrors({
					show   : true,
					errors : [ '携帯電話番号がありません' ]
				});

				return;
			}

			const paramKeyReg = new RegExp(/^[a-z0-9]+$/);

			if (!paramKeyReg.test(paramKey))
			{
				setErrors({
					show   : true,
					errors : [ 'IDには英小文字と数字のみ使用できます' ]
				});

				return;
			}

			if (paramKey.length !== 4)
			{
				setErrors({
					show   : true,
					errors : [ 'IDは4文字としてください' ]
				});

				return;
			}

			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

			if (!email)
			{
				setErrors({
					show   : true,
					errors : [ 'メールアドレスが入力されていません' ]
				});

				return;
			}
			else if (!emailPattern.test(email))
			{
				setErrors({
					show   : true,
					errors : [ 'メールアドレスが正しくありません' ]
				});

				return;
			}

			if (mobilePhoneNumber.length !== 11)
			{
				setErrors({
					show   : true,
					errors : [ '携帯電話番号の長さが正しくありません' ]
				});

				return;
			}

			const phoneNumberReg = new RegExp(/^[0-9]+$/);

			if (!phoneNumberReg.test(mobilePhoneNumber))
			{
				setErrors({
					show   : true,
					errors : [ '携帯電話番号には半角数字のみ使用できます' ]
				});

				return;
			}

		}

		setErrors({
			show   : false,
			errors : []
		});

		setLoading(true);

		// sort price list
		const priceListSort = [ ...priceList ];

		const hashFromTimestamp= Number(new Date()).toString(36)
			.slice(-8);

		const itemIdBase = hashFromTimestamp + randomString({ length: 2 }).toLowerCase();

		priceListSort.forEach((itm, index) =>
		{
			itm.expiryTerm = defaultExpiryTerm;
			itm.itemId = `${itemIdBase}1${(`0${index}`).slice(-2)}`;
		});

		const priceListTermSort = [ ...priceListTerm ];

		priceListTermSort.forEach((itm, index) =>
		{
			itm.itemId = `${itemIdBase}2${(`0${index}`).slice(-2)}`;
		});

		const priceListExpireAfterUseSort = [ ...priceListExpireAfterUse ];

		priceListExpireAfterUseSort.forEach((itm, index) =>
		{
			itm.expiryTerm = defaultExpiryTermAfterUse;
			itm.itemId = `${itemIdBase}3${(`0${index}`).slice(-2)}`;
		});

		const logoImageUrl = await uploadLogo();

		const updateValue = {
			accountName                : accountName,
			contactPerson              : contactPerson,
			phoneNumber                : phoneNumber,
			mobilePhoneNumber          : mobilePhoneNumber,
			address                    : address,
			browserTitle               : browserTitle,
			disabled                   : disabled,
			selfTicketPurchase         : selfTicketPurchase,
			defaultExpiryTerm          : defaultExpiryTerm,
			defaultExpiryTermAfterUse  : defaultExpiryTermAfterUse,
			brokerAccountParamKey      : brokerAccountParamKey,
			commissionPercentage       : commissionPercentage,
			brokerCommissionPercentage : brokerAccountParamKey ?
				brokerCommissionPercentage : 0,
			btnAreaContent               : btnAreaContentObj,
			buttons                      : buttons,
			logoImageUrl                 : logoImageUrl,
			priceList                    : priceListSort,
			priceListTerm                : priceListTermSort,
			priceListExpireAfterUse      : priceListExpireAfterUseSort,
			termsPage                    : termsPage,
			useDefaultDesign             : useDefaultDesign,
			defaultDesignAccountParamKey : defaultDesignAccountParamKey,
			brokerAccountName            : '',
			issueFreeTicket              : issueFreeTicket
		};

		// 他のアカウントのデザインを使用
		if (useDefaultDesign)
		{
			if (!defaultDesignAccountParamKey)
			{
				setErrors({
					show   : true,
					errors : [ 'デザインアカウントIDを指定してください' ]
				});

				setLoading(false);

				return;
			}

			const accounts = await firestore.collection('serviceAccounts')
				.where('paramKey', '==', defaultDesignAccountParamKey)
				.get();

			if (accounts && accounts.docs[0])
			{
				const accountData = accounts.docs[0].data();

				if (accountData.useDefaultDesign)
				{
					setErrors({
						show   : true,
						errors : [ `標準デザインのアカウント${defaultDesignAccountParamKey}は`, `標準デザインにアカウントID${accountData.defaultDesignAccountParamKey}を使用しています。` ]
					});

					setLoading(false);

					return;
				}

				updateValue.btnAreaContent = accountData.btnAreaContent;
				updateValue.buttons = accountData.buttons;
			}
			else
			{
				setErrors({
					show   : true,
					errors : [ '標準デザインのアカウントIDが存在しません' ]
				});

				setLoading(false);

				return;
			}
		}

		let brokerAccountId = '';

		let brokerAccountBrokageCount = 0;

		if (brokerAccountParamKey)
		{
			const brokerAccountParamKeyIndex = serviceAccounts.findIndex(
				(pt) => pt.paramKey === brokerAccountParamKey);

			if (brokerAccountParamKeyIndex === -1)
			{
				setErrors({
					show   : true,
					errors : [ '仲介企業IDが存在しません' ]
				});

				return;
			}
			else
			{

				updateValue.brokerAccountName
					= serviceAccounts[brokerAccountParamKeyIndex].accountName;

				brokerAccountBrokageCount += 1;

				brokerAccountId = serviceAccounts[brokerAccountParamKeyIndex].id;

				serviceAccounts.forEach((account) =>
				{
					if (account.brokerAccountParamKey === brokerAccountParamKey)
					{
						brokerAccountBrokageCount += 1;
					}
				});
			}
		}

		// create
		if (!serviceAccount)
		{
			if (!admin.rights?.createUser)
			{
				setErrors({
					show   : true,
					errors : [ '作成権限がありません' ]
				});

				setLoading(false);

				return;
			}

			const errs= [];

			const sameIdIndex = serviceAccounts.findIndex((pt) => pt.paramKey === paramKey);

			if (sameIdIndex !== -1)
			{
				errs.push('idが重複しています');
			}

			const sameEmailIndex = serviceAccounts.findIndex((pt) => pt.email === email);

			if (sameEmailIndex !== -1)
			{
				errs.push('メールアドレスが重複しています');
			}

			const sameMobilePhoneIndex
				= serviceAccounts.findIndex((pt) => pt.mobilePhoneNumber === mobilePhoneNumber);

			if (sameMobilePhoneIndex !== -1)
			{
				errs.push('携帯電話番号が重複しています');
			}

			if (errs.length > 0)
			{

				setErrors({
					show   : true,
					errors : [ ...errs ]
				});
				setLoading(false);

				return;
			}
			else
			{
				const serviceAccountCreateAPI = functions.httpsCallable('serviceAccountCreate');

				const domain = getDomainWithoutSubdomain();

				serviceAccountCreateAPI({
					domain                    : domain,
					paramKey                  : paramKey,
					email                     : email,
					...updateValue,
					brokerAccountId           : brokerAccountId,
					brokerAccountBrokageCount : brokerAccountBrokageCount
				})
					.then((response) =>
					{
						const data = response.data;

						if (data.success)
						{

							closeCb();
							setErrors({
								show   : false,
								errors : []
							});
							fetchServiceAccounts();
							setLoading(false);
						}
						else
						{
							setErrors({
								show   : true,
								errors : [ '保存に失敗しました' ]
							});
							setLoading(false);
						}
					})
					.catch(() =>
					{
						setErrors({
							show   : true,
							errors : [ '保存に失敗しました' ]
						});
						setLoading(false);
					});
			}
		}
		// edit
		else
		{
			if (!admin.rights?.editUser)
			{
				setErrors({
					show   : true,
					errors : [ '編集権限がありません' ]
				});
				setLoading(false);

				return;
			}

			firestore.collection('serviceAccounts').doc(id)
				.update({
					...updateValue
				})
				.then(() =>
				{
					closeCb();
					setErrors({
						show   : false,
						errors : []
					});

					fetchServiceAccounts({
						checkDesignUpdate : true,
						accountParamKey   : paramKey,
						updateValue       : updateValue
					});
					setLoading(false);
				})
				.catch(() =>
				{
					setErrors({
						show   : true,
						errors : [ '保存に失敗しました' ]
					});
					setLoading(false);
				});
		}
	};

	const updateEmail = () =>
	{
		if (serviceAccount && id && email)
		{
			setErrors({
				show   : false,
				errors : []
			});

			const updateAuthEmailAPI = functions.httpsCallable('updateAuthEmail');

			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

			if (!emailPattern.test(email))
			{
				setErrors({
					show   : true,
					errors : [ 'メールアドレスが正しくありません。' ]
				});

				return;
			}

			setLoading(true);

			updateAuthEmailAPI({
				type  : 'service',
				id    : id,
				email : email
			})
				.then((response) =>
				{
					if (response.data?.success)
					{
						setErrors({
							show   : false,
							errors : []
						});
						setEditEmail(false);
						setLoading(false);
					}
					else
					{
						setErrors({
							show   : true,
							errors : [ 'メールアドレスの変更に失敗しました' ]
						});
						setLoading(false);
					}
				})
				.catch(() =>
				{
					setErrors({
						show   : true,
						errors : [ 'メールアドレスの変更に失敗しました' ]
					});
					setLoading(false);
				});

		}

	};

	const deleteAccount = () =>
	{
		if (paramKey)
		{
			const childAccountIndex = serviceAccounts.findIndex(
				(pt) => pt.brokerAccountParamKey === paramKey);

			if (childAccountIndex !== -1)
			{
				setErrors({
					show   : true,
					errors : [ `このアカウントはアカウントID:${serviceAccounts[childAccountIndex].paramKey}で`, '仲介企業IDに指定されているため削除できません。' ]
				});

				return;
			}

			const defaultDesignIndex = serviceAccounts.findIndex(
				(pt) => pt.defaultDesignAccountParamKey === paramKey);

			if (defaultDesignIndex !== -1)
			{
				setErrors({
					show   : true,
					errors : [ `このアカウントはアカウントID:${serviceAccounts[defaultDesignIndex].paramKey}で`, '標準デザインアカウントに指定されているため削除できません。' ]
				});

				return;
			}
		}

		setLoading(true);

		const serviceAccountDeleteAPI = functions.httpsCallable('serviceAccountDelete');

		let brokerAccountId = '';

		let brokerAccountBrokageCount = 0;

		if (brokerAccountParamKey)
		{
			const brokerAccountParamKeyIndex = serviceAccounts.findIndex(
				(pt) => pt.paramKey === brokerAccountParamKey);

			if (brokerAccountParamKeyIndex !== -1)
			{
				brokerAccountId = serviceAccounts[brokerAccountParamKeyIndex].id;

				serviceAccounts.forEach((account) =>
				{
					if (account.brokerAccountParamKey === brokerAccountParamKey)
					{
						brokerAccountBrokageCount += 1;
					}
				});

				if (brokerAccountBrokageCount > 0)
				{
					brokerAccountBrokageCount -= 1;
				}
			}

		}

		setConfirmDialogState(
			{
				show            : false,
				title           : '',
				message         : '',
				acceptMethod    : () => {},
				acceptMethodArg : undefined,
				closeButtonOnly : true
			}
		);

		serviceAccountDeleteAPI({
			id                        : id,
			paramKey                  : paramKey,
			brokerAccountId           : brokerAccountId,
			brokerAccountBrokageCount : brokerAccountBrokageCount
		})
			.then((response) =>
			{
				if (response.data?.success)
				{
					closeCb();
					setErrors({
						show   : false,
						errors : []
					});
					fetchServiceAccounts();
					setLoading(false);
				}
				else
				{
					setErrors({
						show   : true,
						errors : [ '削除に失敗しました' ]
					});
					setLoading(false);
				}
			})
			.catch(() =>
			{
				setErrors({
					show   : true,
					errors : [ '削除に失敗しました' ]
				});
				setLoading(false);
			});

	};

	const handleDelete = () =>
	{
		if (!admin.rights?.deleteUser)
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : '削除する権限がありません。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true
				}
			);
		}
		else
		{
			setConfirmDialogState({
				show            : true,
				title           : '確認',
				message         : 'チケットアカウントを削除しますか?',
				acceptMethod    : deleteAccount,
				acceptMethodArg : {},
				closeButtonOnly : false
			});
		}
	};

	const handleCancel = () =>
	{
		if (closeCb)
		{
			closeCb();
			setErrors({
				show   : false,
				errors : []
			});
		}
	};

	const showExpiryTerm = (term) =>
	{
		if (term.value === 0)
		{
			return '使い放題';
		}
		else
		{
			return `${term.value}${term.type === 'month' ? 'ヶ月' : '日'}`;
		}
	};

	// eslint-disable-next-line no-console
	console.table(priceList);

	return (
		<MuiThemeProvider theme={theme}>

			<Box className={classes.root}>
				<Box className={`${classes.wrapper} ${classes.group}`}>
					<Typography className={classes.pageTitle}>
						チケットアカウント登録情報
					</Typography>
					{errors.show === true &&
					<Box className={classes.errorBox}>
						{errors.errors.map((err, index) =>
							(<Typography className={classes.errMsg} key={index}>
								・{err}
							</Typography>)
						)}
					</Box>
					}

					<Box className={classes.top}>
						<Box className={classes.topLeft}>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ID</Typography>
									<Typography className={classes.subLabelSmall}>
										英小文字・数字 4文字
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={paramKey}
										className={`${classes.inputField} ${classes.priceList}`}
										onChange={handleChangeParamKey}
										disabled={serviceAccount}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>企業名</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={accountName}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeAccountName}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>担当者氏名</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={contactPerson}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeContactPerson}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>メールアドレス</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={email}
										className={`${classes.inputField} ${classes.emailField}`}
										onChange={handleChangeEmail}
										disabled={serviceAccount && !editEmail}
										type='text'
									/>
									{	editEmail ?
										<Button
											variant='contained'
											color='secondary'
											onClick={updateEmail}
											disabled={!serviceAccount}
											className={classes.editEmailBtn}
										>
											更新
										</Button>
										:
										<Button
											variant='contained'
											color='primary'
											onClick={() => setEditEmail(true)}
											disabled={!serviceAccount}
											className={classes.editEmailBtn}
										>
											編集
										</Button>
									}
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>携帯電話番号</Typography>
									<Typography className={classes.subLabelSmall}>
										(二段階認証用)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={mobilePhoneNumber}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeMobilePhoneNumber}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>電話番号</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={phoneNumber}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangePhoneNumber}
										type='text'
									/>
								</Box>
							</Box>
							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>住所</Typography>
									<Typography className={classes.subLabelSmall}>
										(請求書送付先)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={address}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeAddress}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>アカウント</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={disabled}
										onChange={handleChangeDisabled}
									>
										<MenuItem value={false}>有効</MenuItem>
										<MenuItem value>無効</MenuItem>
									</Select>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>企業管理ページURL</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										disabled
										value={`https://${getDomainWithoutSubdomain()}/manage`}
										className={`${classes.inputField} ${classes.fullWidth}`}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={`${classes.topData} ${classes.imageArea}`}>
								<Box className={classes.imageNameGroup}>
									<ImageIcon className={classes.imageIcon}/>
									<Box className={classes.imageBorder}>
										<Typography className={classes.imageName}>
											ロゴ画像ファイル
										</Typography>
										<Dropzone onDrop={onDropLogo} className={classes.imageName}>
											{ ({ getRootProps, getInputProps }) => (
												<section>
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														{logoImageFile ?
															<Typography>{logoImageFile.name}</Typography>
															:
															<Typography>ドラッグ or クリック</Typography>
														}
													</div>
												</section>
											)}
										</Dropzone>
									</Box>
								</Box>

								{ uploadLogoImageUrl &&
									<Box>
										<Box className={classes.imageBox}>
											<img
												alt='logo'
												src={uploadLogoImageUrl}
												height='100%'
												width='100%'
											/>
										</Box>

										<Box>
											<Button
												variant='contained'
												color='secondary'
												onClick={deleteLogo}
											>
												ロゴ削除
											</Button>
										</Box>
									</Box>
								}
							</Box>
						</Box>
						<Box className={classes.topRight}>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>ブラウザタイトル</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={browserTitle}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeBrowserTitle}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>仲介企業ID</Typography>
									<Typography className={classes.subLabelSmall}>
										アカウントIDを指定
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Box className={classes.dualItemRow}>
										<input
											value={brokerAccountParamKey}
											className={`${classes.inputField} ${classes.brokerId}`}
											onChange={(e) => handleChangeBrokerAccountParamKey(e)}
											type='text'
										/>

										<input
											value={brokerAccountName}
											disabled
											className={`${classes.inputField} ${classes.brokerName}`}
											type='text'
										/>
									</Box>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>手数料</Typography>
									<Typography className={classes.subLabelSmall}>
										(%)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input value={commissionPercentage} className={classes.inputField} onChange={handleChangeCommissionPercentage} type='number' max='100' min='0'/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>仲介企業手数料</Typography>
									<Typography className={classes.subLabelSmall}>
										(%)
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={brokerCommissionPercentage}
										className={classes.inputField}
										onChange={handleChangeBrokerCommissionPercentage}
										disabled={!brokerAccountParamKey}
										type='number'
										max='100'
										min='0'
									/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>標準デザイン使用</Typography>
									<Typography className={classes.subLabelSmall}>
										他アカウントから継承
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={useDefaultDesign}
										onChange={(e) => handleChangeUseDefaultDesign(e)}
									>
										<MenuItem value={false}>無効</MenuItem>
										<MenuItem value>有効</MenuItem>
									</Select>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>標準デザイン指定</Typography>
									<Typography className={classes.subLabelSmall}>
										アカウントIDを指定
									</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										value={defaultDesignAccountParamKey}
										disabled={!useDefaultDesign}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={handleChangeDefaultDesignAccountParamKey}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>無料チケット発行</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={issueFreeTicket}
										onChange={handleChangeIssueFreeTicket}
									>
										<MenuItem value={false}>無効</MenuItem>
										<MenuItem value>有効</MenuItem>
									</Select>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>チケット自社購入</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={selfTicketPurchase}
										onChange={handleChangeSelfTicketPurchase}
									>
										<MenuItem value={false}>無効</MenuItem>
										<MenuItem value>有効</MenuItem>
									</Select>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>利用規約</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<Select
										classes={{ root: classes.inputList }}
										value={termsPage}
										onChange={(e) => handleChangeTermsPage(e)}
									>
										<MenuItem value={'default'}>サイト共通</MenuItem>
										<MenuItem value={'liveTranslate'}>LIVE通訳専用</MenuItem>
									</Select>
								</Box>
							</Box>

							<Box className={classes.topData}>
								<Box className={classes.inputLabel}>
									<Typography className={classes.label}>利用者ページURL</Typography>
								</Box>
								<Box className={classes.inputValue}>
									<input
										disabled
										value={serviceAccount && paramKey ? `https://${getDomainWithoutSubdomain()}/service?id=${paramKey}` : ''}
										className={`${classes.inputField} ${classes.fullWidth}`}
										type='text'
									/>
								</Box>
							</Box>
						</Box>

					</Box>
				</Box>

				{ !useDefaultDesign &&
					<>
						< MultiBtnPageSetting
							type={'service'}
							bgPCGradient={''}
							setBgPCGradient={() => {}}
							bgSPGradient={''}
							setBgSPGradient={() => {}}
							uploadBgPCFile={null}
							setUploadBgPCFile={() => {}}
							uploadBgPCUrl={''}
							setUploadBgPCUrl={() => {}}
							uploadBgSPFile={null}
							setUploadBgSPFile={() => {}}
							uploadBgSPUrl={''}
							setUploadBgSPUrl={() => {}}
							btnAreaPositionObj={{}}
							setBtnAreaPositionObj={() => {}}
							btnPosDirectionSelector={() => {}}
							setBtnPosDirectionSelector={() => {}}
							btnAreaContentObj={btnAreaContentObj}
							setBtnAreaContentObj={setBtnAreaContentObj}
							backButtonActive={false}
							setBackButtonActive={() => {}}
							backButtonText={''}
							setBackButtonText={() => {}}
							backButtonUrl={''}
							setBackButtonUrl={() => {}}
							setBackButtonTextColor={() => {}}
							backButtonBGColor={''}
							setBackButtonBGColor={() => {}}
							backButtonTextMarginPC={''}
							setBackButtonTextMarginPC={() => {}}
							backButtonTextMarginSP={''}
							setBackButtonTextMarginSP={() => {}}
						/>

						<MultiBtnsSetting
							type={'service'}
							buttons={buttons}
							setButtons={setButtons}
						/>
					</>
				}

				<Box className={`${classes.wrapper} ${classes.group}`}>
					<Box className={classes.groupTitleLine}>
						<Typography className={classes.pageTitle}>
							時間別価格リスト
						</Typography>
						<Box>
							<Box>
								<Button
									variant='contained'
									color='primary'
									onClick={() => addPriceListItem('time')}
									className={classes.timeTicketButton}
								>
									追加
								</Button>
							</Box>
						</Box>
					</Box>

					<Box className={classes.defaultTermColumn}>
						<Box className={classes.defaultTermLabel}>
							<Typography className={classes.label}>購入後標準期限</Typography>
							<Typography className={classes.subLabelSmall}>
								0は無制限
							</Typography>
						</Box>
						<Box>
							<input value={defaultExpiryTerm?.value} className={`${classes.inputField} ${classes.defaultTermInput}`} onChange={(e) => handleChangeDefaultExpiryValue(e)} type='number' min='0'/>
						</Box>
						<Box className={classes.inputValueTermType}>
							<Select
								classes={{ root: classes.inputListTermType }}
								value={defaultExpiryTerm?.type}
								onChange={(e) => handleChangeDefaultExpiryType(e)}
							>
								<MenuItem value={'day'}>日</MenuItem>
								<MenuItem value={'month'}>月</MenuItem>
							</Select>
						</Box>
					</Box>

					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label='call target table'>
							<TableHead>
								<TableRow>
									<TableCell align='left'>通話時間(分)</TableCell>
									<TableCell align='left'>期限(購入後)</TableCell>
									<TableCell align='left'>定価</TableCell>
									<TableCell align='left'>卸売価格</TableCell>
									<TableCell align='left'>表示ページ</TableCell>
									<TableCell align='right'>操作</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ priceList && priceList.length > 0 ?
									<>
										{	priceList.map((item, index) => (
											<TableRow key={index}>
												<TableCell align='left'>
													{ item.minutes === -1 ?
														<input
															value={'使い放題'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															disabled
															type='text'
														/>
														:
														<input
															value={item.minutes}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListItem('minutes', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>
													<input
														value={showExpiryTerm(defaultExpiryTerm)}
														className={`${classes.inputField} ${classes.priceListInput}`}
														disabled
														type='text'
													/>
												</TableCell>
												<TableCell align='left'>
													{ item.showPage === MANAGE_PAGE_ONLY ?
														<input
															value={'-'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															type='text'
															disabled
														/>
														:
														<input
															value={item.salesAmount}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListItem('salesAmount', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>
													{ (!selfTicketPurchase
													|| item.showPage === PURCHASE_PAGE_ONLY) ?
														<input
															value={'-'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															type='text'
															disabled
														/>
														:
														<input
															value={item.holesaleAmount}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListItem('holesaleAmount', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>

													<Select
														classes={{ root: classes.inputListShowPage }}
														value={item.showPage}
														onChange={(e) => handleChangePriceListItem('showPage', index, e)}
													>
														<MenuItem value={PURCHASE_PAGE_ONLY}>購入ページ</MenuItem>
														<MenuItem value={MANAGE_PAGE_ONLY}>管理ページ</MenuItem>
														<MenuItem value={PURCHASE_AND_MANAGE_PAGE}>購入・管理ページ</MenuItem>
													</Select>
												</TableCell>
												<TableCell align='right' className={classes.actionCell}>
													<Button
														variant='contained'
														color='primary'
														className={classes.btnRight}
														onClick={
															() => toggleInfiniteDurationPLItem(index)
														}
													>
														使い放題
													</Button>
													<Button
														variant='contained'
														color='secondary'
														onClick={
															() => deletePriceListItem(index)
														}
													>
														削除
													</Button>
												</TableCell>
											</TableRow>
										))}
									</>
									:
									<TableRow>
										<TableCell
											align='center'
											className={classes.noItemRow}
											colSpan={4}
										>
											アイテムがありません
										</TableCell>
									</TableRow>

								}
							</TableBody>
						</Table>
					</TableContainer>

				</Box>

				<Box className={`${classes.wrapper} ${classes.group}`}>
					<Box className={classes.groupTitleLine}>
						<Typography className={classes.pageTitle}>
							期間別価格リスト
						</Typography>
						<Box>
							<Box>
								<Button
									variant='contained'
									color='primary'
									onClick={() => addPriceListItemTerm('term')}
								>
									追加
								</Button>
							</Box>
						</Box>
					</Box>

					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label='call target table'>
							<TableHead>
								<TableRow>
									<TableCell align='left'>通話時間(分)</TableCell>
									<TableCell align='left'>期限日数(購入後)</TableCell>
									<TableCell align='left'>定価</TableCell>
									<TableCell align='left'>卸売価格</TableCell>
									<TableCell align='left'>表示ページ</TableCell>
									<TableCell align='right'>操作</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ priceListTerm && priceListTerm.length > 0 ?
									<>
										{	priceListTerm.map((item, index) => (
											<TableRow key={index}>
												<TableCell align='left'>
													{ item.minutes === -1 ?
														<input
															value={'使い放題'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															disabled
															type='text'
														/>
														:
														<input
															value={item.minutes}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListTermItem('minutes', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>
													<input
														value={item.expiryTerm?.value}
														min={1}
														className={`${classes.inputField} ${classes.priceListInput}`}
														onChange={
															(e) => handleChangePriceListTermExpiryTermValue(index, e)
														}
														type='number'
													/>
												</TableCell>
												<TableCell align='left'>
													{ item.showPage === MANAGE_PAGE_ONLY ?
														<input
															value={'-'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															type='text'
															disabled
														/>
														:
														<input
															value={item.salesAmount}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListTermItem('salesAmount', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>
													{ (!selfTicketPurchase
													|| item.showPage === PURCHASE_PAGE_ONLY) ?
														<input
															value={'-'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															type='text'
															disabled
														/>
														:
														<input
															value={item.holesaleAmount}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListTermItem('holesaleAmount', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>
													<Select
														classes={{ root: classes.inputListShowPage }}
														value={item.showPage}
														onChange={(e) => handleChangePriceListTermItem('showPage', index, e)}
													>
														<MenuItem value={PURCHASE_PAGE_ONLY}>購入ページ</MenuItem>
														<MenuItem value={MANAGE_PAGE_ONLY}>管理ページ</MenuItem>
														<MenuItem value={PURCHASE_AND_MANAGE_PAGE}>購入・管理ページ</MenuItem>
													</Select>
												</TableCell>
												<TableCell align='right' className={classes.actionCell}>
													<Button
														variant='contained'
														color='primary'
														className={classes.btnRight}
														onClick={
															() => toggleInfiniteDurationPLTermItem(index)
														}
													>
														使い放題
													</Button>

													<Button
														variant='contained'
														color='secondary'
														onClick={
															() => deletePriceListTermItem(index)
														}
													>
														削除
													</Button>
												</TableCell>
											</TableRow>
										))}
									</>
									:
									<TableRow>
										<TableCell
											align='center'
											className={classes.noItemRow}
											colSpan={4}
										>
											アイテムがありません
										</TableCell>
									</TableRow>

								}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>

				{ selfTicketPurchase &&
				<Box className={`${classes.wrapper} ${classes.group}`}>
					<Box className={classes.groupTitleLine}>
						<Typography className={classes.pageTitle}>
							コード入力後期限開始価格リスト
						</Typography>
						<Box>
							<Box>
								<Button
									variant='contained'
									color='primary'
									onClick={() => addPriceListItemExpireAfterUse('time')}
									className={classes.timeTicketButton}
								>
									追加
								</Button>
							</Box>
						</Box>
					</Box>

					<Box className={classes.defaultTermColumn}>
						<Box className={classes.defaultTermLabel}>
							<Typography className={classes.label}>購入後標準期限</Typography>
							<Typography className={classes.subLabelSmall}>
								0は無制限
							</Typography>
						</Box>
						<Box>
							<input value={defaultExpiryTermAfterUse?.value} className={`${classes.inputField} ${classes.defaultTermInput}`} onChange={(e) => handleChangeDefaultExpiryValueAfterUse(e)} type='number' min='0'/>
						</Box>
						<Box className={classes.inputValueTermType}>
							<Select
								classes={{ root: classes.inputListTermType }}
								value={defaultExpiryTermAfterUse?.type}
								onChange={(e) => handleChangeDefaultExpiryTypeAfterUse(e)}
							>
								<MenuItem value={'day'}>日</MenuItem>
								<MenuItem value={'month'}>月</MenuItem>
							</Select>
						</Box>
					</Box>

					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label='call target table'>
							<TableHead>
								<TableRow>
									<TableCell align='left'>通話時間(分)</TableCell>
									<TableCell align='left'>期限(使用後)</TableCell>
									<TableCell align='left'>定価</TableCell>
									<TableCell align='left'>卸売価格</TableCell>
									<TableCell align='left'>表示ページ</TableCell>
									<TableCell align='right'>操作</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ priceListExpireAfterUse && priceListExpireAfterUse.length > 0 ?
									<>
										{	priceListExpireAfterUse.map((item, index) => (
											<TableRow key={index}>
												<TableCell align='left'>
													{ item.minutes === -1 ?
														<input
															value={'使い放題'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															disabled
															type='text'
														/>
														:
														<input
															value={item.minutes}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListItemAfterUse('minutes', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>
													<Box className={classes.expiryInputCell}>
														<Box>
															<input value={item.expiryTermAfterUse?.value} className={`${classes.inputField} ${classes.itemTermInput}`} onChange={(e) => handleChangeExpiryTermAfterUseItem('value', index, e)} type='number' min='0'/>
														</Box>
														<Box className={classes.inputValueTermType}>
															<Select
																classes={{ root: classes.inputListTermType }}
																value={item.expiryTermAfterUse?.type}
																onChange={(e) => handleChangeExpiryTermAfterUseItem('type', index, e)}
															>
																<MenuItem value={'day'}>日</MenuItem>
																<MenuItem value={'month'}>月</MenuItem>
															</Select>
														</Box>
													</Box>

												</TableCell>
												<TableCell align='left'>
													{ item.showPage === MANAGE_PAGE_ONLY ?
														<input
															value={'-'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															type='text'
															disabled
														/>
														:
														<input
															value={item.salesAmount}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListItemAfterUse('salesAmount', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>
													{ (!selfTicketPurchase
													|| item.showPage === PURCHASE_PAGE_ONLY) ?
														<input
															value={'-'}
															className={`${classes.inputField} ${classes.priceListInput}`}
															type='text'
															disabled
														/>
														:
														<input
															value={item.holesaleAmount}
															min={0}
															className={`${classes.inputField} ${classes.priceListInput}`}
															onChange={(e) => handleChangePriceListItemAfterUse('holesaleAmount', index, e)}
															type='number'
														/>
													}
												</TableCell>
												<TableCell align='left'>

													<Select
														classes={{ root: classes.inputListShowPage }}
														value={item.showPage}
														onChange={(e) => handleChangePriceListItemAfterUse('showPage', index, e)}
													>
														<MenuItem value={PURCHASE_PAGE_ONLY}>購入ページ</MenuItem>
														<MenuItem value={MANAGE_PAGE_ONLY}>管理ページ</MenuItem>
														<MenuItem value={PURCHASE_AND_MANAGE_PAGE}>購入・管理ページ</MenuItem>
													</Select>
												</TableCell>
												<TableCell align='right' className={classes.actionCell}>
													<Button
														variant='contained'
														color='primary'
														className={classes.btnRight}
														onClick={
															() => toggleInfiniteDurationPLAfterUseItem(index)
														}
													>
														使い放題
													</Button>
													<Button
														variant='contained'
														color='secondary'
														onClick={
															() => deletePriceListItemAfterUse(index)
														}
													>
														削除
													</Button>
												</TableCell>
											</TableRow>
										))}
									</>
									:
									<TableRow>
										<TableCell
											align='center'
											className={classes.noItemRow}
											colSpan={4}
										>
											アイテムがありません
										</TableCell>
									</TableRow>

								}
							</TableBody>
						</Table>
					</TableContainer>

				</Box>
				}

				<Box className={classes.actionGroup}>
					<Button
						onClick={handleSave}
						className={classes.inputBtn}
						variant='contained'
						disabled={
							(!serviceAccount && !admin.rights?.createUser)
							|| (serviceAccount && !admin.rights?.editUser)
						}
					>
						保存
					</Button>
					<Button
						onClick={handleDelete}
						className={classes.inputBtn}
						variant='contained'
						disabled={!serviceAccount || !admin.rights?.deleteUser}
					>
						削除
					</Button>
					<Button onClick={handleCancel} className={classes.inputBtn} variant='contained'>
						キャンセル
					</Button>
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

AdminServiceAccountEditView.propTypes =
{
	classes               : PropTypes.object.isRequired,
	serviceAccount        : PropTypes.object,
	fetchServiceAccounts  : PropTypes.func,
	serviceAccounts       : PropTypes.array,
	admin                 : PropTypes.object,
	closeCb               : PropTypes.func.isRequired,
	errors                : PropTypes.object,
	setErrors             : PropTypes.func,
	setConfirmDialogState : PropTypes.func,
	setLoading            : PropTypes.func
};

export default withStyles(styles)(AdminServiceAccountEditView);
