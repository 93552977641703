const initialState =
{
	name                          : '',
	number                        : null,
	// new/connecting/connected/disconnected/closed,
	state                         : 'new',
	locked                        : false,
	inLobby                       : false,
	signInRequired                : false,
	overRoomLimit                 : false,
	// access code to the room if locked and joinByAccessCode == true
	accessCode                    : '',
	// if true: accessCode is a possibility to open the room
	joinByAccessCode              : true,
	activeSpeakerId               : null,
	torrentSupport                : false,
	showSettings                  : false,
	fullScreenConsumer            : null, // ConsumerID
	windowConsumer                : null, // ConsumerID
	watchShareScreen              : false, // host is watching share screen
	toolbarsVisible               : true,
	mode                          : window.config.defaultLayout || 'democratic',
	selectedPeers                 : [],
	spotlights                    : [],
	rolesManagerPeer              : null, // peerId
	settingsOpen                  : false,
	extraVideoOpen                : false,
	hideSelfView                  : false,
	rolesManagerOpen              : false,
	helpOpen                      : false,
	aboutOpen                     : false,
	currentSettingsTab            : 'media', // media, appearance, advanced
	lockDialogOpen                : false,
	callDialogOpen                : false,
	calledLobbyName               : '',
	calledOriginName              : '',
	supportCallDialogOpen         : false,
	joined                        : false,
	muteAllInProgress             : false,
	lobbyPeersPromotionInProgress : false,
	lobbyPeersLeft                : 0,
	stopAllVideoInProgress        : false,
	closeMeetingInProgress        : false,
	clearChatInProgress           : false,
	clearFileSharingInProgress    : false,
	roomPermissions               : null,
	userRoles                     : null,
	allowWhenRoleMissing          : null,
	accessWarningDialogOpen       : false,
	homeJoinDialogOpen            : false,
	inviteDialogOpen              : false,
	invitedRoomId                 : '',
	info                          : null,
	reciprocalContentDialogOpen   : false,
	groupChatHistory              : [],
	memoDialogOpen                : false,
	memo                          : null,
	memos                         : [],
	saveCurrentMemoState          : false,
	saveMemoAtStart               : false,
	saveMemoAtLeaving             : false,
	currentMemo                   : null,
	orientation                   : '',
	call                          : null,
	calls                         : [],
	callStartTime                 : 0,
	joinRoomNoticeDialogOpen      : false,
	joiningByDirectMode           : false,
	isLoading                     : false,
	makeCallEnd                   : false,
	numOfRecievedChats            : 0,
	numOfMonitoringChats          : 0,
	hideGuestBottomNavi           : false,
	hostName                      : '',
	monitoring                    : 0,
	monitoringStartAt             : 0,
	monitoringTimer               : 0,
	clientType                    : null,
	videoHostId                   : '',
	joinRequestDialogOpen         : false,
	joinRequestInfo               : null,
	joinRequestAccepted           : false,
	joinMeetingRequestAccepted    : false,
	joinAcceptDialogOpen          : false,
	joinAcceptInfo                : null,
	goBackToPrevRoom              : { goBack: false, roomId: '' },
	screenShareSize               : 1,
	memoChatDialogState           : { open: false },
	unReadMemoComments            : 0,
	invitingSubhost               : false,
	showHungUpView                : false,
	personalCallTargetName        : '',
	guestNotificationDialog       : { show: false, title: '', message: '' },
	serviceGuest                  : {}
};

const room = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_ROOM_NAME':
		{
			const { name } = action.payload;

			return { ...state, name };
		}

		case 'SET_ROOM_NUMBER':
		{
			const { number } = action.payload;

			return { ...state, number };
		}

		case 'SET_ROOM_STATE':
		{
			const roomState = action.payload.state;

			if (roomState === 'connected')
				return { ...state, state: roomState };
			else
				return { ...state, state: roomState, activeSpeakerId: null };
		}

		case 'SET_ROOM_LOCKED':
		{
			return { ...state, locked: true };
		}

		case 'SET_ROOM_UNLOCKED':
		{
			return { ...state, locked: false };
		}

		case 'SET_IN_LOBBY':
		{
			const { inLobby } = action.payload;

			return { ...state, inLobby };
		}

		case 'SET_SIGN_IN_REQUIRED':
		{
			const { signInRequired } = action.payload;

			return { ...state, signInRequired };
		}
		case 'SET_OVER_ROOM_LIMIT':
		{
			const { overRoomLimit } = action.payload;

			return { ...state, overRoomLimit };
		}
		case 'SET_ACCESS_CODE':
		{
			const { accessCode } = action.payload;

			return { ...state, accessCode };
		}

		case 'SET_JOIN_BY_ACCESS_CODE':
		{
			const { joinByAccessCode } = action.payload;

			return { ...state, joinByAccessCode };
		}

		case 'SET_LOCK_DIALOG_OPEN':
		{
			const { lockDialogOpen } = action.payload;

			return { ...state, lockDialogOpen };
		}

		case 'SET_ON_CALL_DIALOG_OPEN':
		{
			const { callDialogOpen } = action.payload;

			return { ...state, callDialogOpen };
		}

		case 'SET_CALLED_LOBBY_NAME' :
		{
			const { calledLobbyName } = action.payload;

			return { ...state, calledLobbyName };
		}

		case 'SET_CALLED_ORIGIN_NAME' :
		{
			const { calledOriginName } = action.payload;

			return { ...state, calledOriginName };
		}

		case 'SET_ON_SUPPORT_CALL_DIALOG_OPEN':
		{
			const { supportCallDialogOpen } = action.payload;

			return { ...state, supportCallDialogOpen };
		}

		case 'SET_ON_MEMO_DIALOG_OPEN':
		{
			const { memoDialogOpen } = action.payload;

			return { ...state, memoDialogOpen };
		}

		case 'SET_MEMO_SAVE_AT_START' :
		{
			const { flag } = action.payload;

			return { ...state, saveMemoAtStart: flag };
		}

		case 'SET_SAVE_MEMO_AT_LEAVING':
		{
			const { flag } = action.payload;

			return { ...state, saveMemoAtLeaving: flag };
		}

		case 'SET_SETTINGS_OPEN':
		{
			const { settingsOpen } = action.payload;

			return { ...state, settingsOpen };
		}

		case 'SET_EXTRA_VIDEO_OPEN':
		{
			const { extraVideoOpen } = action.payload;

			return { ...state, extraVideoOpen };
		}

		case 'SET_ROLES_MANAGER_PEER':
		{
			const { rolesManagerPeer } = action.payload;

			return { ...state, rolesManagerPeer };
		}

		case 'SET_ROLES_MANAGER_OPEN':
		{
			const { rolesManagerOpen } = action.payload;

			return { ...state, rolesManagerOpen };
		}

		case 'SET_HELP_OPEN':
		{
			const { helpOpen } = action.payload;

			return { ...state, helpOpen };
		}

		case 'SET_ABOUT_OPEN':
		{
			const { aboutOpen } = action.payload;

			return { ...state, aboutOpen };
		}

		case 'SET_SETTINGS_TAB':
		{
			const { tab } = action.payload;

			return { ...state, currentSettingsTab: tab };
		}

		case 'SET_ROOM_ACTIVE_SPEAKER':
		{
			const { peerId } = action.payload;

			return { ...state, activeSpeakerId: peerId };
		}

		case 'FILE_SHARING_SUPPORTED':
		{
			const { supported } = action.payload;

			return { ...state, torrentSupport: supported };
		}

		case 'TOGGLE_JOINED':
		{
			const joined = true;

			return { ...state, joined };
		}

		case 'TOGGLE_FULLSCREEN_CONSUMER':
		{
			const { consumerId } = action.payload;
			const currentConsumer = state.fullScreenConsumer;

			return { ...state, fullScreenConsumer: currentConsumer ? null : consumerId };
		}

		case 'SET_WATCH_SHARE_SCREEN' :
		{
			const { flag } = action.payload;

			return { ...state, watchShareScreen: flag };
		}

		case 'TOGGLE_WINDOW_CONSUMER':
		{
			const { consumerId } = action.payload;
			const currentConsumer = state.windowConsumer;

			if (currentConsumer === consumerId)
				return { ...state, windowConsumer: null };
			else
				return { ...state, windowConsumer: consumerId };
		}

		case 'SET_TOOLBARS_VISIBLE':
		{
			const { toolbarsVisible } = action.payload;

			return { ...state, toolbarsVisible };
		}

		case 'SET_DISPLAY_MODE':
			return { ...state, mode: action.payload.mode };

		case 'ADD_SELECTED_PEER':
		{
			const { peerId } = action.payload;

			const selectedPeers = [ ...state.selectedPeers, peerId ];

			return { ...state, selectedPeers };
		}

		// Also listen for peers leaving
		case 'REMOVE_PEER':
		case 'REMOVE_SELECTED_PEER':
		{
			const { peerId } = action.payload;

			const selectedPeers =
				state.selectedPeers.filter((peer) =>
					peer !== peerId);

			return { ...state, selectedPeers };
		}

		case 'CLEAR_SELECTED_PEERS':
		{
			return { ...state, selectedPeers: [] };
		}

		case 'SET_SPOTLIGHTS':
		{
			const { spotlights } = action.payload;

			return { ...state, spotlights };
		}

		case 'CLEAR_SPOTLIGHTS':
		{
			return { ...state, spotlights: [] };
		}

		case 'SET_LOBBY_PEERS_PROMOTION_IN_PROGRESS':
			return { ...state, lobbyPeersPromotionInProgress: action.payload.flag };

		case 'INCREMENT_LOBBY_PEERS_LEFT':
		{
			return { ...state, lobbyPeersLeft: state.lobbyPeersLeft + 1 };
		}

		case 'MUTE_ALL_IN_PROGRESS':
			return { ...state, muteAllInProgress: action.payload.flag };

		case 'STOP_ALL_VIDEO_IN_PROGRESS':
			return { ...state, stopAllVideoInProgress: action.payload.flag };

		case 'STOP_ALL_SCREEN_SHARING_IN_PROGRESS':
			return { ...state, stopAllScreenSharingInProgress: action.payload.flag };

		case 'CLOSE_MEETING_IN_PROGRESS':
			return { ...state, closeMeetingInProgress: action.payload.flag };

		case 'CLEAR_CHAT_IN_PROGRESS':
			return { ...state, clearChatInProgress: action.payload.flag };

		case 'CLEAR_FILE_SHARING_IN_PROGRESS':
			return { ...state, clearFileSharingInProgress: action.payload.flag };

		case 'SET_ROOM_PERMISSIONS':
		{
			const { roomPermissions } = action.payload;

			return { ...state, roomPermissions };
		}

		case 'SET_USER_ROLES':
		{
			const { userRoles } = action.payload;

			return { ...state, userRoles };
		}

		case 'SET_ALLOW_WHEN_ROLE_MISSING':
		{
			const { allowWhenRoleMissing } = action.payload;

			return { ...state, allowWhenRoleMissing };
		}

		case 'SET_HIDE_SELF_VIEW':
		{
			const { hideSelfView } = action.payload;

			return { ...state, hideSelfView };
		}

		case 'SET_ACCESS_WARNING_DIALOG_OPEN':
		{
			const { accessWarningDialogOpen } = action.payload;

			return { ...state, accessWarningDialogOpen };
		}

		case 'SET_HOME_JOIN_DIALOG_OPEN':
		{
			const { homeJoinDialogOpen } = action.payload;

			return { ...state, homeJoinDialogOpen };
		}

		case 'SET_INVITE_DIALOG_OPEN':
		{
			const { inviteDialogOpen, invitedRoomId } = action.payload;

			return { ...state, inviteDialogOpen, invitedRoomId };
		}

		case 'SET_ROOM_INFO':
		{
			const { info } = action.payload;

			return { ...state, info };
		}

		case 'SET_ROOM_CALL':
		{
			const { call } = action.payload;

			return { ...state, call };
		}

		case 'SET_RECIPROCAL_CONTENT_DIALOG_OPEN':
		{
			const { reciprocalContentDialogOpen } = action.payload;

			return { ...state, reciprocalContentDialogOpen };
		}

		case 'SET_GROUP_CHAT':
		{
			const { groupChatHistory } = action.payload;

			return { ...state, groupChatHistory };
		}

		case 'SET_MEMOS':
		{
			const { memos } = action.payload;

			return { ...state, memos };
		}

		case 'SET_MEMO':
		{
			const { memo } = action.payload;

			return { ...state, memo };
		}

		case 'UPDATE_MEMO':
		{
			const { newMemo } = action.payload;

			return {
				...state,
				memo : {
					...state.memo,
					...newMemo
				} };
		}

		case 'SET_CURRENT_MEMO':
		{
			const { currentMemo } = action.payload;

			return { ...state, currentMemo };
		}

		case 'SAVE_CURRENT_MEMO_STATE':
		{
			const { flag } = action.payload;

			return { ...state, saveCurrentMemoState: flag };
		}

		case 'SET_CALLS':
		{
			const { calls } = action.payload;

			return { ...state, calls };
		}

		case 'SET_ORIENTATION':
		{
			const { orientation } = action.payload;

			return { ...state, orientation };
		}

		case 'SET_NOTICE_JOIN_ROOM_DIALOG_OPEN':
		{
			const { joinRoomNoticeDialogOpen } = action.payload;

			return { ...state, joinRoomNoticeDialogOpen };
		}

		case 'SET_JOINING_BY_DIRECT_MODE':
		{
			const { joiningByDirectMode } = action.payload;

			return { ...state, joiningByDirectMode };
		}

		case 'SET_IS_LOADING':
		{
			const { isLoading } = action.payload;

			return { ...state, isLoading };
		}

		case 'SET_CALL_START_TIME':
		{
			const { callStartTime } = action.payload;

			return { ...state, callStartTime };
		}

		case 'SET_MAKE_CALL_END':
		{
			const bool = action.payload;

			return { ...state, makeCallEnd: bool };
		}

		case 'INCREMENT_RECIEVED_CHATS' :
		{
			return { ...state, numOfRecievedChats: state.numOfRecievedChats + 1 };
		}

		case 'RESET_NUM_OF_RECIEVED_CHATS' :
		{
			return { ...state, numOfRecievedChats: 0 };
		}

		case 'INCREMENT_MONITORING_CHATS' :
		{
			return { ...state, numOfMonitoringChats: state.numOfMonitoringChats + 1 };
		}

		case 'RESET_NUM_OF_MONITORING_CHATS' :
		{
			return { ...state, numOfMonitoringChats: 0 };
		}

		case 'SET_HIDE_GUEST_BOTTOM_NAVI' :
		{
			const { hideGuestBottomNavi } = action.payload;

			return { ...state, hideGuestBottomNavi };
		}

		case 'RESET_ROOM' :
		{
			return { ...initialState };
		}

		case 'RESET_ROOM_AT_LEAVING_ROOM' :
		{
			return {
				...initialState,
				memos              : [ ...state.memos ],
				calls              : [ ...state.calls ],
				groupChatHistory   : [ ...state.groupChatHistory ],
				unReadMemoComments : state.unReadMemoComments
			};
		}

		case 'SET_HOST_NAME' :
		{
			const val = action.payload;

			return { ...state, hostName: val };
		}

		case 'SET_MONITORING' :
		{
			const val = action.payload;

			return { ...state, monitoring: val };
		}

		case 'SET_MONITORING_START_AT' :
		{
			const val = action.payload;

			return { ...state, monitoringStartAt: val };
		}

		case 'SET_MONITORING_TIMER' :
		{
			const val = action.payload;

			return { ...state, monitoringTimer: val };
		}

		case 'INCREMENT_MONITORING_TIMER' :
		{
			return {
				...state,
				monitoringTimer : state.monitoringTimer + 1
			};
		}

		case 'SET_CLIENT_TYPE' :
		{
			const val = action.payload;

			return {
				...state,
				clientType : val
			};
		}

		case 'SET_VIDEO_HOST_ID' :
		{
			const { videoHostId } = action.payload;

			return {
				...state,
				videoHostId : videoHostId
			};
		}

		case 'SET_JOIN_REQUEST_DIALOG_OPEN' :
		{
			const { flag } = action.payload;

			return { ...state, joinRequestDialogOpen: flag };
		}

		case 'SET_JOIN_REQUEST_INFO' :
		{
			const { info } = action.payload;

			return { ...state, joinRequestInfo: info };
		}

		case 'SET_JOIN_REQUEST_ACCEPTED' :
		{
			const { flag } = action.payload;

			return { ...state, joinRequestAccepted: flag };
		}

		case 'SET_JOIN_MEETING_REQUEST_ACCEPTED' :
		{
			const { flag } = action.payload;

			return { ...state, joinMeetingRequestAccepted: flag };
		}

		case 'SET_JOIN_ACCEPT_DIALOG_OPEN' :
		{
			const { flag } = action.payload;

			return { ...state, joinAcceptDialogOpen: flag };
		}

		case 'SET_JOIN_ACCEPT_INFO' :
		{
			const { info } = action.payload;

			return { ...state, joinAcceptInfo: info };
		}

		case 'SET_GO_BACK_TO_PREV_ROOM' :
		{
			const { obj } = action.payload;

			return { ...state, goBackToPrevRoom: { ...obj } };
		}

		case 'SET_SCREEN_SHARE_SIZE' :
		{
			const val = action.payload;

			return { ...state, screenShareSize: val };
		}

		case 'SET_MEMO_CHAT_DIALOG_STATE' :
		{
			const obj = action.payload;

			return { ...state, memoChatDialogState: { ...obj } };
		}

		case 'SET_UNREAD_MEMO_COMMENTS' :
		{
			const num = action.payload;

			return { ...state, unReadMemoComments: num };
		}

		case 'SET_INVITING_SUBHOST' :
		{
			const { flag } = action.payload;

			return { ...state, invitingSubhost: flag };
		}

		case 'SET_SHOW_HUNG_UP_VIEW' :
		{
			const { flag } = action.payload;

			return { ...state, showHungUpView: flag };
		}

		case 'SET_PERSINAL_CALL_TARGET_NAME':
		{
			const { personalCallTargetName } = action.payload;

			return { ...state, personalCallTargetName: personalCallTargetName };
		}

		case 'SET_GUEST_NOTIFICATION_DIALOG' :
		{
			const { guestNotificationDialogState } = action.payload;

			return { ...state, guestNotificationDialog: guestNotificationDialogState };
		}

		case 'CLOSE_GUEST_NOTIFICATION_DIALOG' :
		{
			return { ...state, guestNotificationDialog: { show: false, title: '', message: '' } };
		}

		case 'SET_SERVICE_GUEST' :
		{
			const { serviceGuest } = action.payload;

			return { ...state, serviceGuest: serviceGuest };

		}

		default:
			return state;
	}
};

export default room;
