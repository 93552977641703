import
{
	createNewMonitoringMessage,
	createNewFileShareMessage,
	createNewMonitoringIconsMessage
} from './helper';

const internalChat = (state = [], action) =>
{
	switch (action.type)
	{
		case 'ADD_MONITOR_MESSAGE':
		{
			const { type, text, time } = action.payload;

			const message = createNewMonitoringMessage(type, text, time, 'client', 'Me', undefined);

			return [ ...state, message ];
		}

		case 'ADD_MONITOR_ICONS_MESSAGE':
		{
			const { type, icons, time } = action.payload;

			const message = createNewMonitoringIconsMessage(type, icons, time, 'client', 'Me', undefined);

			return [ ...state, message ];
		}

		case 'ADD_MONITOR_RESPONSE_MESSAGE':
		{
			const { message } = action.payload;

			return [ ...state, message ];
		}

		case 'UPLOADED_MONITOR_SHARE_FILE':
		{
			const { fileInfo } = action.payload;

			const fileInfoNotification = createNewFileShareMessage('client', 'Me', undefined, fileInfo.fileName, fileInfo.fileShareUrl, fileInfo.time, fileInfo.error);

			return [ ...state, fileInfoNotification ];
		}

		case 'RECIEVE_NEW_MONITOR_SHARE_FILE':
		{
			const { fileInfo } = action.payload;

			return [ ...state, fileInfo ];
		}

		case 'ADD_MONITOR_CHAT_HISTORY':
		{
			const { chatHistory } = action.payload;

			return [ ...state, ...chatHistory ];
		}

		case 'CLEAR_MONITOR_CHAT':
		{
			return [];
		}
		default:
			return state;
	}
};

export default internalChat;
