export const addLobbyPeer = (peerId) =>
	({
		type    : 'ADD_LOBBY_PEER',
		payload : { peerId }
	});

export const removeLobbyPeer = (peerId) =>
	({
		type    : 'REMOVE_LOBBY_PEER',
		payload : { peerId }
	});

export const setLobbyPeerDisplayName = (displayName, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_DISPLAY_NAME',
		payload : { displayName, peerId }
	});

export const setLobbyPeerPicture = (picture, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_PICTURE',
		payload : { picture, peerId }
	});

export const setLobbyPeerOS = (os, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_OS',
		payload : { os, peerId }
	});

export const setLobbyPeerBrowser = (browser, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_BROWSER',
		payload : { browser, peerId }
	});

export const setLobbyPeerPortalId = (portalId, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_PORTAL_ID',
		payload : { portalId, peerId }
	});

export const setLobbyPeerServiceParamKey = (serviceParamKey, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_SERVICE_PARAM_KEY',
		payload : { serviceParamKey, peerId }
	});

export const setLobbyPeerServiceBrokerParamKey = (serviceBrokerParamKey, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_SERVICE_BROKER_PARAM_KEY',
		payload : { serviceBrokerParamKey, peerId }
	});

export const setLobbyPeerGuestInputs = (guestInputs, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_GUEST_INPUTS',
		payload : { guestInputs, peerId }
	});

export const setLobbyPeerKokyakuId = (kokyakuId, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_KOKYAKU_ID',
		payload : { kokyakuId, peerId }
	});

export const setLobbyPeerPersonalCall = (personalCall, peerId) =>
	({
		type    : 'SET_LOBBY_PEER_PERSONAL_CALL',
		payload : { personalCall, peerId }
	});

export const setLobbyPeerPromotionInProgress = (peerId, flag) =>
	({
		type    : 'SET_LOBBY_PEER_PROMOTION_IN_PROGRESS',
		payload : { peerId, flag }
	});

export const clearLobbyPeers = () =>
	({
		type : 'CLEAR_LOBBY_PEERS'
	});