import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// material ui
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// firestore
import { firestore } from '../../../../lib/firebase';

const styles = () =>
	({
		root :
		{
			zIndex : 99,
			width  : '100%',
			height : '100%'
		},
		dialogPaper :
		{
			padding         : '1% 1%',
			width           : 550,
			minHeight       : 550,
			backgroundColor : '#666666'
		},
		dialogPaperCreate :
		{
			padding         : '1% 1%',
			width           : 550,
			minHeight       : 350,
			backgroundColor : '#666666'
		},
		title :
		{
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		titleText :
		{
			color    : 'white',
			fontSize : '1.1rem'
		},
		dialogContent :
		{
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			padding        : '8px 8px'
		},
		inputSection :
		{
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center',
			flexGrow       : '1'
		},
		inputMessage : {
			marginBottom : '10px'
		},
		addButtonSection :
		{
			position : 'absolute',
			right    : '40px',
			top      : '24px'
		},
		addBtn :
		{
			border          : '1px solid #8eba14',
			backgroundColor : '#8eba14',
			color           : 'white',
			padding         : '4px 12px',
			'&:hover'       : {
				backgroundColor : '#8eba14',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '100%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#F6F6F6'
			}
		},
		deleteBtn :
		{
			color      : 'white',
			background : '#ee0000',
			border     : '1px solid #ee0000',
			padding    : '10px 18px',
			'&:hover'  : {
				background : '#ee0000',
				filter     : 'saturate(105%) brightness(110%)'
			}
		},
		createBtn :
		{
			width           : '30%',
			border          : '1px solid #8eba14',
			backgroundColor : '#8eba14',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#8eba14',
				filter          : 'saturate(105%) brightness(105%)'
			}
		},
		cancelBtn :
		{
			width           : '30%',
			border          : '1px solid #fff',
			backgroundColor : 'transparent',
			color           : 'white'
		},
		apiList : {
			width         : '100%',
			height        : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		topData : {
			width          : '100%',
			height         : '1.75rem',
			display        : 'flex',
			margin         : '1% 0',
			justifyContent : 'space-between',
			lineHeight     : 1
		},
		inputLabel : {
			width         : '30%',
			fontSize      : '0.9rem',
			paddingTop    : '10px',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		inputValue : {
			fontSize : '0.9rem',
			width    : '70%'
		},
		label : {
			color        : 'white',
			fontSize     : '0.9rem',
			overflowWrap : 'break-word'
		},
		textCol : {
			width          : '40%',
			fontSize       : '0.8rem',
			paddingTop     : '10px',
			display        : 'flex',
			justifyContent : 'flex-start'
		},
		buttonCol : {
			width          : '20%',
			paddingTop     : '10px',
			display        : 'flex',
			justifyContent : 'center'
		},
		typoCenter : {
			width     : '100%',
			textAlign : 'center'
		},
		noItemLine : {
			position : 'absolute',
			top      : '35%'
		},
		errorMsg : {
			color     : 'red',
			width     : '100%',
			height    : '1.2rem',
			margin    : '0 5px',
			fontSize  : '1rem',
			textAlign : 'center'
		},
		loadingIndicator : {
			width          : '100%',
			height         : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		circularProgress : {
			color : 'white'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiBackdrop : {
			root : {
				backgroundColor : 'transparent'
			}
		},
		MuiDialogTitle : {
			root : {
				backgroundColor : 'transparent',
				color           : '#9ec317',
				width           : '100%'
			}
		},
		MuiDialogContent : {
			root : {
				color          : 'white',
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center'
			}
		},
		MuiDialogActions : {
			root : {
				justifyContent : 'space-around'
			}
		},
		MuiTypography : {
			colorTextSecondary : {
				color : 'white'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0.6rem'
			}
		}
	}
});

const PortalIpAddressDialog = ({
	classes,
	show,
	closeMethod,
	setConfirmDialogState,
	portalId,
	ipAddresses,
	setIpAddresses
}) =>
{
	const [ loading, setLoading ] = useState(false);
	const [ page, setPage ] = useState('list');

	const [ newIpAddress, setNewIpAddress ] = useState('');
	const [ errorMsg, setErrorMsg ] = useState(null);

	const handleChangeNewIpAddress = (e) =>
	{
		setNewIpAddress(e.target.value);
	};

	const deleteIpAddress = ({ targetIpAddress }) =>
	{
		setErrorMsg(null);
		setLoading(true);

		setConfirmDialogState({
			show            : false,
			title           : '',
			message         : '',
			acceptMethod    : () => {},
			acceptMethodArg : {},
			closeButtonOnly : false
		});

		const newIpAddresses = ipAddresses.filter((ip) => ip !== targetIpAddress);

		firestore.collection('portals').doc(portalId)
			.update({
				ipAddresses : [ ...newIpAddresses ]
			})
			.then(() =>
			{
				setIpAddresses([ ...newIpAddresses ]);
				setLoading(false);
			})
			.catch(() =>
			{
				setErrorMsg('IPアドレスの削除に失敗しました。');
				setLoading(false);
			});
	};

	const deletePortalIpAddressHandler = (ipAddress) =>
	{
		let deleteMessage = 'ポータル認証IDを削除しますか?';

		if (ipAddress)
		{
			deleteMessage = `IPアドレス"${ipAddress}"を削除しますか?`;
		}

		setConfirmDialogState({
			show            : true,
			title           : 'IPアドレスの削除',
			message         : deleteMessage,
			acceptMethod    : deleteIpAddress,
			acceptMethodArg : {
				targetIpAddress : ipAddress
			},
			closeButtonOnly : false
		});
	};

	const createIpAddress = () =>
	{
		setErrorMsg(null);

		setLoading(true);

		const ipIddressReg = new RegExp(/^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])$/);

		if (!ipIddressReg.test(newIpAddress))
		{
			setErrorMsg('IPアドレスが正しくありません');
			setLoading(false);
		}
		else
		{
			const newIpAddresses = [ ...ipAddresses, newIpAddress ];

			firestore.collection('portals').doc(portalId)
				.update({
					ipAddresses          : [ ...newIpAddresses ],
					ipAddressRestriction : true
				})
				.then(() =>
				{
					setIpAddresses([ ...newIpAddresses ]);
					setPage('list');
					setLoading(false);
				})
				.catch(() =>
				{
					setErrorMsg('IPアドレスの作成に失敗しました。');
					setLoading(false);
				});
		}
	};

	const backMethod = () =>
	{
		setErrorMsg('');
		setNewIpAddress('');
		setPage('list');
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				className={classes.root}
				open={show}
				onClose={() => {}}
				classes={{
					paper : page === 'new' ? classes.dialogPaperCreate : classes.dialogPaper
				}}
			>
				{
					loading ?
						<DialogContent>
							<Box className={classes.loadingIndicator}>
								<CircularProgress className={classes.circularProgress}/>
							</Box>
						</DialogContent>
						: page === 'new' ?
							<>
								<DialogTitle id='form-dialog-title'>
									<div className={classes.title}>
										<div className={classes.titleText}>
											利用可能IPアドレス追加
										</div>
									</div>
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									{ errorMsg &&
									<Box className={classes.topData}>
										<Typography className={classes.errorMsg}>
											{errorMsg}
										</Typography>
									</Box>
									}

									<Box className={classes.inputSection}>
										<Typography className={classes.inputMessage}>
											利用を許可するIPアドレスを追加
										</Typography>
										<Box className={`${classes.topData}`}>
											<Box className={classes.inputLabel}>
												<Typography className={classes.label}>ipアドレス</Typography>
											</Box>
											<Box className={classes.inputValue}>
												<input
													value={newIpAddress}
													className={`${classes.inputField}`}
													onChange={handleChangeNewIpAddress}
													type='text'
												/>
											</Box>
										</Box>
									</Box>
								</DialogContent>
								<DialogActions>
									<Button
										className={classes.cancelBtn}
										onClick={backMethod}
										color='primary'
									>
										<FormattedMessage
											id='label.back'
											defaultMessage='Back'
										/>
									</Button>
									<Button
										className={classes.createBtn}
										onClick={createIpAddress}
										color='primary'
									>
										<FormattedMessage
											id='label.create'
											defaultMessage='Create'
										/>
									</Button>
								</DialogActions>
							</>
							: // list
							<>
								<DialogTitle id='form-dialog-title'>
									<Box className={classes.title}>
										<Box className={classes.titleText}>
											利用可能IPアドレス
										</Box>
									</Box>
									<Box className={classes.addButtonSection}>
										<Button
											className={classes.addBtn}
											onClick={() => setPage('new')}
											color='primary'
										>
											追加
										</Button>
									</Box>
								</DialogTitle>
								<DialogContent className={classes.dialogContent}>
									{ errorMsg &&
									<Box className={classes.topData}>
										<Typography className={classes.errorMsg}>
											{errorMsg}
										</Typography>
									</Box>
									}

									{ !ipAddresses || ipAddresses.length === 0 ?
										<Box className={classes.noItemLine}>
											<Typography className={classes.typoCenter}>
												登録されたIPアドレスはありません
											</Typography>
										</Box>
										:
										<Box className={classes.apiList}>
											<Box className={`${classes.topData}`}>
												<Box className={classes.textCol}>
													<Typography className={classes.label}>
														IPアドレス
													</Typography>
												</Box>
												<Box className={classes.buttonCol}>
													<Typography className={classes.label}>
														操作
													</Typography>
												</Box>
											</Box>

											{ ipAddresses.map((ipAddress) => (
												<Box className={`${classes.topData}`} key={ipAddress}>
													<Box className={classes.textCol}>
														<Typography className={classes.label}>
															{ipAddress}
														</Typography>
													</Box>
													<Box className={classes.buttonCol}>
														<Button
															className={classes.deleteBtn}
															onClick={() =>
																deletePortalIpAddressHandler(
																	ipAddress
																)}
															color='secondary'
														>
															削除
														</Button>
													</Box>
												</Box>
											))}
										</Box>
									}
								</DialogContent>
								<DialogActions>
									<Button
										className={classes.cancelBtn}
										onClick={closeMethod}
										color='primary'
									>
										<FormattedMessage
											id='label.close'
											defaultMessage='Close'
										/>
									</Button>
								</DialogActions>
							</>
				}
			</Dialog>
		</MuiThemeProvider>
	);
};

PortalIpAddressDialog.propTypes =
{
	classes               : PropTypes.object.isRequired,
	show                  : PropTypes.bool.isRequired,
	closeMethod           : PropTypes.func.isRequired,
	setConfirmDialogState : PropTypes.func.isRequired,
	portalId              : PropTypes.string.isRequired,
	ipAddresses           : PropTypes.array.isRequired,
	setIpAddresses        : PropTypes.func.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return ({
			});
		}
	}
)(withStyles(styles)(PortalIpAddressDialog)));