// React
import React from 'react';
import { connect } from 'react-redux';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { withRoomContext } from '../../../../RoomContext';

// Daysjs
import dayjs from 'dayjs';
// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = (theme) =>
	({
		root : {
			width  : '100%',
			height : 'calc(100% - 7.5rem)'
		},
		paper : {
			width                       : '100%',
			height                      : '100%',
			borderRadius                : '0',
			background                  : 'transparent',
			marginBottom                : theme.spacing(2),
			color                       : 'var(--text-color)',
			'& .MuiTableContainer-root' :
			{
				height : 'calc(100% - 52px)'
			}
		},
		tableContainer : {
			background   : 'white',
			borderRadius : '0.5rem',
			paddingTop   : '1rem',
			fontWeight   : '300',
			color        : 'var(--text-color)'
		},
		table : {
			minWidth                                : 750,
			'& .MuiTableCell-root:not(:last-child)' : {
				borderBottom : 'none',
				borderRight  : '1px solid var(--text-color)',
				fontWeight   : '300',
				fontFamily   : '"M PLUS Rounded 1c", sans-serif',
				color        : 'var(--text-color)'
			},
			'& .MuiTableCell-root:last-child' : {
				borderBottom : 'none',
				fontWeight   : '300',
				fontFamily   : '"M PLUS Rounded 1c", sans-serif',
				color        : 'var(--text-color)'
			}
		},
		visuallyHidden : {
			border   : 0,
			clip     : 'rect(0 0 0 0)',
			height   : 1,
			margin   : -1,
			overflow : 'hidden',
			padding  : 0,
			position : 'absolute',
			top      : 20,
			width    : 1
		},
		tableRow : {
			'&:nth-of-type(odd)' : {
				backgroundColor : theme.palette.action.hover
			}
		},
		noAccount : {
			color     : 'var(--text-color)',
			fontSize  : '1.4rem',
			width     : '100%',
			textAlign : 'center',
			marginTop : '15%'
		}
	});

const headCells = [
	{ id: 'select', numeric: false, disablePadding: false, isSort: false, label: '選択' },
	{ id: 'id', numeric: false, disablePadding: false, isSort: true, label: 'ID' },
	{ id: 'portalName', numeric: false, disablePadding: false, isSort: true, label: '名称' },
	{ id: 'action', numeric: false, disablePadding: false, isSort: false, label: '操作' }
];

function descendingComparator(a, b, orderBy)
{
	if (b[orderBy] < a[orderBy])
	{
		return -1;
	}
	if (b[orderBy] > a[orderBy])
	{
		return 1;
	}

	return 0;
}

function getComparator(order, orderBy)
{
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, rowsPerPage)
{
	const stabilizedThis = array.map((el, index) => [ el, index ]);

	stabilizedThis.sort((a, b) =>
	{
		const order = comparator(a[0], b[0]);

		if (order !== 0) return order;

		return a[1] - b[1];
	});

	const duplicateRow = [ ...Array(rowsPerPage) ]
		.map(() => { return { hideAction: true }; });

	return [
		...stabilizedThis.map((el) => el[0]),
		...duplicateRow
	];
}

const ServiceAccountsTable = ({
	classes,
	serviceAccounts,
	selected,
	setSelected,
	page,
	setPage,
	showServiceAccountEditView,
	isLoading
}) =>
{
	const [ order, setOrder ] = React.useState('asc');
	const [ orderBy, setOrderBy ] = React.useState('calories');
	const [ rowsPerPage, setRowsPerPage ] = React.useState(5);

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const handleRequestSort = (event, property) =>
	{
		const isAsc = orderBy === property && order === 'asc';

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler = (property, isSort) => (event) =>
	{
		if (!isSort) return;
		handleRequestSort(event, property);
	};

	const handleChangePage = (event, newPage) =>
	{
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) =>
	{
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClick = (event, paramKey) =>
	{
		const selectedIndex = selected.indexOf(paramKey);

		let newSelected = [];

		if (selectedIndex === -1)
		{
			newSelected = newSelected.concat(selected, paramKey);
		}
		else if (selectedIndex === 0)
		{
			newSelected = newSelected.concat(selected.slice(1));
		}
		else if (selectedIndex === selected.length - 1)
		{
			newSelected = newSelected.concat(selected.slice(0, -1));
		}
		else if (selectedIndex > 0)
		{
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	return (
		<Box className={classes.root}>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer className={classes.tableContainer}>
					{ serviceAccounts?.length > 0 ?
						<Table
							className={classes.table}
							aria-labelledby='tableTitle'
							size={'medium'}
							aria-label='enhanced table'
						>
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											align={'center'}
											padding={headCell.disablePadding ? 'none' : 'normal'}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											{headCell.isSort &&
											<TableSortLabel disabled/>
											}
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : 'asc'}
												hideSortIcon={!headCell.isSort}
												onClick={createSortHandler(headCell.id, headCell.isSort)}
											>
												{headCell.label}
												{orderBy === headCell.id ? (
													<span className={classes.visuallyHidden}>
														{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
													</span>
												) : null}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{stableSort(serviceAccounts, getComparator(order, orderBy), rowsPerPage)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) =>
									{
										const isItemSelected = isSelected(row.paramKey);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<React.Fragment key={index}>
												{ row.paramKey &&
													<TableRow
														hover
														onClick={(event) => handleClick(event, row.paramKey)}
														role='checkbox'
														tabIndex={-1}
														className={classes.tableRow}
														aria-checked={isItemSelected}
														selected={isItemSelected}
													>
														<TableCell align='center'>
															<Checkbox
																checked={isItemSelected}
																inputProps={{ 'aria-labelledby': labelId }}
															/>
														</TableCell>
														<TableCell align='center' component='th' id={labelId} scope='row'>
															{row.paramKey}
														</TableCell>
														<TableCell align='center'>{row.accountName}</TableCell>
														<TableCell align='center'>
															{ !row.hideAction &&
															<Button
																onClick={() =>
																	showServiceAccountEditView(row.id)
																}
																className={classes.addUserBtn}
																variant='contained'
																color='primary'
															>
																編集
															</Button>
															}
														</TableCell>
													</TableRow>
												}
											</React.Fragment>
										);
									})}
							</TableBody>
						</Table>
						: !isLoading ?
							<p className={classes.noAccount}>
								表示するページがありません
							</p>
							: null
					}
				</TableContainer>

				<TablePagination
					rowsPerPageOptions={[ 5, 10, 25 ]}
					component='div'
					count={serviceAccounts.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
};

ServiceAccountsTable.propTypes =
{
	classes                    : PropTypes.object.isRequired,
	serviceAccounts            : PropTypes.array.isRequired,
	selected                   : PropTypes.array.isRequired,
	setSelected                : PropTypes.func.isRequired,
	page                       : PropTypes.number,
	setPage                    : PropTypes.func,
	showServiceAccountEditView : PropTypes.func,
	isLoading                  : PropTypes.bool
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return;
		}
	}
)(withStyles(styles)(ServiceAccountsTable)));