// React
import React, { useEffect, useState } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';

// Message

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Daysjs
import dayjs from 'dayjs';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = (theme) =>
	({
		root : {
			display : 'flex',
			width   : '100%',
			height  : '100%',
			color   : 'var(--text-color)'
		},
		dialogRoot : {
			// pointerEvents : 'none'
		},
		dialogPaper : {
			padding                        : '1% 2%',
			width                          : '55vw',
			pointerEvents                  : 'auto',
			[theme.breakpoints.down('lg')] : {
				width : '55vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '65vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '80vw'
			},
			[theme.breakpoints.down('xs')] : {
				width  : '95vw',
				margin : '15px'
			}
		},
		inputGroup : {
			width         : '100%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'center'
		},
		icon : {
			width       : 'auto',
			height      : '1.5rem',
			marginRight : '0.5rem'
		},
		header : {
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'center',
			marginBottom   : '1rem',
			color          : 'var(--text-color)'
		},
		summery : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'flex-start',
			marginBottom   : '0.5rem',
			color          : 'var(--text-color)',
			flexWrap       : 'wrap'
		},
		summeryItemWrapper : {
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		summeryItem : {
			display        : 'flex',
			justifyContent : 'center',
			alignItems     : 'flex-start',
			margin         : '0 5px'
		},
		summeryLabelSection : {
			width : '6.5rem'
		},
		summeryLabel : {
			fontSize   : '0.8rem',
			whiteSpace : 'nowrap',
			marginTop  : '0.5rem'
		},
		summeryLabelSmall : {
			fontSize     : '0.6rem',
			marginLeft   : '0.5rem',
			width        : '100%',
			textAlign    : 'end',
			paddingRight : '12px'
		},
		summeryValue : {
			fontSize        : '0.8rem',
			padding         : '0.5rem',
			backgroundColor : 'rgba(0,0,0,0.1)',
			color           : 'var(--text-color)',
			borderRadius    : '0.5rem',
			width           : '6rem',
			marginLeft      : '0.5rem',
			marginRight     : '0.5rem',
			textAlign       : 'center'
		},
		input : {
			width        : '80%',
			border       : 'none',
			marginBottom : '1.5rem'
		},
		scrollBar : {
			overflow               : 'auto',
			height                 : '100%',
			paddingRight           : '10px',
			'&::-webkit-scrollbar' :
			{
				background   : '#ccc',
				width        : '7px',
				borderRadius : '100px'
			},
			'&::-webkit-scrollbar-thumb' :
			{
				background   : '#888',
				borderRadius : '100px',
				'&:hover'    :
				{
					background : '#555'
				}
			}
		},
		wrapper : {
			width           : 'calc(100% - 20px)',
			height          : 'calc(100% - 2.5rem)',
			backgroundColor : 'white',
			borderRadius    : '0.5rem',
			padding         : '10px',
			position        : 'relative'
		},
		tableBg : {
			display       : 'flex',
			position      : 'absolute',
			height        : '100%',
			width         : '100%',
			zIndex        : '0',
			pointerEvents : 'none',
			paddingRight  : 'calc(6% + 17px)',
			paddingBottom : '6%'
		},
		tableBgLine : {
			height      : '100%',
			borderRight : '1px solid #ccc',
			zIndex      : 2
		},
		tableHeader : {
			display      : 'flex',
			background   : 'rgba(0,0,0,0.1)',
			borderRadius : '0.5rem 0.5rem 0rem 0rem',
			height       : '2.5rem'
		},
		tableFooter : {
			background   : 'rgba(0,0,0,0.1)',
			borderRadius : '0rem 0rem 0.5rem 0.5rem',
			height       : '1rem'
		},
		tableHeaderText : {
			fontSize  : '0.8rem',
			textAlign : 'center',
			padding   : '0.7rem',
			color     : '#999898'
		},
		tableData : {
			display   : 'flex',
			listStyle : 'none',
			position  : 'relative',
			height    : '2.5rem'
		},
		tableDataValues : {
			display      : 'flex',
			border       : 'none',
			borderRadius : '0rem',
			background   : 'rgba(0,0,0,0.05)',
			alignItems   : 'center',
			zIndex       : 1,
			height       : '100%'
		},
		tableDataValuesOdd : {
			background : 'rgba(0,0,0,0.025)'
		},
		tableDataValue : {
			color     : '#888',
			textAlign : 'center',
			fontSize  : '0.8rem'
		},
		historyDate : {
			width : '30%'
		},
		historyDateContent : {
			width : '30%'
		},
		historyCount : {
			width : '30%'
		},
		historyDuration : {
			width : '40%'
		},
		button : {
			backgroundColor : 'white',
			border          : '1px solid #ccc',
			borderRadius    : '0.5rem',
			padding         : '0.1rem',
			fontSize        : '0.8rem',
			width           : '2rem',
			textAlign       : 'center',
			boxShadow       : 'none',
			color           : 'var(--text-color)'
		},
		closeButton : {
			backgroundColor : 'white',
			border          : '1px solid #ccc',
			borderRadius    : '0.5rem',
			padding         : '3px 12px',
			fontSize        : '0.8rem',
			textAlign       : 'center',
			boxShadow       : 'none',
			color           : 'var(--text-color)'
		},
		loading :
		{
			width           : '100%',
			height          : '35rem',
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		}
	});

const theme = createTheme({
	overrides : {
		MuiPaper : {
			rounded : {
				borderRadius : '0.5rem'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none',
				padding        : '1rem 0'
			}
		},
		MuiDialogActions : {
			root : {
				flexDirection  : 'column',
				justifyContent : 'center'
			},
			spacing : {
				'& > :not(:first-child)' : {
					marginLeft : '0'
				}
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.6rem',
				marginRight : '0.6rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding         : '0.4rem 0.8rem',
				border          : 'none',
				borderRadius    : '0.5rem',
				backgroundColor : 'var(--text-color)',
				boxShadow       : '2px 2px 4px inset var(--text-color)',
				color           : 'white'
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const PortalUsageDialog = ({
	classes,
	portalUsageDialogOpen,
	setPortalUsageDialogOpen,
	setPortalUsageDialogSubState,
	setPortalUsageDialogSubOpen,
	portalId,
	calls
}) =>
{
	const [ callList, setCallList ] = useState([]);

	const [ thisMonth, setThisMonth ] = useState('');
	const [ thisMonthCount, setThisMonthCount ] = useState(0);
	const [ thisMonthDuration, setThisMonthDuration ] = useState(0);

	const [ loading, setLoading ] = useState(false);

	const handleSelectMonth = (item) =>
	{
		setPortalUsageDialogSubState({ year: item.year, month: item.month });
		setPortalUsageDialogSubOpen(true);
	};

	const getTalkTimeString = (duration) =>
	{
		const seconds = parseInt(duration);
		const min = (`${parseInt(seconds/60)}`);
		const sec = (`0${parseInt(seconds%60)}`).slice(-2);

		return `${min}分${sec}秒`;
	};

	useEffect(() =>
	{

		const getCalls = async () =>
		{
			setLoading(true);
			const currentYear = dayjs().year();
			const currentMonth = dayjs().month();

			const listToShow = [];

			for (let i = 0; i < 12; i++)
			{
				let year = currentYear;

				let month = currentMonth - i;

				if (month < 0)
				{
					year = currentYear -1;
					month = month + 12;
				}

				const items = calls.filter((item) =>
				{
					const date = dayjs(item.timestamp);

					if (date.year() === year && date.month() === month)
					{
						return true;
					}

					return false;
				});

				let duration = 0;

				items.forEach((item) =>
				{
					duration += item.duration;
				});

				listToShow.push({
					year  : year,
					month : month,
					count : items.length || 0,
					duration
				});
			}

			setThisMonth(`${currentYear}年${currentMonth+1}月`);

			const currentMonthData = listToShow.find(
				(item) => item.year === currentYear && item.month === currentMonth
			);

			setThisMonthCount(currentMonthData.count);
			setThisMonthDuration(currentMonthData.duration);

			setCallList(listToShow);

			setLoading(false);

		};

		if (portalUsageDialogOpen && portalId)
		{
			getCalls();
		}

	// eslint-disable-next-line
	}, [ portalUsageDialogOpen, portalId]);

	return (
		<MuiThemeProvider theme={theme}>
			<Dialog
				open={portalUsageDialogOpen}
				onClose={() => setPortalUsageDialogOpen(false)}
				classes={{
					root  : classes.dialogRoot,
					paper : classes.dialogPaper
				}}
				BackdropProps={{
					style : {
						backgroundColor : 'transparent'
					}
				}}
			>
				<DialogContent>
					<Box className={classes.header}>
						<img
							src='images/user_setting_icon.png'
							className={classes.icon}
							alt=''
						/>
						<Typography className={classes.title}>
							ご利用通話明細
						</Typography>
					</Box>

					<Box className={classes.summery}>
						<Box className={classes.summeryItem}>
							<Box className={classes.summeryLabelSection}>
								<Typography className={classes.summeryLabel}>
									今月のご利用件数
								</Typography>
								<Typography className={classes.summeryLabelSmall}>
									({thisMonth})
								</Typography>
							</Box>
							<Box className={classes.summeryValue}>
								{thisMonthCount}件
							</Box>
						</Box>
						<Box className={classes.summeryItemWrapper}>
							<Box className={classes.summeryItem}>
								<Box className={classes.summeryLabelSection}>
									<Typography className={classes.summeryLabel}>
										今月の通話時間
									</Typography>
								</Box>
								<Box>
									<Typography className={classes.summeryValue}>
										{getTalkTimeString(thisMonthDuration)}
									</Typography>
								</Box>
							</Box>
							<Typography className={classes.summeryLabelSmall}>
								※毎月１日にリセットされます。
							</Typography>
						</Box>
					</Box>

					{ !loading ?
						<Box className={classes.wrapper}>
							<Box className={classes.tableBg}>
								<Box className={`${classes.tableBgLine} ${classes.historyDate}`} />
								<Box className={`${classes.tableBgLine} ${classes.historyCount}`} />
								<Box className={`${classes.historyDuration}`} />
							</Box>
							<Box className={`${classes.scrollBar}`}>
								<ul>
									<Box className={classes.tableHeader}>
										<Box className={`${classes.tableHeaderText} ${classes.historyDate}`}>
											利用月
										</Box>
										<Box className={`${classes.tableHeaderText} ${classes.historyCount}`}>
											件数
										</Box>
										<Box className={`${classes.tableHeaderText} ${classes.historyDuration}`}>
											月間通話時間
										</Box>
									</Box>
									{callList.length < 1 ? (
										<Box>No items</Box>
									) : (
										callList.map((item, index) =>
											(<li className={classes.tableData} key={index}>
												<Box position='absolute' width='100%' height='100%'>
													<Box className={`${classes.tableDataValues} ${index % 2 === 1 ? classes.tableDataValuesOdd : ''}`}>
														<Box className={`${classes.tableDataValue} ${classes.historyDateContent}`}>{item.year}/{item.month+1}</Box>
														{ item.count !== 0 ?
															<Box className={`${classes.tableDataValue} ${classes.historyCount}`}>
																<Button
																	className={classes.button} onClick={() => handleSelectMonth(item)} variant='contained'
																>
																	{item.count}件
																</Button>
															</Box>
															:
															<Box className={`${classes.tableDataValue} ${classes.historyCount}`}>
																<Button className={classes.button}
																	disabled
																	variant='contained'
																>
																	0件
																</Button>
															</Box>
														}
														<Box className={`${classes.tableDataValue} ${classes.historyDuration}`}>{getTalkTimeString(item.duration)}</Box>
													</Box>
												</Box>
											</li>)
										))}
									<Box className={classes.tableFooter} />
								</ul>
							</Box>
						</Box>
						:
						<Box className={classes.loading}>
							<CircularProgress />
						</Box>
					}
				</DialogContent>
				<DialogActions>
					<Button
						className={classes.closeButton} onClick={() => setPortalUsageDialogOpen(false)} variant='contained'
					>
						閉じる
					</Button>
				</DialogActions>
			</Dialog>
		</MuiThemeProvider>
	);
};

PortalUsageDialog.propTypes =
{
	classes                      : PropTypes.object.isRequired,
	portalUsageDialogOpen        : PropTypes.bool.isRequired,
	setPortalUsageDialogOpen     : PropTypes.func.isRequired,
	portalUsageDialogSubState    : PropTypes.object.isRequired,
	setPortalUsageDialogSubState : PropTypes.func.isRequired,
	setPortalUsageDialogSubOpen  : PropTypes.func.isRequired,
	portalId                     : PropTypes.string,
	calls                        : PropTypes.array.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(PortalUsageDialog)));
