import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../../RoomContext';
import { FormattedMessage } from 'react-intl';

// material ui
import { Button, Typography } from '@material-ui/core';

const styles = () =>
	({
		buttonWithFlag : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		buttonWithFlagImg : {
			width          : '60px',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		flagImg : {
			width  : '60px',
			height : 'auto'
		},
		buttonWithFlagText : {
			width          : 'calc( 100% - 60px )',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		joinButton : {
			display      : 'block',
			position     : 'relative',
			borderRadius : '5px',
			boxShadow    : '#CCC 5px 5px 5px',
			fontSize     : '1.2rem',
			'&:hover'    : {
				filter : 'saturate(105%) brightness(105%)'
			},
			'&:only-child' : {
				marginRight : 'auto !important',
				marginLeft  : 'auto !important'
			}
		},
		businessHoursLine : {
			position   : 'absolute',
			margin     : 'auto',
			left       : '50%',
			transform  : 'translateX(-50%)',
			whiteSpace : 'nowrap',
			bottom     : '-26px'
		},
		businessHoursText : {
			fontSize : '1rem',
			color    : '#292929'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ServiceButton = ({
	classes,
	rowIndex,
	columnIndex,
	buttons,
	btnAreaContentVal,
	btnFlagConds,
	roomAvailable,
	handleJoin
}) =>
{

	const itemIndex = rowIndex*btnAreaContentVal.columns + columnIndex;

	if (buttons[itemIndex])
	{
		return (
			<MuiThemeProvider theme={theme}>
				<Button
					key={columnIndex}
					style={{
						backgroundColor : `#${buttons[itemIndex].btnColor}`,
						padding
						: btnAreaContentVal.btnPadding,
						margin : btnAreaContentVal.btnMargin
					}}
					className={classes.joinButton}
					onClick={() => handleJoin(itemIndex)}
				>
					{ btnFlagConds[
						buttons[itemIndex].subdomain
					] ?
						<div className={
							classes.buttonWithFlag
						}
						>
							<div className={
								classes.buttonWithFlagImg
							}
							>
								<img
									alt={btnFlagConds[
										buttons[itemIndex].subdomain
									].alt}
									src={btnFlagConds[
										buttons[itemIndex].subdomain
									].img}
									className={classes.flagImg}
								/>
							</div>
							<div className={
								classes.buttonWithFlagText
							}
							>
								<Typography
									className={classes.joinButtonText}
									style={{
										color    : `#${buttons[itemIndex].textColor}`,
										fontSize : `min(${btnAreaContentVal.btnFontSize}, 26px)`
									}}
								>
									{
										btnFlagConds[buttons[itemIndex]
											.subdomain].text
									}
								</Typography>
								<Typography
									className={
										classes.joinButtonTextSmall
									}
									style={{
										color    : `#${buttons[itemIndex].textColor}`,
										fontSize : `min(${btnAreaContentVal.btnFontSizeSecond}, 18px)`
									}}
								>
									{
										roomAvailable[itemIndex] ?
											<FormattedMessage
												id='service.englishStatus1'
												defaultMessage='Available Now'
											/>
											:
											<FormattedMessage
												id='service.englishStatus2'
												defaultMessage='Crowded Now'
											/>
									}
								</Typography>
							</div>
						</div>
						:
						<>
							<Typography
								className={classes.joinButtonText}
								style={{
									color    : `#${buttons[itemIndex].textColor}`,
									fontSize : `min(${btnAreaContentVal.btnFontSize}, 26px)`
								}}
							>

								{buttons[itemIndex].btnText}
							</Typography>
							<Typography
								className={
									classes.joinButtonTextSmall
								}
								style={{
									color    : `#${buttons[itemIndex].textColor}`,
									fontSize : `min(${btnAreaContentVal.btnFontSizeSecond}, 18px)`
								}}
							>
								{
									roomAvailable[itemIndex] ?
										<FormattedMessage
											id='service.englishStatus1'
											defaultMessage='Available Now'
										/>
										:
										<FormattedMessage
											id='service.englishStatus2'
											defaultMessage='Crowded Now'
										/>
								}
							</Typography>
						</>
					}
					{ buttons[itemIndex].businessHours &&
					<div
						className={classes.businessHoursLine}
					>
						<Typography
							className={classes.businessHoursText}
						>
							<FormattedMessage
								id='service.englishReception'
								defaultMessage='BUSINESS HOURS'
							/>&nbsp;:&nbsp;
							{buttons[itemIndex].businessHours}
						</Typography>
					</div>
					}
				</Button>
			</MuiThemeProvider>
		);
	}
	else
	{
		return null;
	}
};

ServiceButton.propTypes =
{
	classes           : PropTypes.object.isRequired,
	rowIndex          : PropTypes.number,
	columnIndex       : PropTypes.number,
	buttons           : PropTypes.array.isRequired,
	btnAreaContentVal : PropTypes.object.isRequired,
	btnFlagConds      : PropTypes.object.isRequired,
	roomAvailable     : PropTypes.array.isRequired,
	handleJoin        : PropTypes.func.isRequired

};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceButton)));
