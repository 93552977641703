/**
 * 外部ライブラリ等の設定用ファイル
 */
import * as Const from '../const';

function firebaseConfig(env)
{
	switch (env)
	{
		case Const.ENV_DEVELOP:
			return {
				apiKey            : 'AIzaSyB_LDLW4xx7MD7zJE3SYi30zU06F7t30CY',
				authDomain        : 'nddoor-plus.firebaseapp.com',
				projectId         : 'nddoor-plus',
				storageBucket     : 'nddoor-plus.appspot.com',
				messagingSenderId : '576461100261',
				appId             : '1:576461100261:web:f3fd45e0a407c4c74fb00c',
				measurementId     : 'G-SNT2LMKQF3'
			};

		case Const.ENV_STAGING:
			return {
				apiKey            : 'AIzaSyB_LDLW4xx7MD7zJE3SYi30zU06F7t30CY',
				authDomain        : 'nddoor-plus.firebaseapp.com',
				projectId         : 'nddoor-plus',
				storageBucket     : 'nddoor-plus.appspot.com',
				messagingSenderId : '576461100261',
				appId             : '1:576461100261:web:f3fd45e0a407c4c74fb00c',
				measurementId     : 'G-SNT2LMKQF3'
			};

			// case Const.ENV_PRODUCTION:
			// return {
			// 	apiKey            : 'AIzaSyB_LDLW4xx7MD7zJE3SYi30zU06F7t30CY',
			// 	authDomain        : 'nddoor-plus.firebaseapp.com',
			// 	projectId         : 'nddoor-plus',
			// 	storageBucket     : 'nddoor-plus.appspot.com',
			//	messagingSenderId : '576461100261',
			//	appId             : '1:576461100261:web:f3fd45e0a407c4c74fb00c',
			//	measurementId     : 'G-SNT2LMKQF3'
			// };
		case Const.ENV_PRODUCTION:
			return {
				apiKey            : 'AIzaSyDYXlWhtg91ISMrXtvlfG-yP1WpLwPOSM4',
				authDomain        : 'online-call-project.firebaseapp.com',
				projectId         : 'online-call-project',
				storageBucket     : 'online-call-project.appspot.com',
				messagingSenderId : '176323743322',
				appId             : '1:176323743322:web:9e0db239c589e9fe5fd263',
				measurementId     : 'G-0J79F6SSDQ'
			};

		default:
			return {
				apiKey            : 'AIzaSyB_LDLW4xx7MD7zJE3SYi30zU06F7t30CY',
				authDomain        : 'nddoor-plus.firebaseapp.com',
				projectId         : 'nddoor-plus',
				storageBucket     : 'nddoor-plus.appspot.com',
				messagingSenderId : '576461100261',
				appId             : '1:576461100261:web:f3fd45e0a407c4c74fb00c',
				measurementId     : 'G-SNT2LMKQF3'
			};
	}
}

function googleAnalyticsConfig(env)
{
	switch (env)
	{
		case Const.ENV_DEVELOP:
			return '';

		case Const.ENV_STAGING:
			return 'UA-195338529-1';

		case Const.ENV_PRODUCTION:
			return 'UA-196910415-1';
		default :
			return 'UA-196910415-1';
	}
}

export const Config = {
	firebase        : firebaseConfig(process.env.NODE_ENV) || {},
	googleAnalytics : googleAnalyticsConfig(process.env.NODE_ENV)
};
