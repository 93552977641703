// React
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// Dialog 
import SetNewPasswordDialog from '../Common/SetNewPasswordDialog';

// Firebase
import { firestore } from '../../../lib/firebase';

// material ui
import CircularProgress from '@material-ui/core/CircularProgress';

const OperatorResetPasswordDialog = () =>
{

	const location = useLocation();
	const history = useHistory();

	const [ loginId, setLoginId ] = useState('');
	const [ email, setEmail ] = useState('');

	const [ hostId, setHostId ] = useState('');
	const [ portalId, setPortalId ] = useState('');
	const [ serviceParamKey, setServiceParamKey ] = useState('');
	const [ documentId, setDocumentId ] = useState('');
	const [ passChangeKey, setPassChangeKey ] = useState('');

	const [ isLoading, setIsLoading ] = useState(true);
	const [ errorToShow, setErrorToShow ] = useState('');

	const afterCloseMethod = () =>
	{
		if (location.pathname === '/sap')
		{
			history.replace({
				pathname : '/manage'
			});
		}
		else if (location.pathname === '/sgp')
		{
			history.replace({
				pathname : '/service',
				search   : `?id=${serviceParamKey}`
			});
		}
		// portal login id password
		else if (location.pathname === '/plp')
		{
			history.replace({
				pathname : '/portal',
				search   : `?id=${portalId}`
			});
		}
		// host password
		else
		{
			history.replace({
				pathname : '/host'
			});
		}
	};

	useEffect(() =>
	{
		const reg = new RegExp(/^[a-zA-Z0-9]+$/);

		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		const parts = window.location.hostname.split('.');
		const subdomain = parts.shift();

		if (paramsValue && reg.test(paramsValue) && paramsValue.length >= 36)
		{
			// 0-15 is passwordChangeKey. ID is from 16 to the end
			setPassChangeKey(paramsValue.substring(0, 16));
			setHostId(decodeURIComponent(paramsValue.substring(16)));

			try
			{
				// service account
				if (location.pathname === '/sap')
				{
					firestore.collection('serviceAccounts')
						.doc(decodeURIComponent(paramsValue.substring(16)))
						.get()
						.then((doc) =>
						{
							const loginData = doc.data();
							const emailVal = loginData?.email;
							const passwordChangeKey = loginData?.passwordChangeKey;

							if (!passwordChangeKey)
							{
								setErrorToShow('URLが正しくありません。');
								setIsLoading(false);
							}
							else if (emailVal)
							{
								setDocumentId(paramsValue.substring(16));
								setEmail(emailVal);
								setLoginId(emailVal);
								setHostId('');
								setIsLoading(false);
							}
							else
							{
								setErrorToShow('URLが正しくありません。');
								setIsLoading(false);
							}
						});
				}
				// service guest
				else if (location.pathname === '/sgp')
				{
					firestore.collection('serviceGuests')
						.doc(decodeURIComponent(paramsValue.substring(16)))
						.get()
						.then((doc) =>
						{
							const loginData = doc.data();
							const emailVal = loginData?.email;
							const passwordChangeKey = loginData?.passwordChangeKey;

							if (!passwordChangeKey)
							{
								setErrorToShow('URLが正しくありません。');
								setIsLoading(false);
							}
							else if (emailVal)
							{
								setDocumentId(paramsValue.substring(16));
								setEmail(emailVal);
								setServiceParamKey(loginData?.accountParamKey);
								setLoginId(emailVal);
								setHostId('');
								setIsLoading(false);
							}
							else
							{
								setErrorToShow('URLが正しくありません。');
								setIsLoading(false);
							}
						});
				}
				// portal login id password
				else if (location.pathname === '/plp')
				{
					firestore.collection('portalLoginIds')
						.doc(decodeURIComponent(paramsValue.substring(16)))
						.get()
						.then((doc) =>
						{
							const portalLoginData = doc.data();
							const emailVal = portalLoginData?.email;
							const passwordChangeKey = portalLoginData?.passwordChangeKey;

							if (!passwordChangeKey)
							{
								setErrorToShow('URLが正しくありません。');
								setIsLoading(false);
							}
							else if (emailVal)
							{
								setDocumentId(paramsValue.substring(16));
								setEmail(emailVal);
								setPortalId(portalLoginData?.portalId);
								setLoginId(emailVal);
								setHostId('');
								setIsLoading(false);
							}
							else
							{
								setErrorToShow('URLが正しくありません。');
								setIsLoading(false);
							}
						});
				}
				else
				{
					firestore.collection('hosts')
						.doc(decodeURIComponent(paramsValue.substring(16)))
						.get()
						.then((doc) =>
						{
							const hostData = doc.data();
							const loginIdVal = hostData?.loginId;
							const emailVal = hostData?.email;
							const subdomainVal = hostData?.subdomain;
							const passwordChangeKey = hostData?.passwordChangeKey;

							if (!passwordChangeKey)
							{
								setErrorToShow('URLが正しくありません。');
								setIsLoading(false);
							}
							else if (loginIdVal && emailVal && subdomainVal === subdomain)
							{
								setLoginId(loginIdVal);
								setEmail(emailVal);
								setDocumentId('');
								setIsLoading(false);
							}
							else
							{
								setErrorToShow('URLが正しくありません。');
								setIsLoading(false);
							}
						});
				}
			}
			catch
			{
				setErrorToShow('サーバーエラーが発生しました。');
				setIsLoading(false);
			}
		}
		else
		{
			setErrorToShow('URLが正しくありません。');
			setIsLoading(false);
		}

	}, [ location ]);

	let target = 'host';

	if (location.pathname === '/plp')
	{
		target = 'portal';
	}
	else if (location.pathname === 'sap')
	{
		target = 'serviceAccount';
	}
	else if (location.pathname === 'sgp')
	{
		target = 'serviceGuest';
	}

	return (
		<>
			{
				isLoading ?
					<div style={{
						width           : '100vw',
						height          : '100vh',
						zIndex          : 9999999,
						position        : 'fixed',
						top             : 0,
						left            : 0,
						display         : 'flex',
						justifyContent  : 'center',
						alignItems      : 'center',
						backgroundColor : 'rgba(255, 255, 255, 0.5)' }}
					>
						<CircularProgress />
					</div>
					:
					<SetNewPasswordDialog
						target={target}
						pageType={'change'}
						loginId={loginId}
						email={email}
						hostId={hostId}
						documentId={documentId}
						passwordChangeKey={passChangeKey}
						afterClose={afterCloseMethod}
						errorToShow={errorToShow}
					/>
			}
		</>
	);
};

export default OperatorResetPasswordDialog;
