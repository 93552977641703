export const addMonitorMessage = (type, text, time) =>
	({
		type    : 'ADD_MONITOR_MESSAGE',
		payload : { type, text, time }
	});

export const addMonitorIconsMessage = (type, icons, time) =>
	({
		type    : 'ADD_MONITOR_ICONS_MESSAGE',
		payload : { type, icons, time }
	});

export const addMonitorResponseMessage = (message) =>
	({
		type    : 'ADD_MONITOR_RESPONSE_MESSAGE',
		payload : { message }
	});

export const uploadedMonitorShareFile = (fileInfo) =>
	({
		type    : 'UPLOADED_MONITOR_SHARE_FILE',
		payload : { fileInfo }
	});

export const recieveNewMonitorShareFile = (fileInfo) =>
	({
		type    : 'RECIEVE_NEW_MONITOR_SHARE_FILE',
		payload : { fileInfo }
	});

export const addMonitorChatHistory = (chatHistory) =>
	({
		type    : 'ADD_MONITOR_CHAT_HISTORY',
		payload : { chatHistory }
	});

export const clearMonitorChat = () =>
	({
		type : 'CLEAR_MONITOR_CHAT'
	});