export const setMe = ({ peerId, loginEnabled }) =>
	({
		type    : 'SET_ME',
		payload : { peerId, loginEnabled }
	});

export const setBrowser = (browser) =>
	({
		type    : 'SET_BROWSER',
		payload : { browser }
	});

export const loggedIn = (flag) =>
	({
		type    : 'LOGGED_IN',
		payload : { flag }
	});

export const addRole = (roleId) =>
	({
		type    : 'ADD_ROLE',
		payload : { roleId }
	});

export const removeRole = (roleId) =>
	({
		type    : 'REMOVE_ROLE',
		payload : { roleId }
	});

export const setPicture = (picture) =>
	({
		type    : 'SET_PICTURE',
		payload : { picture }
	});

export const setMediaCapabilities = ({
	canSendMic,
	canSendWebcam,
	canShareScreen,
	canShareFiles
}) =>
	({
		type    : 'SET_MEDIA_CAPABILITIES',
		payload : { canSendMic, canSendWebcam, canShareScreen, canShareFiles }
	});

export const setAudioDevices = (devices) =>
	({
		type    : 'SET_AUDIO_DEVICES',
		payload : { devices }
	});

export const setAudioOutputDevices = (devices) =>
	({
		type    : 'SET_AUDIO_OUTPUT_DEVICES',
		payload : { devices }
	});

export const setWebcamDevices = (devices) =>
	({
		type    : 'SET_WEBCAM_DEVICES',
		payload : { devices }
	});

export const setRaisedHand = (flag) =>
	({
		type    : 'SET_RAISED_HAND',
		payload : { flag }
	});

export const setAudioInProgress = (flag) =>
	({
		type    : 'SET_AUDIO_IN_PROGRESS',
		payload : { flag }
	});

export const setAudioOutputInProgress = (flag) =>
	({
		type    : 'SET_AUDIO_OUTPUT_IN_PROGRESS',
		payload : { flag }
	});

export const setWebcamInProgress = (flag) =>
	({
		type    : 'SET_WEBCAM_IN_PROGRESS',
		payload : { flag }
	});

export const setScreenShareInProgress = (flag) =>
	({
		type    : 'SET_SCREEN_SHARE_IN_PROGRESS',
		payload : { flag }
	});

export const setRaisedHandInProgress = (flag) =>
	({
		type    : 'SET_RAISED_HAND_IN_PROGRESS',
		payload : { flag }
	});

export const setDisplayNameInProgress = (flag) =>
	({
		type    : 'SET_DISPLAY_NAME_IN_PROGRESS',
		payload : { flag }
	});

export const setIsSpeaking = (flag) =>
	({
		type    : 'SET_IS_SPEAKING',
		payload : { flag }
	});

export const setAutoMuted = (flag) =>
	({
		type    : 'SET_AUTO_MUTED',
		payload : { flag }
	});

export const setStatus = (flag) =>
	({
		type    : 'SET_STATUS',
		payload : { flag }
	});

export const setUser = (user) =>
	({
		type    : 'SET_USER',
		payload : { user }
	});

export const setHost = (host) =>
	({
		type    : 'SET_HOST',
		payload : { host }
	});

export const setHostLogin = ({ host, hostLogin }) =>
	({
		type    : 'SET_HOST_LOGIN',
		payload : { host, hostLogin }
	});

export const setHostListening = (hostListening) =>
	({
		type    : 'SET_HOST_LISTENING',
		payload : { hostListening: hostListening }
	});

export const setLogoutByHostListening = (flag) =>
	({
		type    : 'SET_LOGOUT_BY_HOST_LISTENING',
		payload : { flag }
	});

export const setHostFirstLoginComp = (hostPassChangeComp) =>
	({
		type    : 'SET_HOST_FIRST_LOGIN_COMP',
		payload : { hostPassChangeComp }
	});

export const setSettings = (settings) =>
	({
		type    : 'SET_SETTINGS',
		payload : { settings }
	});

export const setRooms = (rooms) =>
	({
		type    : 'SET_ROOMS',
		payload : { rooms }
	});

export const setGuestInput1 = (guestInput1) =>
	({
		type    : 'SET_GUEST_INPUT1',
		payload : { guestInput1 }
	});

export const setGuestInput2 = (guestInput2) =>
	({
		type    : 'SET_GUEST_INPUT2',
		payload : { guestInput2 }
	});

export const setIsPortraitScreen = (isPortraitScreen) =>
	({
		type    : 'SET_IS_PORTRAIT_SCREEN',
		payload : { isPortraitScreen }
	});

export const setServiceStopped = (flag) =>
	({
		type    : 'SET_SERVICE STOPPED',
		payload : { flag }
	});

export const setUseMobileView = (useMobileView) =>
	({
		type    : 'SET_USE_MOBILE_VIEW',
		payload : { useMobileView }
	});

export const setHostSupportCallList = (supportCallList) =>
	({
		type    : 'SET_HOST_SUPPORT_CALL_LIST',
		payload : { supportCallList }
	});

export const setPostEvents = (postEvents) =>
	({
		type    : 'SET_POST_EVENTS',
		payload : { postEvents }
	});

export const updatePersonalCallKey = (personalCallKey) =>
	({
		type    : 'UPDATE_PERSONAL_CALL_KEY',
		payload : { personalCallKey }
	});

export const setServiceTicketLogin = (serviceTicketLogin) =>
	({
		type    : 'SET_SERVICE_TICKET_LOGIN',
		payload : { serviceTicketLogin }
	});

export const setServiceGuestLoggedIn = (open) =>
	({
		type    : 'SET_SERVICE_GUEST_LOGGED_IN',
		payload : { open }
	});

export const setUserAgent = (userAgent) =>
	({
		type    : 'SET_USER_AGENT',
		payload : { userAgent }
	});