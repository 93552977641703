// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// util
import { showNumberWithComma } from '../../../utils';

// dayjs

const styles = () =>
	({
		contentPanel : {
			width          : '100%',
			maxWidth       : '1670px',
			borderBottom   : '1px solid #292929',
			display        : 'flex',
			justifyContent : 'space-between',
			flexWrap       : 'wrap',
			alignItems     : 'end',
			color          : '#292929'
		},
		contentPanelItemGroup :
		{
			marginTop : '20px'
		},
		contentPanelItemFirst : {
			minWidth     : '220px',
			flexWrap     : 'wrap',
			marginBottom : '10px'
		},
		contentPanelItemSecond : {
			minWidth     : '250px',
			flexWrap     : 'wrap',
			marginBottom : '10px'
		},
		contentPanelItemThird : {
			minWidth     : '330px',
			flexWrap     : 'wrap',
			marginBottom : '2px'
		},
		contentPanelItem : {
			display     : 'flex',
			marginRight : '15px',
			alignItems  : 'end'
		},
		contentPanelItemBlack : {
			display         : 'flex',
			alignItems      : 'center',
			backgroundColor : '#343433',
			borderRadius    : '3px',
			padding         : '5px 10px 7px 10px',
			color           : '#FFF',
			marginTop       : '2px'
		},
		contentPanelItemText :
		{
			height      : '1rem',
			fontSize    : '0.9rem',
			marginRight : '5px'
		},
		contentPanelItemValue :
		{
			height     : '1.1rem',
			fontSize   : '1rem',
			lineHeight : '1.3rem',
			fontWeight : 'bold'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const MonthlySalesAmount = ({
	classes,
	data,
	originPage,
	year,
	month,
	paymentYear,
	paymentMonth,
	ticketsListLength,
	salesAmount,
	holesaleAmount,
	commissionAmount
}) =>
{
	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.contentPanel}>
				<Box className={`${classes.contentPanelItem} ${classes.contentPanelItemGroup} ${classes.contentPanelItemFirst}`}>
					<Typography className={classes.contentPanelItemText}>
						{`${year}年${month}月の取引件数:`}
					</Typography>
					<Typography className={classes.contentPanelItemValue}>
						{showNumberWithComma(ticketsListLength)}件
					</Typography>
				</Box>
				{ data === 'customerSales' &&
				<>
					{ originPage === 'editAccount' ?
						<Box className={`${classes.contentPanelItem} ${classes.contentPanelItemGroup} ${classes.contentPanelItemSecond}`}>
							<Typography className={classes.contentPanelItemText}>
								{ `${year}年${month}月の手数料合計金額:`}
							</Typography>
							<Typography className={classes.contentPanelItemValue}>
								{showNumberWithComma(commissionAmount)}円(税込)
							</Typography>
						</Box>
						:
						<Box className={`${classes.contentPanelItem} ${classes.contentPanelItemGroup} ${classes.contentPanelItemSecond}`}>
							<Typography className={classes.contentPanelItemText}>
								{ `${year}年${month}月の販売合計金額:`}
							</Typography>
							<Typography className={classes.contentPanelItemValue}>
								{showNumberWithComma(salesAmount)}円(税込)
							</Typography>
						</Box>
					}
				</>
				}
				<Box className={`${classes.contentPanelItemGroup} ${classes.contentPanelItemThird}`}>
					{ originPage === 'manage' &&
					<Typography className={classes.contentPanelItemText}>
						{ data === 'purchase' ? `${paymentYear}年${paymentMonth}月末払い ご請求分` : `${paymentYear}年${paymentMonth}月末払い ご入金分`}
					</Typography>
					}
					<Box className={classes.contentPanelItemBlack}>
						<Typography className={classes.contentPanelItemText}>
							{ data === 'purchase' ? `${year}年${month}月の御社購入合計金額:` : originPage === 'editAccount' ? `${year}年${month}月の販売合計金額:`: `${year}年${month}月の手数料合計金額:`}
						</Typography>
						<Typography className={classes.contentPanelItemValue}>
							{
								data === 'purchase' ? showNumberWithComma(
									holesaleAmount)
									: originPage === 'editAccount' ? showNumberWithComma(salesAmount)
										: showNumberWithComma(commissionAmount)
							}
							円(税込)
						</Typography>
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

MonthlySalesAmount.propTypes =
{
	classes           : PropTypes.object.isRequired,
	originPage        : PropTypes.string.isRequired,
	data              : PropTypes.string.isRequired,
	year              : PropTypes.number.isRequired,
	month             : PropTypes.number.isRequired,
	paymentYear       : PropTypes.number.isRequired,
	paymentMonth      : PropTypes.number.isRequired,
	ticketsListLength : PropTypes.number,
	salesAmount       : PropTypes.number,
	holesaleAmount    : PropTypes.number,
	commissionAmount  : PropTypes.number
};

export default withStyles(styles)(MonthlySalesAmount);