const initialState =
{
	callState : 'new',
	infos     : {},
	callHost  : {},
	dbData    : {}
};

const supportCall = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_SUPPORT_CALL_STATE':
		{
			const callState = action.payload;

			return { ...state, callState: callState };
		}

		case 'SET_SUPPORT_CALL_INFOS':
		{
			const {
				infos
			} = action.payload;

			return {
				...state,
				infos
			};
		}

		case 'SET_SUPPORT_CALL_HOST' :
		{
			const {
				callHost
			} = action.payload;

			return {
				...state,
				callHost
			};
		}

		case 'SET_DB_DATA' :
		{
			const { dbData } = action.payload;

			return {
				...state,
				dbData : { ...dbData }
			};
		}

		default:
			return state;
	}
};

export default supportCall;
