// React
import React from 'react';

// Prop types
import PropTypes from 'prop-types';

// Message
import { useIntl } from 'react-intl';

const TicketExpiryText = ({
	expiryTerm,
	expiryTermAfterUse,
	singleRow
}) =>
{
	const intl = useIntl();

	let returnTextFirstLine = '';

	let returnTextSecondLine = '';

	if (expiryTermAfterUse)
	{
		if (expiryTermAfterUse?.type === 'day')
		{
			const condValue = Number(expiryTermAfterUse.value);

			if (condValue === 1)
			{
				returnTextFirstLine =
							intl.formatMessage({
								id             : 'paidservice.dayAfterLogin',
								defaultMessage : '1 day after entering the code'
							});
			}
			else if (condValue === 7)
			{
				returnTextFirstLine =
							intl.formatMessage({
								id             : 'paidservice.weekAfterLogin',
								defaultMessage : '1 week after entering the code'
							});
			}
			else if (condValue %7 === 0)
			{
				returnTextFirstLine =
							intl.formatMessage({
								id             : 'paidservice.weeksAfterLogin',
								defaultMessage : '{value} weeks after entering the code'
							}, {
								value : expiryTermAfterUse.value/7
							});
			}
			else
			{
				returnTextFirstLine =
							intl.formatMessage({
								id             : 'paidservice.daysAfterLogin',
								defaultMessage : '{value} days after entering the code'
							}, {
								value : expiryTermAfterUse.value
							});
			}
		}
		else if (expiryTermAfterUse?.type === 'month')
		{
			const condValue = Number(expiryTermAfterUse.value);

			if (condValue === 1)
			{
				returnTextFirstLine =
							intl.formatMessage({
								id             : 'paidservice.monthAfterLogin',
								defaultMessage : '1 month after entering the code'
							});
			}
			else
			{
				returnTextFirstLine =
							intl.formatMessage({
								id             : 'paidservice.monthsAfterLogin',
								defaultMessage : '{value} months after entering the code'
							}, {
								value : expiryTermAfterUse.value
							});
			}
		}
	}

	if (expiryTerm?.value && expiryTerm.type)
	{
		const afterLoginValueExist = returnTextFirstLine !== '';

		if (afterLoginValueExist)
		{
			returnTextSecondLine += ' (';
			returnTextSecondLine +=
							intl.formatMessage({
								id             : 'paidservice.dueDate',
								defaultMessage : '1 month after entering the code'
							});
		}

		if (expiryTerm?.type === 'day')
		{
			const condValue = Number(expiryTerm.value);

			if (condValue === 1)
			{
				returnTextSecondLine +=
							intl.formatMessage({
								id             : 'paidservice.dayAfterPurchase',
								defaultMessage : '1 day after entering the code'
							});
			}
			else
			{
				returnTextSecondLine +=
							intl.formatMessage({
								id             : 'paidservice.daysAfterPurchase',
								defaultMessage : '{value} days after entering the code'
							}, {
								value : expiryTerm.value
							});
			}
		}
		else if (expiryTerm.type === 'month')
		{
			const condValue = Number(expiryTerm.value);

			if (condValue === 1)
			{
				returnTextSecondLine +=
							intl.formatMessage({
								id             : 'paidservice.monthAfterPurchase',
								defaultMessage : '1 month after entering the code'
							});
			}
			else
			{
				returnTextSecondLine +=
							intl.formatMessage({
								id             : 'paidservice.monthsAfterPurchase',
								defaultMessage : '{value} months after entering the code'
							}, {
								value : expiryTerm.value
							});
			}
		}

		// expiryTermTextが最初の行か２行目かを場合分け
		if (afterLoginValueExist)
		{
			returnTextSecondLine += ')';
		}
	}

	if (returnTextFirstLine && returnTextSecondLine)
	{
		if (singleRow)
		{
			return (<span>{returnTextFirstLine}{returnTextSecondLine}</span>);
		}
		else
		{
			return (<>
				<span>{returnTextFirstLine}</span><br/><span>{returnTextSecondLine}</span>
			</>);
		}
	}
	else if (returnTextFirstLine)
	{
		return (<span>{returnTextFirstLine}</span>);
	}
	else if (returnTextSecondLine)
	{
		return (<span>{returnTextSecondLine}</span>);
	}
	else
	{
		return <React.Fragment />;
	}
};

TicketExpiryText.propTypes =
{
	expiryTerm         : PropTypes.object,
	expiryTermAfterUse : PropTypes.object,
	singleRow          : PropTypes.bool.isRequired
};

export default TicketExpiryText;
