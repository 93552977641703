import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { withRoomContext } from '../../../../RoomContext';

import { Box, Button, Typography } from '@material-ui/core';

// components
import BrokageAccountsDialog from './BrokageAccountsDialog';

const styles = (theme) =>
	({
		root :
		{
			width                          : '100%',
			height                         : '100%',
			maxWidth                       : '980px',
			minWidth                       : '400px',
			position                       : 'relative',
			padding                        : '15px 20px',
			color                          : 'var(--text-color)',
			margin                         : 'auto',
			[theme.breakpoints.down('sm')] : {
				padding : '15px 5px'
			}
		},
		pageHeader : {
			display        : 'flex',
			justifyContent : 'center'
		},
		title : {
			margin     : '25px 0',
			fontSize   : '1.2rem',
			fontWeight : 'bold',
			color      : '#292929'
		},
		columnsBox : {
			width  : '100%',
			margin : '30px 0 0 0'
		},
		columnTitleBox : {
			display        : 'flex',
			justifyContent : 'start'
		},
		columnTitleBoxBetween : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'space-between'
		},
		showAllButton : {
			marginTop       : '4px',
			border          : '1px solid #292929',
			backgroundColor : '#FFF',
			color           : '#292929',
			padding         : '2px 8px',
			height          : '28px',
			marginLeft      : '25px',
			corsor          : 'pointer'
		},
		columnTitle : {
			fontSize     : '1rem',
			fontWeight   : 'bold',
			marginBottom : '15px',
			paddingLeft  : '20px',
			height       : '35px',
			lineHeight   : '35px',
			borderLeft   : '6px solid #484A46',
			color        : '#292929'
		},
		columnItem : {
			width          : '100%',
			borderBottom   : '1px solid #CCC',
			display        : 'flex',
			justifyContent : 'space-between',
			alignItems     : 'end',
			padding        : '10px 0 1px 0'
		},
		firstColumnItem : {
			borderTop : '1px solid #CCC'
		},
		columnItemLeft : {
			width       : '38%',
			color       : '#292929',
			paddingLeft : '26px'
		},
		columnItemRight : {
			width : '72%',
			color : '#292929'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	}
});

const ServiceAccount = ({
	classes,
	serviceAccount
}) =>
{
	const [ showBrokageAccountsDialog, setShowBrokageAccountsDialog ] = useState(false);

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>

				< BrokageAccountsDialog
					show={showBrokageAccountsDialog}
					closeMethod={() => setShowBrokageAccountsDialog(false)}
					paramKey={serviceAccount.paramKey}
				/>

				<Box className={classes.pageHeader}>
					<Typography className={classes.title}>
						アカウント情報
					</Typography>
				</Box>

				<Box className={classes.columnsBox}>
					<Typography className={classes.columnTitle}>基本情報</Typography>

					<Box className={`${classes.columnItem} ${classes.firstColumnItem}`}>
						<Box className={classes.columnItemLeft}>
							会社名/屋号
						</Box>
						<Box className={classes.columnItemRight}>
							{serviceAccount.accountName}
						</Box>
					</Box>
					<Box className={classes.columnItem}>
						<Box className={classes.columnItemLeft}>
							住所
						</Box>
						<Box className={classes.columnItemRight}>
							{serviceAccount.address}
						</Box>
					</Box>
				</Box>
				<Box className={classes.columnsBox}>
					<Typography className={classes.columnTitle}>代表者情報</Typography>
					<Box className={`${classes.columnItem} ${classes.firstColumnItem}`}>
						<Box className={classes.columnItemLeft}>
							氏名
						</Box>
						<Box className={classes.columnItemRight}>
							{serviceAccount.contactPerson}
						</Box>
					</Box>
					<Box className={classes.columnItem}>
						<Box className={classes.columnItemLeft}>
							メールアドレス
						</Box>
						<Box className={classes.columnItemRight}>
							{serviceAccount.email}
						</Box>
					</Box>
					<Box className={classes.columnItem}>
						<Box className={classes.columnItemLeft}>
							携帯電話番号
						</Box>
						<Box className={classes.columnItemRight}>
							{serviceAccount.mobilePhoneNumber}
						</Box>
					</Box>
					<Box className={classes.columnItem}>
						<Box className={classes.columnItemLeft}>
							電話番号
						</Box>
						<Box className={classes.columnItemRight}>
							{serviceAccount.phoneNumber}
						</Box>
					</Box>
				</Box>
				<Box className={classes.columnsBox}>
					<Typography className={classes.columnTitle}>契約情報</Typography>
					<Box className={`${classes.columnItem} ${classes.firstColumnItem}`}>
						<Box className={classes.columnItemLeft}>
							手数料率
						</Box>
						<Box className={classes.columnItemRight}>
							{serviceAccount.commissionPercentage}%
						</Box>
					</Box>
				</Box>
				<Box className={classes.columnsBox}>
					<Box className={classes.columnTitleBoxBetween}>
						<Typography className={classes.columnTitle}>仲介企業</Typography>
						{ serviceAccount.brokageAccountsCount ?
							<Button
								className={classes.showAllButton}
								onClick={() => setShowBrokageAccountsDialog(true)}
							>
								一覧
							</Button>
							: null
						}
					</Box>

					<Box className={`${classes.columnItem} ${classes.firstColumnItem}`}>
						<Box className={classes.columnItemLeft}>
							仲介企業
						</Box>
						<Box className={classes.columnItemRight}>
							{serviceAccount.brokageAccountsCount
								? serviceAccount.brokageAccountsCount
								: 0
							}社
						</Box>
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>
	);

};

ServiceAccount.propTypes =
{
	classes        : PropTypes.object.isRequired,
	serviceAccount : PropTypes.object
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceAccount)));