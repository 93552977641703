import React from 'react';
import PropTypes from 'prop-types';

import GuestCall from '../../../images/guest_talk/guest_call.svg';
import GuestCallActive from '../../../images/guest_talk/guest_call_active.svg';
import GuestCamera from '../../../images/guest_talk/guest_camera.svg';
import GuestCameraChange from '../../../images/guest_talk/guest_cameraChange.svg';
import GuestCameraActive from '../../../images/guest_talk/guest_camera_active.svg';
import GuestChat from '../../../images/guest_talk/guest_chat.svg';
import GuestChatGuest from '../../../images/guest_talk/guest_chatGuest.svg';
import GuestChatHost from '../../../images/guest_talk/guest_chatHost.svg';
import GuestChatActive from '../../../images/guest_talk/guest_chat_active.svg';
import GuestFileShare from '../../../images/guest_talk/guest_fileShare.svg';
import GuestFileShareActive from '../../../images/guest_talk/guest_fileShare_active.svg';
import GuestMic from '../../../images/guest_talk/guest_mic.svg';
import GuestMicActive from '../../../images/guest_talk/guest_mic_active.svg';
import GuestMute from '../../../images/guest_talk/guest_mute.svg';
import GuestMuteActive from '../../../images/guest_talk/guest_mute_active.svg';
import GuestScreenChange from '../../../images/guest_talk/guest_screenChange.svg';
import GuestSendMessage from '../../../images/guest_talk/guest_sendMessage.svg';
import GuestSettings from '../../../images/guest_talk/guest_settings.svg';
import GuestSettingsActive from '../../../images/guest_talk/guest_settings_active.svg';

import HostAbsent from '../../../images/host_etc/host_absent.svg';
import HostAlert from '../../../images/host_etc/host_alert.svg';
import HostFlag from '../../../images/host_etc/host_flag.svg';
import HostFlagActive from '../../../images/host_etc/host_flag_active.svg';
import HostGroupTalk from '../../../images/host_etc/host_groupTalk.svg';
import HostManagerLogin from '../../../images/host_etc/host_managerLogin.svg';
import HostManagerId from '../../../images/host_etc/host_managerId.svg';
import HostManagerPassword from '../../../images/host_etc/host_managerPassword.svg';
import HostNotice from '../../../images/host_etc/host_notice.svg';
import HostSearch from '../../../images/host_etc/host_search.svg';
import HostSearchResult from '../../../images/host_etc/host_searchResult.svg';
import HostStatus from '../../../images/host_etc/host_status.svg';
import HostOperator from '../../../images/host_etc/host_operator.svg';
import HostTime from '../../../images/host_etc/host_time.svg';
import HostVideoBackground from '../../../images/host_etc/host_background.svg';

import HostNaviAbsent from '../../../images/host_navi/host_naviAbsent.svg';
import HostNaviHome from '../../../images/host_navi/host_naviHome.svg';
import HostNaviHomeActive from '../../../images/host_navi/host_naviHome_active.svg';
import HostNaviLogout from '../../../images/host_navi/host_naviLogout.svg';
import HostNaviMemo from '../../../images/host_navi/host_naviMemo.svg';
import HostNaviMemoActive from '../../../images/host_navi/host_naviMemo_active.svg';
import HostNaviSetings from '../../../images/host_navi/host_naviSetings.svg';
import HostNaviSettingsActive from '../../../images/host_navi/host_naviSettings_active.svg';
import HostNaviSettingsDisable from '../../../images/host_navi/host_naviSettings_disable.svg';
import HostNaviTalking from '../../../images/host_navi/host_naviTalking.svg';
import HostNaviTalkingActive from '../../../images/host_navi/host_naviTalking_active.svg';
import HostNaviTeam from '../../../images/host_navi/host_naviTeam.svg';
import HostNaviTeamActive from '../../../images/host_navi/host_naviTeam_active.svg';
import HostNaviTeamDisable from '../../../images/host_navi/host_naviTeam_disable.svg';
import HostNaviTodo from '../../../images/host_navi/host_naviTodo.svg';
import HostNaviTodoActive from '../../../images/host_navi/host_naviTodo_active.svg';
import HostNaviPersonalLink from '../../../images/host_navi/host_naviPersonaLlink.svg';

import HostBtn from '../../../images/host_talk/host_btn.svg';
import HostBtnActive from '../../../images/host_talk/host_btn_active.svg';
import HostChat from '../../../images/host_talk/host_chat.svg';
import HostChatActive from '../../../images/host_talk/host_chat_active.svg';
import HostSv from '../../../images/host_talk/host_sv.svg';
import HostSvOff from '../../../images/host_talk/host_svOff.svg';
import HostFileShare from '../../../images/host_talk/host_fileShare.svg';
import HostFileShareActive from '../../../images/host_talk/host_fileShare_active.svg';
import HostHold from '../../../images/host_talk/host_hold.svg';
import HostHoldActive from '../../../images/host_talk/host_hold_active.svg';
import HostHungUp from '../../../images/host_talk/host_hungUp.svg';
import HostMemo from '../../../images/host_talk/host_memo.svg';
import HostMemoActive from '../../../images/host_talk/host_memo_active.svg';
import HostMute from '../../../images/host_talk/host_mute.svg';
import HostMuteActive from '../../../images/host_talk/host_mute_active.svg';
import HostReconnectCamera from '../../../images/host_talk/host_reconnect_camera.svg';
import HostReconnectMic from '../../../images/host_talk/host_reconnect_mic.svg';
import HostScreenShare from '../../../images/host_talk/host_screenShare.svg';
import HostScreenShareActive from '../../../images/host_talk/host_screenShare_active.svg';
import HostGroupchatMe from '../../../images/host_talk/host_groupchat_me.svg';
import HostGroupchatPeer from '../../../images/host_talk/host_groupchat_peer.svg';

import HostBtnGreen from '../../../images/host_waiting/host_btnGreen.svg';
import HostBtnWhite from '../../../images/host_waiting/host_btnWhite.svg';
import HostCamera from '../../../images/host_waiting/host_camera.svg';
import HostCameraOff from '../../../images/host_waiting/host_cameraOff.svg';
import HostCameraWhite from '../../../images/host_waiting/host_cameraWhite.svg';
import HostMic from '../../../images/host_waiting/host_mic.svg';
import HostSpeaker from '../../../images/host_waiting/host_speaker.svg';

import MonitoringChange from '../../../images/monitoring_chat/change.svg';
import MonitoringDifficult from '../../../images/monitoring_chat/difficult.svg';
import MonitoringFine from '../../../images/monitoring_chat/fine.svg';
import MonitoringHelp from '../../../images/monitoring_chat/help.svg';
import MonitoringOk from '../../../images/monitoring_chat/ok.svg';
import MonitoringSorry from '../../../images/monitoring_chat/sorry.svg';
import MonitoringThanks from '../../../images/monitoring_chat/thanks.svg';
import MonitoringTough from '../../../images/monitoring_chat/tough.svg';

const Icons = ({ category, type, iconName, size, display, iconHeight, iconWidth }) =>
{
	const iconList = {
		guest : {
			call : {
				normal  : GuestCall,
				active  : GuestCallActive,
				disable : GuestCall
			},
			camera : {
				normal  : GuestCamera,
				active  : GuestCameraActive,
				disable : GuestCamera
			},
			cameraChange : {
				normal  : GuestCameraChange,
				active  : GuestCameraChange,
				disable : GuestCameraChange
			},
			chat : {
				normal  : GuestChat,
				active  : GuestChatActive,
				disable	: GuestChat
			},
			chatGuest : {
				normal  : GuestChatGuest,
				active  : GuestChatGuest,
				disable	: GuestChatGuest
			},
			chatHost : {
				normal  : GuestChatHost,
				active  : GuestChatHost,
				disable	: GuestChatHost
			},
			fileShare : {
				normal  : GuestFileShare,
				active  : GuestFileShareActive,
				disable	: GuestFileShare
			},
			mic : {
				normal  : GuestMic,
				active  : GuestMicActive,
				disable	: GuestMic
			},
			mute : {
				normal  : GuestMute,
				active  : GuestMuteActive,
				disable	: GuestMute
			},
			screenChange : {
				normal  : GuestScreenChange,
				active  : GuestScreenChange,
				disable	: GuestScreenChange
			},
			sendMessage : {
				normal  : GuestSendMessage,
				active  : GuestSendMessage,
				disable	: GuestSendMessage
			},
			settings : {
				normal  : GuestSettings,
				active  : GuestSettingsActive,
				disable	: GuestSettings
			}
		},
		host : {
			absent : {
				normal  : HostAbsent,
				active  : HostAbsent,
				disable	: HostAbsent
			},
			alet : {
				normal  : HostAlert,
				active  : HostAlert,
				disable	: HostAlert
			},
			flag : {
				normal  : HostFlag,
				active  : HostFlagActive,
				disable	: HostFlag
			},
			status : {
				normal  : HostStatus,
				active  : HostStatus,
				disable : HostStatus
			},
			groupTalk : {
				normal  : HostGroupTalk,
				active  : HostGroupTalk,
				disable	: HostGroupTalk
			},
			managerLogin : {
				normal  : HostManagerLogin,
				active  : HostManagerLogin,
				disable	: HostManagerLogin
			},
			managerId : {
				normal  : HostManagerId,
				active  : HostManagerId,
				disable	: HostManagerId
			},
			managerPassword : {
				normal  : HostManagerPassword,
				active  : HostManagerPassword,
				disable	: HostManagerPassword
			},
			notice : {
				normal  : HostNotice,
				active  : HostNotice,
				disable	: HostNotice
			},
			search : {
				normal  : HostSearch,
				active  : HostSearchResult,
				disable	: HostSearch
			},
			naviAbsent : {
				normal  : HostNaviAbsent,
				active  : HostNaviAbsent,
				disable	: HostNaviAbsent
			},
			naviHome : {
				normal  : HostNaviHome,
				active  : HostNaviHomeActive,
				disable	: HostNaviHome
			},
			naviLogout : {
				normal  : HostNaviLogout,
				active  : HostNaviLogout,
				disable	: HostNaviLogout
			},
			naviMemo : {
				normal  : HostNaviMemo,
				active  : HostNaviMemoActive,
				disable	: HostNaviMemo
			},
			naviSettings : {
				normal  : HostNaviSetings,
				active  : HostNaviSettingsActive,
				disable	: HostNaviSettingsDisable
			},
			naviTalking : {
				normal  : HostNaviTalking,
				active  : HostNaviTalkingActive,
				disable	: HostNaviTalking
			},
			naviTeam : {
				normal  : HostNaviTeam,
				active  : HostNaviTeamActive,
				disable	: HostNaviTeamDisable
			},
			naviTodo : {
				normal  : HostNaviTodo,
				active  : HostNaviTodoActive,
				disable	: HostNaviTodo
			},
			naviPersonalLink : {
				normal  : HostNaviPersonalLink,
				active  : HostNaviPersonalLink,
				disable : HostNaviPersonalLink
			},
			btn : {
				normal  : HostBtn,
				active  : HostBtnActive,
				disable	: HostBtn
			},
			chat : {
				normal  : HostChat,
				active  : HostChatActive,
				disable	: HostChat
			},
			fileShare : {
				normal  : HostFileShare,
				active  : HostFileShareActive,
				disable	: HostFileShare
			},
			hold : {
				normal  : HostHold,
				active  : HostHoldActive,
				disable	: HostHold
			},
			hungUp : {
				normal  : HostHungUp,
				active  : HostHungUp,
				disable	: HostHungUp
			},
			memo : {
				normal  : HostMemo,
				active  : HostMemoActive,
				disable	: HostMemo
			},
			mute : {
				normal  : HostMute,
				active  : HostMuteActive,
				disable	: HostMute
			},
			reconnectMic : {
				normal  : HostReconnectMic,
				active  : HostReconnectMic,
				disable : HostReconnectMic
			},
			reconnectCamera : {
				normal  : HostReconnectCamera,
				active  : HostReconnectCamera,
				disable : HostReconnectCamera
			},
			screenShare : {
				normal  : HostScreenShare,
				active  : HostScreenShareActive,
				disable	: HostScreenShare
			},
			btnGreen : {
				normal  : HostBtnGreen,
				active  : HostBtnGreen,
				disable	: HostBtnGreen
			},
			btnWhite : {
				normal  : HostBtnWhite,
				active  : HostBtnWhite,
				disable	: HostBtnWhite
			},
			camera : {
				normal  : HostCamera,
				active  : HostCameraOff,
				disable	: HostCameraWhite
			},
			mic : {
				normal  : HostMic,
				active  : HostMic,
				disable	: HostMic
			},
			speaker : {
				normal  : HostSpeaker,
				active  : HostSpeaker,
				disable	: HostSpeaker
			},
			groupChatMe : {
				normal  : HostGroupchatMe,
				active  : HostGroupchatMe,
				disable : HostGroupchatMe
			},
			groupChatPeer : {
				normal  : HostGroupchatPeer,
				active  : HostGroupchatPeer,
				disable : HostGroupchatPeer
			},
			sv : {
				normal  : HostSv,
				active  : HostSv,
				disable : HostSvOff
			},
			operator : {
				normal  : HostOperator,
				active  : HostOperator,
				disable : HostOperator
			},
			time : {
				normal  : HostTime,
				active  : HostTime,
				disable : HostTime
			},
			videoBackground : {
				normal  : HostVideoBackground,
				active  : HostVideoBackground,
				disable : HostVideoBackground
			}
		},
		monitoringChat : {
			change : {
				normal  : MonitoringChange,
				active  : MonitoringChange,
				disable : MonitoringChange
			},
			difficult : {
				normal  : MonitoringDifficult,
				active  : MonitoringDifficult,
				disable : MonitoringDifficult
			},
			fine : {
				normal  : MonitoringFine,
				active  : MonitoringFine,
				disable : MonitoringFine
			},
			help : {
				normal  : MonitoringHelp,
				active  : MonitoringHelp,
				disable : MonitoringHelp
			},
			ok : {
				normal  : MonitoringOk,
				active  : MonitoringOk,
				disable : MonitoringOk
			},
			sorry : {
				normal  : MonitoringSorry,
				active  : MonitoringSorry,
				disable : MonitoringSorry
			},
			thanks : {
				normal  : MonitoringThanks,
				active  : MonitoringThanks,
				disable : MonitoringThanks
			},
			tough : {
				normal  : MonitoringTough,
				active  : MonitoringTough,
				disable : MonitoringTough
			}
		}
	};

	const styleVal = {
		width        : size,
		marginBottom : 0,
		aspectRatio  : 1
	};

	if (display)
	{
		styleVal['display'] = display;
	}

	return (
		<div style={styleVal}>
			<img
				src={iconList[category][iconName][type]}
				alt={iconName}
				height={iconHeight ? iconHeight : ''}
				width={iconWidth ? iconWidth : ''}
			/>
		</div>
	);
};

Icons.propTypes =
{
	category   : PropTypes.string.isRequired,
	type       : PropTypes.string.isRequired,
	iconName   : PropTypes.string.isRequired,
	size       : PropTypes.string,
	display    : PropTypes.string,
	iconHeight : PropTypes.string,
	iconWidth  : PropTypes.string
};

Icons.defaultProps = {
	category : 'host',
	type     : 'active',
	size     : '20px'
};

export default Icons;