import { combineReducers } from 'redux';
import room from './room';
import me from './me';
import producers from './producers';
import peers from './peers';
import lobbyPeers from './lobbyPeers';
import consumers from './consumers';
import peerVolumes from './peerVolumes';
import notifications from './notifications';
import chat from './chat';
import monitoringChat from './monitoringChat';
import toolarea from './toolarea';
import files from './files';
import settings from './settings';
import transports from './transports';
import intl from './intl';
import bottomNavigation from './bottomNavigation';
import admin from './admin';
import recorder from './recorder';
import supportCall from './supportCall';
// import { intlReducer } from 'react-intl-redux';

export default combineReducers({
	room,
	me,
	producers,
	consumers,
	transports,
	peers,
	lobbyPeers,
	peerVolumes,
	notifications,
	chat,
	monitoringChat,
	toolarea,
	files,
	settings,
	// intl : intlReducer
	intl,
	bottomNavigation,
	admin,
	recorder,
	supportCall
});
