export const setNaviTab = (naviTab) =>
	({
		type    : 'SET_NAVI_TAB',
		payload : { naviTab }
	});

export const setChatEnabled = (chatEnabled) =>
	({
		type    : 'SET_CHAT_ENABLED',
		payload : { chatEnabled }
	});

export const setGuestNaviTab = (guestNaviTab) =>
	({
		type    : 'SET_GUEST_NAVI_TAB',
		payload : { guestNaviTab }
	});