// React
import React, { useState, useEffect } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../../RoomContext';

// Router
import { useHistory, useLocation } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage, useIntl } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

// Firestore
import { functions } from '../../../../lib/firebase';

// util
import { getDomainWithoutSubdomain, parseUrlParams } from '../../../../utils';

// const 
import { DEFAULT_SERVICE_ACCOUNT_KEY } from '../../../../const';

// components
import ServiceAppBar from '../ServiceCall/Parts/ServiceAppBar';

const styles = (theme) =>
	({
		root : {
			zIndex          : 99,
			width           : '100%',
			height          : '100%',
			overflow        : 'auto',
			backgroundColor : '#EBEBEC'
		},
		content : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		contentArea : {
			width           : 'calc( 100% - 20px )',
			maxWidth        : '750px',
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'flex-start',
			alignItems      : 'center',
			margin          : '30px 15px',
			padding         : '30px 0px',
			minWidth        : '320px',
			backgroundColor : '#FFF',
			borderRadius    : '20px'
		},
		pageContent : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		titleSection : {
			margin         : '20px 0 30px 0',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		title : {
			fontSize : '1.1rem'
		},
		messageAreaMarginBoth : {
			margin : '10px 0'
		},
		messegeText : {
			fontSize                     : '0.8rem',
			color                        : 'var(--text-color)',
			[theme.breakpoints.up('sm')] :
			{
				maxWidth : '100%'
			}
		},
		contentWrapper : {
			width          : '100%',
			maxWidth       : '500px',
			marginTop      : '25px',
			display        : 'flex',
			justifyContent : 'center'
		},
		freeCondText : {
			color           : '#292929',
			fontSize        : '1.1rem',
			padding         : '5px 15px',
			backgroundColor : '#F4DA23',
			borderRadius    : '3px'
		},
		inputLabel : {
			width                        : '100%',
			fontSize                     : '0.8rem',
			paddingBottom                : '0px',
			[theme.breakpoints.up('sm')] :
			{
				width         : '40%',
				minWidth      : '150px',
				fontSize      : '0.8rem',
				height        : '37px',
				lineHeight    : '37px',
				verticalAlign : 'middle'
			}
		},
		inputLine : {
			width                        : '95%',
			display                      : 'block',
			marginBottom                 : '3px',
			[theme.breakpoints.up('sm')] :
			{
				display        : 'flex',
				justifyContent : 'space-between',
				marginTop      : '10px'
			}
		},
		inputText : {
			background   : '#FFF',
			padding      : '0.6rem 0.6rem',
			borderRadius : '0.2rem',
			color        : '#292929',
			textAlign    : 'left',
			border       : '1px solid #9E9E9E',
			fontSize     : '16px',
			'&:focus'    : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#E8E8E8',
				color           : 'var(--text-color)'
			},
			'&::-ms-reveal' : {
				display : 'none'
			}
		},
		inputTextTicket : {
			width                        : '100%',
			[theme.breakpoints.up('sm')] :
			{
				width    : '55%',
				maxWidth : '250px'
			}
		},
		buttonSection : {
			width          : '100%',
			maxWidth       : '500px',
			marginTop      : '30px',
			display        : 'flex',
			padding        : '0 3px',
			justifyContent : 'center'
		},
		servicePageButtonSection :
		{
			width          : '100%',
			maxWidth       : '500px',
			marginTop      : '55px',
			display        : 'flex',
			padding        : '0 3px',
			justifyContent : 'center'
		},
		button : {
			padding      : '4px 8px',
			width        : '40%',
			maxWidth     : '300px',
			borderRadius : '10px',
			fontSize     : '0.9rem'
		},
		backButton : {
			backgroundColor : 'white',
			color           : '#cd2c82',
			border          : '1px solid #cd2c82',
			'&:hover'       : {
				color  : '#D9388E',
				border : '1px solid #D9388E'
			}
		},
		servicePageButton : {
			padding         : '6px 10px',
			maxWidth        : '300px',
			borderRadius    : '10px',
			fontSize        : '1.1rem',
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : '#292929',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#4B4B4B'
			}
		},
		proceedButton : {
			backgroundColor : '#cd2c82',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#D9388E'
			}
		},
		movePageSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0',
			width          : '100%'
		},
		loadingWrapper :
		{
			width           : '100%',
			height          : 'calc( 100% - 40px )',
			display         : 'flex',
			paddingTop      : '250px',
			justifyContent  : 'center',
			alignItems      : 'flex-start',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		},
		loadingIndicator :
		{
			color : 'var(--text-color)'
		},
		errorMsg : {
			width        : '100%',
			color        : 'red',
			fontSize     : '0.8rem',
			textAlign    : 'center',
			marginBottom : '10px'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides :
	{
		MuiOutlinedInput : {
			input : {
				'&::-ms-reveal' : {
					display : 'none'
				}
			}
		},
		PrivateSwitchBase : {
			root : {
				padding : '1px'
			}
		}
	}
});

const convertNumber = (text) =>
{

	const numbersToReplace = '０１２３４５６７８９';
	const reFullnums = new RegExp(`[${numbersToReplace}]`, 'g');

	return text.replace(reFullnums, (m) => numbersToReplace.indexOf(m));
};

const ServiceResendTicket = ({
	classes
}) =>
{
	const intl = useIntl();

	const history = useHistory();

	const location = useLocation();

	const [ serviceParamKey, setServiceParamKey ] = useState(null);

	const [ page, setPage ] = useState('home');

	const [ loading, setLoading ] = useState(false);

	const [ ticketEmail, setTicketEmail ] = useState('');

	const [ phoneNumber, setPhoneNumber ] = useState('');

	const [ errorMsg, setErrorMsg ] = useState('');

	const updateTicketEmail = (e) =>
	{
		setTicketEmail(e.target.value);
	};

	const updatePhoneNumber = (e) =>
	{
		setPhoneNumber(e.target.value);
	};

	const resendTicketCode = async () =>
	{

		setErrorMsg('');

		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

		if (!ticketEmail)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.notEnteredEmail',
				defaultMessage : 'No email address entered'
			}));

			return;
		}
		else if (!emailPattern.test(ticketEmail))
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.incorrectEmail',
				defaultMessage : 'Incorrect email address'
			}));

			return;
		}

		const phoneNumberReg = /^[0-9]+$/;

		const phoneNumberValue = convertNumber(phoneNumber).replace(/−/g, '')
			.replace(/―/g, '')
			.replace(/-/g, '')
			.replace(/ー/g, '');

		if (!phoneNumber)
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.notEnteredPhone',
				defaultMessage : 'No phone number entered'
			}));

			return;
		}
		else if (!phoneNumberReg.test(phoneNumberValue))
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.incorrectPhone',
				defaultMessage : 'Incorrect phone number'
			}));

			return;
		}

		setLoading(true);

		const serviceResendTicketEmailAPI = functions.httpsCallable('serviceResendTicketEmail');

		try
		{
			const domain = getDomainWithoutSubdomain();

			const response = await serviceResendTicketEmailAPI({
				email       : ticketEmail,
				phoneNumber : phoneNumberValue,
				domain      : domain
			});

			const data = response.data;

			if (data.success)
			{
				setErrorMsg('');
				setPage('complete');
				setLoading(false);
			}
			else
			{
				if (!data.foundItem)
				{
					setErrorMsg('送信できませんでした。入力情報を確認してください。');
				}
				else
				{
					setErrorMsg(`${data.foundItem}件見つかりましたが、${data.expiredItem}件が期限切れ、${data.consumedItem}件が使用済みでした。`);
				}

				setLoading(false);

				return;
			}
		}
		catch
		{
			setErrorMsg(intl.formatMessage({
				id             : 'error.error',
				defaultMessage : 'An error has occurred'
			}));
			setLoading(false);

			return;
		}
	};

	useEffect(() =>
	{
		// index 0 is ? get strings after ?
		const paramsValue = location.search.substring(1);

		let paramsObj = {};

		if (paramsValue)
		{
			paramsObj = parseUrlParams({
				paramsValues : paramsValue,
				keys         : [ 'id' ]
			});
		}

		if (paramsObj['id'])
		{
			setServiceParamKey(paramsObj['id']);
		}
		else
		{
			setServiceParamKey(null);
		}

	// eslint-disable-next-line
	}, [ location.search ]);

	const backToHome = () =>
	{
		setTicketEmail('');
		setPhoneNumber('');
		setPage('home');
	};

	const backToServicePage = () =>
	{
		const serviceParamKeyValue = serviceParamKey
			? serviceParamKey
			: DEFAULT_SERVICE_ACCOUNT_KEY;

		history.push({
			pathname : '/service',
			search   : `?id=${serviceParamKeyValue}`,
			state    : {
				prevPage : 'purchase'
			}
		});
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.root}>
				<ServiceAppBar
					free={false}
					appBarHeight={40}
				/>
				{ loading ?
					<Box className={classes.loadingWrapper}>
						<CircularProgress className={classes.loadingIndicator}/>
					</Box>
					:
					<Box className={classes.content}>
						<Box className={classes.contentArea}>
							{errorMsg &&
							<p className={classes.errorMsg}>{errorMsg}</p>
							}
							{ page === 'home' ?
								<Box className={classes.pageContent}>
									<Box className={classes.titleSection}>
										<Typography className={classes.title}>
											<FormattedMessage
												id='paidservice5.resendTicket'
												defaultMessage='Resend Ticket Code'
											/>
										</Typography>
									</Box>

									<Box className={classes.contentWrapper}>
										<Box className={classes.inputLine}>
											<Typography className={classes.inputLabel}>
												<FormattedMessage
													id='service.email'
													defaultMessage='Email'
												/>
											</Typography>
											<input
												value={ticketEmail}
												className={`${classes.inputText} ${classes.inputTextTicket}`}
												onChange={(e) => updateTicketEmail(e)}
												type='email'
											/>
										</Box>
									</Box>

									<Box className={classes.contentWrapper}>
										<Box className={classes.inputLine}>
											<Typography className={classes.inputLabel}>
												<FormattedMessage
													id='paidservice.phoneNumber'
													defaultMessage='Phone Number'
												/>
											</Typography>
											<input
												value={phoneNumber}
												className={`${classes.inputText} ${classes.inputTextTicket}`}
												onChange={(e) => updatePhoneNumber(e)}
												type='tel'
											/>
										</Box>
									</Box>

									<Box className={classes.buttonSection}>
										<Button
											className={`${classes.button} ${classes.proceedButton}`}
											onClick={resendTicketCode}
										>
											<FormattedMessage
												id='paidservice.nextButton'
												defaultMessage='FORWARD'
											/>
										</Button>
									</Box>
								</Box>
								:
								<Box className={classes.pageContent}>
									<Box className={classes.titleSection}>
										<Typography className={classes.title}>
											<FormattedMessage
												id='paidservice5.resending'
												defaultMessage='Retransmission completed'
											/>
										</Typography>
									</Box>
									<Box className={classes.messageAreaMarginBoth}>
										<Typography className={classes.messegeText}>
											<FormattedMessage
												id='paidservice5.resendingDescription1'
												defaultMessage='A ticket code has been sent to'
											/>
											<FormattedMessage
												id='paidservice5.resendingDescription2'
												defaultMessage=' {email}'
												values={{
													email : ticketEmail
												}}
											/>
										</Typography>
									</Box>
									<Box className={classes.buttonSection}>
										<Button
											className={`${classes.button} ${classes.backButton}`}
											onClick={backToHome}
										>
											<FormattedMessage
												id='paidservice.backButton'
												defaultMessage='BACKWARD'
											/>
										</Button>
									</Box>

									<Box className={classes.servicePageButtonSection}>
										<Button
											className={classes.servicePageButton}
											onClick={backToServicePage}
										>
											<FormattedMessage
												id='service.startUseingButton'
												defaultMessage='Click here to use the interpretation service'
											/>
										</Button>
									</Box>
								</Box>
							}
						</Box>
					</Box>
				}
			</Box>
		</MuiThemeProvider>
	);
};

ServiceResendTicket.propTypes =
{
	classes : PropTypes.object.isRequired
};

const mapStateToProps = () =>
{
	return {};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(ServiceResendTicket)));