// React
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withRoomContext } from '../../../../RoomContext';

import { firestore, serverTimestamp } from '../../../../lib/firebase';

// Daysjs
import dayjs from 'dayjs';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = (theme) =>
	({
		root : {
			width          : '100%',
			height         : 'calc(100% - 7.5rem)',
			display        : 'flex',
			justifyContent : 'center'
		},
		paper : {
			width                       : '100%',
			height                      : '100%',
			borderRadius                : '0',
			background                  : 'transparent',
			marginBottom                : theme.spacing(2),
			color                       : 'var(--text-color)',
			'& .MuiTableContainer-root' :
			{
				height : 'calc(100% - 52px)'
			}
		},
		tableContainer : {
			background   : 'white',
			borderRadius : '0.5rem',
			paddingTop   : '1rem',
			fontWeight   : '300',
			color        : 'var(--text-color)'
		},
		table : {
			minWidth                                : 750,
			'& .MuiTableCell-root:not(:last-child)' : {
				borderBottom : 'none',
				borderRight  : '1px solid var(--text-color)',
				fontWeight   : '300',
				fontFamily   : '"M PLUS Rounded 1c", sans-serif',
				color        : 'var(--text-color)'
			},
			'& .MuiTableCell-root:last-child' : {
				borderBottom : 'none',
				fontWeight   : '300',
				fontFamily   : '"M PLUS Rounded 1c", sans-serif',
				color        : 'var(--text-color)'
			}
		},
		tableRow : {
			'&:nth-of-type(odd)' : {
				backgroundColor : theme.palette.action.hover
			}
		},
		tableCell : {
			border : 'none !important'
		},
		noItem : {
			color        : 'var(--text-color)',
			fontSize     : '1.4rem',
			width        : '100%',
			textAlign    : 'center',
			marginTop    : '15%',
			marginBottom : '15%'
		},
		editContent : {
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			width          : '100%',
			padding        : '10px',
			maxWidth       : '600px'
		},
		inputLabel : {
			width : '100%'
		},
		label : {
			fontSize : '0.9rem',
			color    : 'var(--text-color)'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '100%',
			maxWidth        : '700px',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#F6F6F6'
			}
		},
		createBtnArea : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end',
			marginBottom   : '5px'
		},
		createBtn : {
			color      : 'white',
			background : '#8eba14',
			border     : '1px solid #8eba14',
			padding    : '5px 9px',
			'&:hover'  : {
				background : '#8eba14',
				filter     : 'saturate(105%) brightness(110%)'
			}
		},
		editBtn : {
			color      : 'white',
			background : '#8eba14',
			border     : '1px solid #8eba14',
			padding    : '2px 5px',
			'&:hover'  : {
				background : '#8eba14',
				filter     : 'saturate(105%) brightness(110%)'
			}
		},
		deleteBtn : {
			marginLeft : '15px',
			color      : 'white',
			background : '#ee0000',
			border     : '1px solid #ee0000',
			padding    : '2px 5px',
			'&:hover'  : {
				background : '#ee0000',
				filter     : 'saturate(105%) brightness(110%)'
			}
		},
		navigateButtons : {
			width          : '100%',
			display        : 'flex',
			justifyContent : 'end'
		},
		saveBtn : {
			color      : 'white',
			background : '#8eba14',
			border     : '1px solid #8eba14',
			padding    : '6px 30px',
			'&:hover'  : {
				background : '#8eba14',
				filter     : 'saturate(105%) brightness(110%)'
			}
		},
		backBtn : {
			color      : '#8eba14',
			background : 'white',
			border     : '1px solid #8eba14',
			padding    : '6px 30px',
			margin     : '0 10px 0 30px',
			'&:hover'  : {
				background : 'white',
				filter     : 'saturate(105%) brightness(110%)'
			}
		},
		inputValue : {
			width        : '100%',
			fontSize     : '1rem',
			padding      : '0 10px',
			marginBottom : '20px'
		},
		errorMsg : {
			width        : '500px',
			color        : 'red',
			marginBottom : '20px',
			fontSize     : '0.8rem',
			textAlign    : 'center'
		},
		loadingIndicator : {
			width          : '100%',
			height         : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		circularProgress : {
			color : 'var(--text-color)'
		}
	});

const ServiceNotifications = ({
	classes,
	editTarget,
	serviceAccountCategory
}) =>
{

	const [ notifications, setNotifications ] = useState([]);

	const [ isLoading, setIsLoading ] = useState(true);

	const [ editItem, setEditItem ] = useState(null);

	const [ editCount, setEditCount ] = useState(0);

	const [ errorMsg, setErrorMsg ] = useState('');

	useEffect(() =>
	{
		if (editTarget === 'serviceAccount' && serviceAccountCategory === 'notifications')
		{
			setIsLoading(true);

			firestore.collection('serviceNotifications')
				.get()
				.then((res) =>
				{
					const notificationsAr = [];

					res.docs.forEach((item) =>
					{

						notificationsAr.push({ id: item.id, ...item.data() });

					});

					notificationsAr.sort((a, b) =>
					{
						if (a.updatedAt < b.updatedAt) { return 1; }
						if (a.updatedAt > b.updatedAt) { return -1; }

						return 0;
					});

					setNotifications([ ...notificationsAr ]);
					setIsLoading(false);
				})
				.catch(() =>
				{
					setErrorMsg('エラーが発生しました');
					setIsLoading(false);
				});

		}

	}, [ editTarget, serviceAccountCategory, editCount ]);

	const handleChangeTitle = (e) =>
	{
		const value = e.target.value;

		setEditItem((state) =>
		{
			return {
				...state,
				title : value
			};
		});
	};

	const handleChangeContent = (e) =>
	{
		const value = e.target.value;

		setEditItem((state) =>
		{
			return {
				...state,
				content : value
			};
		});
	};

	const showNotificationEditView = (id) =>
	{
		const target = notifications.find((item) => item.id === id);

		if (target)
		{
			setEditItem({ ...target });
		}
	};

	const crateNotification = () =>
	{
		setEditItem({
			title   : '',
			content : ''
		});
	};

	const saveNotification = () =>
	{
		if (!editItem.title)
		{
			setErrorMsg('タイトルが入力されていません');
		}
		else if (!editItem.content)
		{
			setErrorMsg('内容が入力されていません');
		}
		else if (editItem.id)
		{
			setIsLoading(true);

			firestore.collection('serviceNotifications')
				.doc(editItem.id)
				.set({
					title     : editItem.title,
					content   : editItem.content,
					updatedAt : serverTimestamp()
				})
				.then(() =>
				{
					setEditCount((state) =>
					{
						return state+1;
					});
					setEditItem(null);
				})
				.catch(() =>
				{
					setErrorMsg('保存中にエラーが発生しました');
					setIsLoading(false);
				});
		}
		else
		{
			setIsLoading(true);

			firestore.collection('serviceNotifications')
				.add({
					title     : editItem.title,
					content   : editItem.content,
					updatedAt : serverTimestamp()
				})
				.then(() =>
				{
					setEditCount((state) =>
					{
						return state+1;
					});
					setEditItem(null);
				})
				.catch(() =>
				{
					setErrorMsg('保存中にエラーが発生しました');
					setIsLoading(false);
				});
		}
	};

	const deleteNotification = (id) =>
	{
		if (id)
		{
			setIsLoading(true);
			firestore.collection('serviceNotifications')
				.doc(id)
				.delete()
				.then(() =>
				{
					setEditCount((state) =>
					{
						return state+1;
					});
					setEditItem(null);
				})
				.catch(() =>
				{
					setErrorMsg('削除にエラーが発生しました');
					setIsLoading(false);
				});
		}
	};

	return (
		<Box className={classes.root}>
			{ isLoading ?
				<Box className={classes.loadingIndicator}>
					<CircularProgress className={classes.circularProgress}/>
				</Box>
				: editItem ?
					<Box className={classes.editContent}>
						{ errorMsg &&
						<Typography className={classes.errorMsg}>
							{errorMsg}
						</Typography>
						}
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>タイトル</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<input
								value={editItem.title}
								type='text'
								onChange={handleChangeTitle}
								className={classes.inputField}
							/>
						</Box>
						<Box className={classes.inputLabel}>
							<Typography className={classes.label}>内容</Typography>
						</Box>
						<Box className={classes.inputValue}>
							<textarea
								value={editItem.content}
								id='content'
								rows='20'
								cols='30'
								onChange={handleChangeContent}
								className={classes.inputField}
							/>
						</Box>
						<Box className={classes.navigateButtons}>
							<Button
								className={classes.backBtn}
								onClick={() => setEditItem(null)}
							>
								戻る
							</Button>

							<Button
								className={classes.saveBtn}
								onClick={saveNotification}
							>
								保存
							</Button>
						</Box>
					</Box>
					:
					<Paper className={classes.paper} elevation={0}>
						<Box className={classes.createBtnArea}>
							<Button
								className={classes.createBtn}
								onClick={crateNotification}
							>
								作成
							</Button>
						</Box>

						<TableContainer className={classes.tableContainer}>
							{ notifications?.length > 0 ?
								<Table
									className={classes.table}
									aria-labelledby='tableTitle'
									size={'medium'}
									aria-label='enhanced table'
								>
									<TableHead>
										<TableRow>
											<TableCell
												align={'center'}
												padding={'normal'}
												className={classes.tableCell}
											>
												タイトル
											</TableCell>
											<TableCell
												align={'center'}
												padding={'normal'}
												className={classes.tableCell}
											>
												操作
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{notifications
											.map((row, index) =>
											{
												return (
													<React.Fragment key={index}>
														<TableRow
															hover
															tabIndex={-1}
															className={classes.tableRow}
														>
															<TableCell align='center' component='th' scope='row' className={classes.tableCell}>
																{row.title}
															</TableCell>
															<TableCell align='center' className={classes.tableCell}>

																<Button
																	onClick={() =>
																		showNotificationEditView(row.id)
																	}
																	className={classes.editBtn}
																	variant='contained'
																	color='primary'
																>
																	編集
																</Button>

																<Button
																	onClick={() =>
																		deleteNotification(row.id)
																	}
																	className={classes.deleteBtn}
																	variant='contained'
																>
																	削除
																</Button>
															</TableCell>
														</TableRow>
													</React.Fragment>
												);
											})}
									</TableBody>
								</Table>
								:
								<Typography className={classes.noItem}>
									表示する通知がありません
								</Typography>
							}
						</TableContainer>
					</Paper>
			}
		</Box>
	);
};

ServiceNotifications.propTypes =
{
	classes                : PropTypes.object.isRequired,
	editTarget             : PropTypes.string.isRequired,
	serviceAccountCategory : PropTypes.string.isRequired
};

const mapStateToProps = () =>
{
	return {
	};
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : () =>
		{
			return;
		}
	}
)(withStyles(styles)(ServiceNotifications)));