export const setSupportCallState = (callState) =>
	({
		type    : 'SET_SUPPORT_CALL_STATE',
		payload : callState
	});

export const setSupportCallInfos = (infos) =>
	({
		type    : 'SET_SUPPORT_CALL_INFOS',
		payload : {
			infos
		}
	});

export const setSupportCallHost = (callHost) =>
	({
		type    : 'SET_SUPPORT_CALL_HOST',
		payload : {
			callHost : callHost
		}
	});

export const setDbData = (dbData) =>
	({
		type    : 'SET_DB_DATA',
		payload : { dbData }
	});