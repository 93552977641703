// React
import React, { useState } from 'react';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../../../RoomContext';

// Router
import { useHistory } from 'react-router-dom';

// Prop types
import PropTypes from 'prop-types';

// Message
import { FormattedMessage, useIntl } from 'react-intl';

// Mui styles
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';

// Mui core
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

// const
import { DEFAULT_SERVICE_ACCOUNT_KEY } from '../../../../../const';

// util
import { getDomainWithoutSubdomain } from '../../../../../utils';

// component
import TicketExpiryText from './TicketExpiryText';

import dayjs from 'dayjs';

// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = (theme) =>
	({
		contentArea : {
			width          : '100%',
			height         : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			margin         : '30px 0'
		},
		pageContent : {
			width          : '100%',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		contentWrapper : {
			marginTop                    : '25px',
			minWidth                     : '300px',
			width                        : '100%',
			[theme.breakpoints.up('sm')] :
			{
				minWidth : '480px'
			}
		},
		titleSection : {
			margin         : '20px 0 30px 0',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'center',
			alignItems     : 'center'
		},
		title : {
			fontSize : '1.1rem'
		},
		ticketCodeSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0',
			width          : '100%'
		},
		ticketBox : {
			padding                      : '4px 6px',
			border                       : '1px solid #292929',
			borderRadius                 : '10px',
			marginBottom                 : '5px',
			[theme.breakpoints.up('sm')] :
			{
				padding : '4px 10px'
			}
		},
		copyCodeBtn : {
			padding         : '1px 10px',
			backgroundColor : '#cd2c82',
			color           : '#FFF',
			cursor          : 'pointer',
			'&:hover'       : {
				backgroundColor : '#cd2c82',
				color           : '#FFF'
			}
		},
		ticketCodeTitle : {
			color      : '#292929',
			fontSize   : '1.1rem',
			fontWeight : 'bold'
		},
		ticketCodeText : {
			color                        : '#cd2c82',
			fontSize                     : '1.6rem',
			fontWeight                   : 'bold',
			[theme.breakpoints.up('sm')] :
			{
				fontSize : '2rem'
			}
		},
		inputCreditCardInfo : {
			width    : '100%',
			minWidth : '300px'
		},
		creditCardInput : {
			marginTop : '15px'
		},
		ticketInfoSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '25px 0',
			width          : '100%'
		},
		ticketInfoRow : {
			display                      : 'flex',
			justifyContent               : 'flex-start',
			width                        : '97%',
			maxWidth                     : '550px',
			[theme.breakpoints.up('sm')] :
			{
				marginLeft : '23%'
			}
		},
		ticketInfoTitle : {
			fontSize   : '0.8rem',
			fontWeight : 'bold',
			minWidth   : '140px',
			width      : '20%'
		},
		ticketInfoDevide : {
			textAlign : 'center',
			fontSize  : '0.8rem',
			width     : '5%'
		},
		ticketInfoValue : {
			fontSize : '0.8rem',
			width    : '65%'
		},
		messageArea : {
			width          : 'calc( 100% - 30px )',
			paddingLeft    : '18px',
			margin         : '20px 0',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center'
		},
		messageText : {
			fontSize : '0.8rem',
			color    : 'var(--text-color)'
		},
		resendLink : {
			cursor         : 'pointer',
			textDecoration : 'underline',
			color          : 'cd2c82 !important'
		},
		centerButton : {
			padding      : '6px 10px',
			maxWidth     : '300px',
			borderRadius : '10px',
			fontSize     : '1.1rem'
		},
		movePageSection : {
			display        : 'flex',
			flexDirection  : 'column',
			alignItems     : 'center',
			justifyContent : 'flex-start',
			margin         : '15px 0',
			width          : '100%'
		},
		movePageButton : {
			boxShadow       : '2px 2px 4px #aaa',
			backgroundColor : '#292929',
			color           : 'white',
			'&:hover'       : {
				backgroundColor : '#4B4B4B'
			}
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	MuiTooltip : {
		tooltip : {
			backgroundColor : 'rgba(226,107,4,0.9)'
		},
		arrow : {
			color : 'rgba(226,107,4,0.9)'
		}
	}
});

// home select input confirm complete

const showExpiryTime = (timestamp) =>
{
	if (timestamp === 0 || timestamp === '0')
	{
		return '';
	}
	else if (timestamp)
	{
		const dayjsTime = dayjs(timestamp);

		return dayjsTime.format('YYYY.MM.DD H:mm');
	}
	else
	{
		return '';
	}
};

const PurchaseComplete = ({
	classes,
	serviceParamKey,
	ticketCode,
	ticketExpiryTimestamp,
	ticketExpiryTerm,
	ticketMinutes,
	ticketEmail,
	free
}) =>
{
	const intl = useIntl();

	const history = useHistory();

	const [ showTooltip, setShowTooltip ] = useState(false);

	const moveToTicketLoginPage = () =>
	{
		const serviceParamKeyValue = serviceParamKey
			? serviceParamKey
			: DEFAULT_SERVICE_ACCOUNT_KEY;

		history.push({
			pathname : '/service',
			search   : `?id=${serviceParamKeyValue}`,
			state    : {
				prevPage : 'purchase'
			}
		});
	};

	const copyTicketCode = () =>
	{
		if (ticketCode)
		{
			navigator.clipboard.writeText(ticketCode);
			setShowTooltip(true);

			setTimeout(() =>
			{
				setShowTooltip(false);
			}, 2000);
		}
	};

	const domain = getDomainWithoutSubdomain();

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={classes.contentArea}>
				<Box className={classes.pageContent}>
					<Box className={classes.contentWrapper}>
						<Box className={classes.titleSection}>
							{ !free &&
							<Typography className={classes.title}>
								<FormattedMessage
									id='paidservice5.transactionSuccessfully'
									defaultMessage='Transaction successfully'
								/>
							</Typography>
							}
							<Typography className={classes.title}>
								<FormattedMessage
									id='service.ticketIssued'
									defaultMessage='Ticket code has been issued'
								/>
							</Typography>

							{ free &&
							<Typography className={classes.messageText}>
								<FormattedMessage
									id='service.ticketIssueDescription1'
									defaultMessage='The ticket code has been sent to {email}.'
									values={{
										email : ticketEmail
									}}
								/>
							</Typography>
							}

						</Box>
						{ ticketCode &&
							<Box className={classes.ticketCodeSection}>
								<Typography className={classes.ticketCodeTitle}>
									<FormattedMessage
										id='service.ticket'
										defaultMessage='Ticket Code'
									/>
								</Typography>
								<Box className={classes.ticketBox}>
									<Typography className={classes.ticketCodeText}>
										{ticketCode}
									</Typography>
								</Box>
								<Tooltip
									title={
										<FormattedMessage
											id='service.copied'
											defaultMessage='Copied'
										/>
									}
									className={classes.toolTip}
									placement={'left'}
									open={showTooltip}
									arrow
								>
									<Button
										className={classes.copyCodeBtn}
										onClick={copyTicketCode}
									>
										<FormattedMessage
											id='service.copyTicketButton'
											defaultMessage='Copy ticket code'
										/>
									</Button>
								</Tooltip>
							</Box>
						}

						<Box className={classes.ticketInfoSection}>

							<Box className={classes.ticketInfoRow}>
								<Typography className={classes.ticketInfoTitle}>
									<FormattedMessage
										id='paidservice5.vaildTime'
										defaultMessage='Available time'
									/>
								</Typography>
								<Typography className={classes.ticketInfoDevide} >:</Typography>
								<Typography className={classes.ticketInfoValue}>
									{ ticketMinutes === -1 ?
										<FormattedMessage
											id='paidservice.unlimited'
											defaultMessage='Unlimitd'
											values={{
												minutesPurchased : ticketMinutes
											}}
										/>
										:
										<FormattedMessage
											id='paidservice5.minute'
											defaultMessage='{minutesPurchased} minutes'
											values={{
												minutesPurchased : ticketMinutes
											}}
										/>
									}
								</Typography>
							</Box>
							{ ticketExpiryTimestamp ?
								<Box className={classes.ticketInfoRow}>
									<Typography className={classes.ticketInfoTitle}>
										<FormattedMessage
											id='paidservice5.period'
											defaultMessage='Expiry date'
										/>
									</Typography>
									<Typography className={classes.ticketInfoDevide} >:</Typography>
									<Typography className={classes.ticketInfoValue}>
										{showExpiryTime(ticketExpiryTimestamp)}
									</Typography>
								</Box>
								: (
									ticketExpiryTerm.afterPurchase?.value
									|| ticketExpiryTerm.afterUse?.value
								) ?
									<Box className={classes.ticketInfoRow}>
										<Typography className={classes.ticketInfoTitle}>
											<FormattedMessage
												id='paidservice5.period'
												defaultMessage='Expiry date'
											/>
										</Typography>
										<Typography className={classes.ticketInfoDevide} >:</Typography>
										<Typography className={classes.ticketInfoValue}>
											<TicketExpiryText
												expiryTerm={ticketExpiryTerm.afterPurchase}
												expiryTermAfterUse={ticketExpiryTerm.afterUse}
												singleRow
											/>
										</Typography>
									</Box>
									: null
							}
						</Box>

						<Box className={classes.messageArea}>
							{ !free &&
							<Typography className={classes.messageText}>
								<FormattedMessage
									id='service.ticketIssueDescription1'
									defaultMessage='The ticket code has been sent to {email}.'
									values={{
										email : ticketEmail
									}}
								/>
							</Typography>
							}
							{ !free &&
							<Typography className={classes.messageText}>
								<span>
									<FormattedMessage
										id='service.ticketIssueDescription2-1'
										defaultMessage="If you don't receive the email, please click "
									/>
								</span>
								<a
									className={classes.resendLink}
									href={serviceParamKey ? `https://${domain}/resend?id=${serviceParamKey}` : `https://${domain}/resend`}
									target='_blank'
									rel='noreferrer'
								>
									<FormattedMessage
										id='service.ticketIssueDescription2-Link'
										defaultMessage='here'
									/>
								</a>
								<span>
									<FormattedMessage
										id='service.ticketIssueDescription2-2'
										defaultMessage=' to resend email.'
									/>
								</span>

							</Typography>
							}
						</Box>

						<Box className={classes.movePageSection}>
							<Button
								className={`${classes.centerButton} ${classes.movePageButton}`}
								onClick={moveToTicketLoginPage}
								color='primary'
							>
								<FormattedMessage
									id='service.startUseingButton'
									defaultMessage='Click here to use the interpretation service'
								/>
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

PurchaseComplete.propTypes =
{
	classes               : PropTypes.object.isRequired,
	serviceParamKey       : PropTypes.string.isRequired,
	ticketCode            : PropTypes.string,
	ticketExpiryTimestamp : PropTypes.number.isRequired,
	ticketExpiryTerm      : PropTypes.object.isRequired,
	ticketMinutes         : PropTypes.number.isRequired,
	ticketEmail           : PropTypes.string,
	free                  : PropTypes.bool
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = () =>
	{
		return {
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = () =>
{
	return {
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{}
)(withStyles(styles)(PurchaseComplete)));