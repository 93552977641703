import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Firebase
import { firestore } from '../../../lib/firebase';

// Mui
import { withStyles } from '@material-ui/core/styles';

// Daysjs
import dayjs from 'dayjs';

// components
import PortalUsageDialog from './PortalUsageDialog';

import PortalUsageDialogSub from './PortalUsageDialogSub';
// dayjsの タイムゾーンの設定
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.tz.setDefault('Asia/Tokyo');

const styles = () =>
	({
		root :
		{
			position       : 'fixed',
			display        : 'flex',
			flexDirection  : 'column',
			justifyContent : 'flex-start',
			alignItems     : 'center',
			padding        : '2px'
		},
		positionSP : {
			right  : '0.5vw',
			bottom : '0.5vh'
		},
		positionPC : {
			right  : '0.75vw',
			bottom : '0.75vh'
		},
		callHistorySection :
		{
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(153, 152, 152, 0.75)',
			padding         : '4px 2px',
			borderRadius    : '2px',
			minWidth        : '136px',
			marginBottom    : '6px',
			cursor          : 'pointer'
		},
		remainingTimeSection :
		{
			display         : 'flex',
			flexDirection   : 'column',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.75)',
			padding         : '4px 2px',
			borderRadius    : '2px',
			minWidth        : '136px'
		},
		itemText :
		{
			fontSize : '0.65rem',
			padding  : '0',
			margin   : '0'
		},
		normalColor :
		{
			color : 'var(--text-color)'
		},
		exceedColor :
		{
			color : '#FF6666'
		},
		callHistoryTextColor :
		{
			color : 'rgba(255, 255, 255, 0.75)'
		}
	});

let talkingTimeListener = null;

const TimeIndicator = ({
	classes,
	portalId,
	talkingMinutesLimit,
	isSP
}) =>
{

	const [ totalTalkingMinutes, setTotalTalkingMinutes ] = useState(0);

	const [ exceedTimeLimit, setExceedTimeLimit ] = useState(false);

	const [ calls, setCalls ] = useState([]);

	const [ portalUsageDialogOpen, setPortalUsageDialogOpen ] = useState(false);

	const [ portalUsageDialogSubOpen, setPortalUsageDialogSubOpen ] = useState(false);

	const [ portalUsageDialogSubState, setPortalUsageDialogSubState ] = useState(
		{ year: null, month: null }
	);

	const closePortalUsageDialogSub = () =>
	{
		setPortalUsageDialogSubOpen(false);
	};

	useEffect(() =>
	{
		if (portalUsageDialogSubOpen === false)
		{
			setPortalUsageDialogSubState({ year: null, month: null });
		}
	}, [ portalUsageDialogSubOpen ]);

	const fetchTalkingTime = useCallback(() =>
	{
		if (portalId)
		{
			const beggingOfMonth = dayjs().startOf('month');

			const oneYearBefore = dayjs().subtract(1, 'year');

			talkingTimeListener = firestore.collection('calls')
				.where('portalId', '==', portalId)
				.where('timestamp', '>=', oneYearBefore.valueOf())
				.onSnapshot((snapshot) =>
				{
					let totalDuration = 0;

					const callsArray = [];

					snapshot.forEach((c) =>
					{
						if (c.exists)
						{
							const callData = c.data();

							if (callData.timestamp >= beggingOfMonth && callData.duration)
							{
								totalDuration += callData.duration;
							}

							callsArray.push({
								id : c.id,
								...c.data()
							});
						}
					});

					setCalls(callsArray);

					const totalMinutes = Math.floor(totalDuration/60);

					setTotalTalkingMinutes(totalMinutes);

					if (totalMinutes >= talkingMinutesLimit)
					{
						setExceedTimeLimit(true);
					}
					else
					{
						setExceedTimeLimit(false);
					}
				});
		}

	}, [ talkingMinutesLimit, portalId ]);

	useEffect(() =>
	{
		if (talkingMinutesLimit)
		{
			fetchTalkingTime();
		}

		return () =>
		{
			if (talkingTimeListener)
			{
				// stop listening
				try
				{
					talkingTimeListener();
				}
				catch
				{
					// do nothing
				}
			}

			talkingTimeListener = null;
		};

	}, [ talkingMinutesLimit, fetchTalkingTime ]);

	return (
		<div className={isSP ? `${classes.root} ${classes.positionSP}` : `${classes.root} ${classes.positionPC}`}>
			<PortalUsageDialog
				portalUsageDialogOpen={portalUsageDialogOpen}
				setPortalUsageDialogOpen={setPortalUsageDialogOpen}
				portalUsageDialogSubState={portalUsageDialogSubState}
				setPortalUsageDialogSubState={setPortalUsageDialogSubState}
				setPortalUsageDialogSubOpen={setPortalUsageDialogSubOpen}
				portalId={portalId}
				calls={calls}
			/>
			<PortalUsageDialogSub
				portalUsageDialogSubOpen={portalUsageDialogSubOpen}
				portalUsageDialogSubState={portalUsageDialogSubState}
				closePortalUsageDialogSub={closePortalUsageDialogSub}
				calls={calls}
			/>

			<div
				className={classes.callHistorySection}
				onClick={() => setPortalUsageDialogOpen(true)}
			>
				<p
					className={`${classes.itemText} ${classes.callHistoryTextColor}`}
				>
					通話履歴確認
				</p>
			</div>

			<div className={classes.remainingTimeSection}>
				<p
					className={exceedTimeLimit ? `${classes.itemText} ${classes.exceedColor}` : `${classes.itemText} ${classes.normalColor}`}
				>
					今月の通話分数 {totalTalkingMinutes}/{talkingMinutesLimit}
				</p>
			</div>

		</div>
	);
};

TimeIndicator.propTypes =
{
	classes             : PropTypes.object.isRequired,
	portalId            : PropTypes.string,
	talkingMinutesLimit : PropTypes.number,
	isSP                : PropTypes.bool
};

export default withStyles(styles)(TimeIndicator);
