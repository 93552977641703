// React
import React, { useCallback } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

// Mui icon
import ImageIcon from '@material-ui/icons/Image';

// Components
import Dropzone from 'react-dropzone';

const styles = () =>
	({
		wrapper : {
			width           : '100%',
			backgroundColor : 'white',
			boxShadow       : '3px 3px 10px #aaa',
			padding         : '0.5% 1%'
		},
		group : {
			marginBottom : '2%'
		},
		pageTitle : {
			color      : 'var(--text-color)',
			fontSize   : '1.2rem',
			lineHeight : '1.4rem'
		},
		smallTitle : {
			color      : 'var(--text-color)',
			fontSize   : '1rem',
			lineHeight : '1.1rem',
			marginTop  : '25px'
		},
		inputField : {
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '20%',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#F6F6F6'
			}
		},
		fullWidth : {
			width : '100%'
		},
		inputList : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 1rem',
			color        : 'var(--text-color)',
			minWidth     : '12rem'
		},
		inputListSmall : {
			border       : '1px solid var(--text-color)',
			borderRadius : '0.3rem',
			padding      : '0.2rem 0.3rem',
			color        : 'var(--text-color)',
			minWidth     : '4rem'
		},
		imageGroup : {
			width   : '100%',
			display : 'flex'
		},
		imageNameGroup : {
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'flex-start'
		},
		imageIcon : {
			color       : 'var(--text-color)',
			marginRight : '0.5rem'
		},
		imageBorder : {
			borderBottom : '1px solid var(--text-color)',
			padding      : '0 0.5rem'
		},
		imageName : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			whiteSpace : 'nowrap'
		},
		imageBox : {
			width          : '100%',
			height         : '10rem',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center',
			padding        : '5% 3%',
			'&> img'       : {
				objectFit : 'contain'
			}
		},
		image : {
			width     : 'auto',
			height    : 'auto',
			display   : 'block',
			maxWidth  : '100%',
			maxHeight : '100%'
		},
		screenSetting :
		{
			width          : '100%',
			display        : 'flex',
			justifyContent : 'center'
		},
		screen : {
			width : '50%'
		},
		buttonAreaInputArea :
		{
			width : '50%'
		},
		gradientInputArea :
		{
			width        : '50%',
			marginBottom : '25px'
		},
		settingLineStart : {
			display        : 'flex',
			justifyContent : 'start'
		},
		waitingScreenSettingLine :
		{
			marginTop : '10px',
			display   : 'flex'
		},
		buttonPosTitle :
		{
			marginTop : '5px'
		},
		buttonPosInput :
		{
			marginRight : '5px'
		},
		inputSpaceRight :
		{
			marginRight : '30px'
		},
		inputFieldBtnPos :
		{
			backgroundColor : 'white',
			border          : '1px solid var(--text-color)',
			borderRadius    : '0.4rem',
			padding         : '0.3rem 1rem',
			boxShadow       : '2px 2px 4px inset #eee',
			color           : 'var(--text-color)',
			fontSize        : '0.9rem',
			width           : '80px',
			'&:focus'       : {
				outline : 'none'
			},
			'&:disabled' : {
				backgroundColor : '#EEE'
			}
		},
		finalItem :
		{
			marginBottom : '2%'
		}

	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiInput : {
			underline : {
				'&:before' : {
					borderBottom : 'none'
				},
				'&:after' : {
					borderBottom : 'none'
				},
				'&:not(.Mui-disabled):hover::before' : {
					borderBottom : 'none'
				},
				'&.Mui-disabled:before' : {
					borderBottomStyle : 'none'
				},
				'&.Mui-disabled' : {
					backgroundColor : '#F6F6F6'
				}
			}
		},
		MuiSelect : {
			select : {
				'&:focus' : {
					borderRadius : '0.4rem'
				}
			}
		},
		MuiTypography : {
			root : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			},
			body1 : {
				color    : 'var(--text-color)',
				fontSize : '0.8rem'
			}
		}
	}
});

const MultiBtnPageSetting = ({
	classes,
	type,
	bgPCGradient,
	setBgPCGradient,
	bgSPGradient,
	setBgSPGradient,
	uploadBgPCFile,
	setUploadBgPCFile,
	uploadBgPCUrl,
	setUploadBgPCUrl,
	uploadBgSPFile,
	setUploadBgSPFile,
	uploadBgSPUrl,
	setUploadBgSPUrl,
	btnAreaPositionObj,
	setBtnAreaPositionObj,
	btnPosDirectionSelector,
	setBtnPosDirectionSelector,
	btnAreaContentObj,
	setBtnAreaContentObj,
	backButtonActive,
	setBackButtonActive,
	backButtonText,
	setBackButtonText,
	backButtonUrl,
	setBackButtonUrl,
	backButtonTextColor,
	setBackButtonTextColor,
	backButtonBGColor,
	setBackButtonBGColor,
	backButtonTextMarginPC,
	setBackButtonTextMarginPC,
	backButtonTextMarginSP,
	setBackButtonTextMarginSP
}) =>
{

	const handleChangeBtnAreaContent = (screen, prop, value, valueType) =>
	{
		setBtnAreaContentObj((state) =>
		{
			const updatedState = { ...state };

			let valueToSave = value;

			if (valueType === 'number')
			{
				valueToSave = Number(value);
			}

			updatedState[screen][prop] = valueToSave;

			return {
				...updatedState
			};
		});
	};

	const handleChangeBtnAreaPositionObj = (screen, prop, value) =>
	{
		setBtnAreaPositionObj((state) =>
		{
			const updatedState = { ...state };

			updatedState[screen][prop] = value;

			return {
				...updatedState
			};
		});
	};

	const onDropBgPC = useCallback((acceptedFiles) =>
	{

		if (acceptedFiles.length > 0)
		{
			const src = URL.createObjectURL(acceptedFiles[0]);

			setUploadBgPCUrl(src);
			setUploadBgPCFile(acceptedFiles[0]);
		}

	}, [ setUploadBgPCFile, setUploadBgPCUrl ]);

	const onDropBgSP = useCallback((acceptedFiles) =>
	{

		if (acceptedFiles.length > 0)
		{

			const src = URL.createObjectURL(acceptedFiles[0]);

			setUploadBgSPUrl(src);
			setUploadBgSPFile(acceptedFiles[0]);
		}

	}, [ setUploadBgSPUrl, setUploadBgSPFile ]);

	const handleChangeBtnPosDirection = (prop, val) =>
	{

		// set value
		setBtnPosDirectionSelector((state) =>
		{
			const stateToUpdate = { ...state };

			stateToUpdate[prop] = val;

			return { ...stateToUpdate };
		});

		if (val === 'center')
		{
			if (prop==='verticalPC')
			{

				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.top = '';
					stateToUpdate.PC.bottom = '';
					stateToUpdate.PC.verticCenter = true;

					return { ...stateToUpdate };
				});
			}
			else if (prop==='horizontalPC')
			{
				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.left = '';
					stateToUpdate.PC.right = '';
					stateToUpdate.PC.horizCenter = true;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.top = '';
					stateToUpdate.SP.bottom = '';
					stateToUpdate.SP.verticCenter = true;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'top')
		{
			if (prop==='verticalPC')
			{

				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.top = '50';
					stateToUpdate.PC.bottom = '';
					stateToUpdate.PC.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.top = '50';
					stateToUpdate.SP.bottom = '';
					stateToUpdate.SP.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'bottom')
		{
			if (prop==='verticalPC')
			{

				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.bottom = '50';
					stateToUpdate.PC.top = '';
					stateToUpdate.PC.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
			else if (prop=== 'verticalSP')
			{
				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.SP.bottom = '50';
					stateToUpdate.SP.top = '';
					stateToUpdate.SP.verticCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'left')
		{
			if (prop==='horizontalPC')
			{

				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.left = '50';
					stateToUpdate.PC.right = '';
					stateToUpdate.PC.horizCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
		else if (val === 'right')
		{
			if (prop==='horizontalPC')
			{

				setBtnAreaPositionObj((state) =>
				{
					const stateToUpdate = { ...state };

					stateToUpdate.PC.right = '50';
					stateToUpdate.PC.left = '';
					stateToUpdate.PC.horizCenter = false;

					return { ...stateToUpdate };
				});
			}
		}
	};

	const handleChangeBackButtonActive = (e) =>
	{
		let value = false;

		if (e.target.value)
		{
			value = true;
		}

		setBackButtonActive(value);
	};

	const handleChangeBackButtonText = (e) =>
	{
		setBackButtonText(e.target.value);
	};

	const handleChangeBackButtonUrl = (e) =>
	{
		setBackButtonUrl(e.target.value);
	};

	const handleChangeBackButtonTextColor = (e) =>
	{
		setBackButtonTextColor(e.target.value);
	};

	const handleChangeBackButtonBGColor = (e) =>
	{
		setBackButtonBGColor(e.target.value);
	};

	const handleChangeBackButtonTextMarginPC = (e) =>
	{
		setBackButtonTextMarginPC(e.target.value);
	};

	const handleChangeBackButtonTextMarginSP = (e) =>
	{
		setBackButtonTextMarginSP(e.target.value);
	};

	return (
		<MuiThemeProvider theme={theme}>
			<Box className={`${classes.imageGroup} ${classes.group}`}>
				<Box className={`${classes.imageRight} ${classes.wrapper}`}>
					<Typography className={classes.pageTitle}>
						待受画面設定
					</Typography>
					<Typography className={classes.smallTitle}>
						ボタンエリア設定
					</Typography>

					{ type !== 'service' &&
					<>
						<Box className={classes.waitingScreenSettingLine} >
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									PC 縦ボタンエリア配置
								</Typography>
								<Box className={classes.settingLineStart} >
									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.verticalPC}
											onChange={(e) => handleChangeBtnPosDirection('verticalPC', e.target.value)}
										>
											<MenuItem value={'top'}>上(%)</MenuItem>
											<MenuItem value={'bottom'}>下(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={btnAreaPositionObj.PC.verticCenter ? '' : btnAreaPositionObj['PC'][btnPosDirectionSelector.verticalPC]}
											disabled={btnPosDirectionSelector.verticalPC === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeBtnAreaPositionObj(
												'PC',
												btnPosDirectionSelector.verticalPC,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>
							</Box>
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									PC 横ボタンエリア配置
								</Typography>
								<Box className={classes.settingLineStart} >
									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.horizontalPC}
											onChange={(e) => handleChangeBtnPosDirection('horizontalPC', e.target.value)}
										>
											<MenuItem value={'left'}>左(%)</MenuItem>
											<MenuItem value={'right'}>右(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={btnAreaPositionObj.PC.horizCenter ? '' : btnAreaPositionObj['PC'][btnPosDirectionSelector.horizontalPC]}
											disabled={btnPosDirectionSelector.horizontalPC === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeBtnAreaPositionObj(
												'PC',
												btnPosDirectionSelector.horizontalPC,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>

							</Box>
							<Box className={classes.inputSpaceRight}>
								<Typography className={classes.buttonPosTitle}>
									スマホ 縦ボタンエリア配置
								</Typography>
								<Box className={classes.settingLineStart} >

									<Box className={classes.buttonPosInput}>
										<Select
											classes={{ root: classes.inputListSmall }}
											value={btnPosDirectionSelector.verticalSP}
											onChange={(e) => handleChangeBtnPosDirection('verticalSP', e.target.value)}
										>
											<MenuItem value={'top'}>上(%)</MenuItem>
											<MenuItem value={'bottom'}>下(%)</MenuItem>
											<MenuItem value={'center'}>中央揃え</MenuItem>
										</Select>
									</Box>

									<Box>
										<input
											value={btnAreaPositionObj.SP.verticCenter ? '' : btnAreaPositionObj['SP'][btnPosDirectionSelector.verticalSP]}
											disabled={btnPosDirectionSelector.verticalSP === 'center'}
											className={classes.inputFieldBtnPos}
											onChange={(e) => handleChangeBtnAreaPositionObj(
												'SP',
												btnPosDirectionSelector.verticalSP,
												e.target.value
											)}
											type='number'
											min='0'
											max='100'
										/>
									</Box>
								</Box>
							</Box>
						</Box>

						<Box className={classes.waitingScreenSettingLine} >

							<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
								<Typography>
									PC ボタンエリア幅
								</Typography>
								<Box>
									<input
										value={btnAreaContentObj.PC.areaWidth}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => handleChangeBtnAreaContent('PC', 'areaWidth', e.target.value)}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.buttonAreaInputArea}>
								<Typography>
									スマホ ボタンエリア幅
								</Typography>
								<Box>
									<input
										value={btnAreaContentObj.SP.areaWidth}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => handleChangeBtnAreaContent('SP', 'areaWidth', e.target.value)}
										type='text'
									/>
								</Box>
							</Box>
						</Box>

						<Box className={classes.waitingScreenSettingLine} >

							<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
								<Typography>
									PC ボタンエリア高さ
								</Typography>
								<Box>
									<input
										value={btnAreaContentObj.PC.areaHeight}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => handleChangeBtnAreaContent('PC', 'areaHeight', e.target.value)}
										type='text'
									/>
								</Box>
							</Box>

							<Box className={classes.buttonAreaInputArea}>
								<Typography>
									スマホ ボタンエリア幅
								</Typography>
								<Box>
									<input
										value={btnAreaContentObj.SP.areaHeight}
										className={`${classes.inputField} ${classes.fullWidth}`}
										onChange={(e) => handleChangeBtnAreaContent('SP', 'areaHeight', e.target.value)}
										type='text'
									/>
								</Box>
							</Box>
						</Box>
					</>
					}

					<Box className={classes.waitingScreenSettingLine} >

						<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
							<Typography>
								PC 1行のボタン個数
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.PC.columns}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('PC', 'columns', e.target.value, 'number')}
									type='number'
								/>
							</Box>
						</Box>

						<Box className={classes.buttonAreaInputArea}>
							<Typography>
								スマホ 1行のボタン個数
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.SP.columns}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('SP', 'columns', e.target.value, 'number')}
									type='number'
								/>
							</Box>
						</Box>
					</Box>

					<Typography className={classes.smallTitle}>
						ボタン共通設定
					</Typography>

					<Box className={classes.waitingScreenSettingLine} >

						<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
							<Typography>
								PC ボタン内部余白(padding)
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.PC.btnPadding}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('PC', 'btnPadding', e.target.value)}
									type='text'
								/>
							</Box>
						</Box>

						<Box className={classes.buttonAreaInputArea}>
							<Typography>
								スマホ ボタン内部余白(padding)
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.SP.btnPadding}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('SP', 'btnPadding', e.target.value)}
									type='text'
								/>
							</Box>
						</Box>
					</Box>

					<Box className={classes.waitingScreenSettingLine} >

						<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
							<Typography>
								PC ボタンマージン(margin)
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.PC.btnMargin}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('PC', 'btnMargin', e.target.value)}
									type='text'
								/>
							</Box>
						</Box>

						<Box className={classes.buttonAreaInputArea}>
							<Typography>
								スマホ ボタンマージン(margin)
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.SP.btnMargin}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('SP', 'btnMargin', e.target.value)}
									type='text'
								/>
							</Box>
						</Box>
					</Box>

					<Box className={classes.waitingScreenSettingLine} >

						<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
							<Typography>
								PC 文字サイズ(1列目)
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.PC.btnFontSize}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('PC', 'btnFontSize', e.target.value)}
									type='text'
								/>
							</Box>
						</Box>

						<Box className={classes.buttonAreaInputArea}>
							<Typography>
								スマホ 文字サイズ(1列目)
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.SP.btnFontSize}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('SP', 'btnFontSize', e.target.value)}
									type='text'
								/>
							</Box>
						</Box>
					</Box>

					<Box className={classes.waitingScreenSettingLine} >

						<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
							<Typography>
								PC 文字サイズ(2列目)
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.PC.btnFontSizeSecond}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('PC', 'btnFontSizeSecond', e.target.value)}
									type='text'
								/>
							</Box>
						</Box>

						<Box className={classes.buttonAreaInputArea}>
							<Typography>
								スマホ 文字サイズ(2列目)
							</Typography>
							<Box>
								<input
									value={btnAreaContentObj.SP.btnFontSizeSecond}
									className={`${classes.inputField} ${classes.fullWidth}`}
									onChange={(e) => handleChangeBtnAreaContent('SP', 'btnFontSizeSecond', e.target.value)}
									type='text'
								/>
							</Box>
						</Box>
					</Box>

					{ type !== 'service' &&
						<>
							<Typography className={classes.smallTitle}>
								背景設定
							</Typography>
							<Box className={classes.waitingScreenSettingLine} >

								<Box className={`${classes.gradientInputArea} ${classes.inputSpaceRight}`}>
									<Typography>
										PC 背景グラディエーション
									</Typography>
									<Box>
										<input
											value={bgPCGradient}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => setBgPCGradient(e.target.value)}
											type='text'
										/>
									</Box>
								</Box>

								<Box className={classes.gradientInputArea}>
									<Typography>
										スマホ 背景グラディエーション
									</Typography>
									<Box>
										<input
											placeholder='#FFF, #FFF'
											value={bgSPGradient}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => setBgSPGradient(e.target.value)}
											type='text'
										/>
									</Box>
								</Box>
							</Box>
							<Box className={classes.screenSetting}>
								<Box className={classes.screen}>
									<Box className={classes.imageNameGroup}>
										<ImageIcon className={classes.imageIcon}/>
										<Box className={classes.imageBorder}>
											<Typography className={classes.imageName}>
												PC 待受け背景ファイル
											</Typography>
											<Dropzone onDrop={onDropBgPC} className={classes.imageName}>
												{ ({ getRootProps, getInputProps }) => (
													<section>
														<div {...getRootProps()}>
															<input {...getInputProps()} />
															{uploadBgPCFile ?
																<Typography>{uploadBgPCFile.name}</Typography>
																:
																<Typography>ドラッグ or クリック</Typography>
															}
														</div>
													</section>
												)}
											</Dropzone>
										</Box>
									</Box>
									<Box className={classes.imageBox}>
										{ uploadBgPCUrl &&
										<img
											alt='logo'
											src={uploadBgPCUrl}
											height='100%'
											width='100%'
										/>
										}
									</Box>
								</Box>
								<Box className={classes.screen}>
									<Box className={classes.imageNameGroup}>
										<ImageIcon className={classes.imageIcon}/>
										<Box className={classes.imageBorder}>
											<Typography className={classes.imageName}>
												スマホ待受け背景ファイル
											</Typography>
											<Dropzone onDrop={onDropBgSP} className={classes.imageName}>
												{ ({ getRootProps, getInputProps }) => (
													<section>
														<div {...getRootProps()}>
															<input {...getInputProps()} />
															{uploadBgSPFile ?
																<Typography>{uploadBgSPFile.name}</Typography>
																:
																<Typography>ドラッグ or クリック</Typography>
															}
														</div>
													</section>
												)}
											</Dropzone>
										</Box>
									</Box>
									<Box className={classes.imageBox}>
										{ uploadBgSPUrl &&
										<img
											alt='logo'
											src={uploadBgSPUrl}
											height='100%'
											width='100%'
										/>
										}
									</Box>
								</Box>
							</Box>

							<Typography className={classes.smallTitle}>
								戻るボタン設定
							</Typography>

							<Box className={classes.waitingScreenSettingLine} >

								<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
									<Typography className={classes.label}>戻るボタン</Typography>
									<Select
										classes={{ root: classes.inputList }}
										value={backButtonActive}
										onChange={(e) => handleChangeBackButtonActive(e)}
									>
										<MenuItem value>有効</MenuItem>
										<MenuItem value={false}>無効</MenuItem>
									</Select>
								</Box>
							</Box>

							<Box className={`${classes.waitingScreenSettingLine}`} >
								<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
									<Typography>
										ボタンテキスト
									</Typography>
									<Box>
										<input
											value={backButtonText}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeBackButtonText(e)}
											disabled={!backButtonActive}
											type='text'
										/>
									</Box>
								</Box>

								<Box className={classes.buttonAreaInputArea}>
									<Typography>
										リンク先URL
									</Typography>
									<Box>
										<input
											value={backButtonUrl}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeBackButtonUrl(e)}
											disabled={!backButtonActive}
											type='text'
										/>
									</Box>
								</Box>
							</Box>

							<Box className={`${classes.waitingScreenSettingLine}`} >
								<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
									<Typography>
										ボタンカラー
									</Typography>
									<Box>
										<input
											value={backButtonBGColor}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeBackButtonBGColor(e)}
											disabled={!backButtonActive}
											type='text'
										/>
									</Box>
								</Box>

								<Box className={classes.buttonAreaInputArea}>
									<Typography>
										テキストカラー
									</Typography>
									<Box>
										<input
											value={backButtonTextColor}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeBackButtonTextColor(e)}
											disabled={!backButtonActive}
											type='text'
										/>
									</Box>
								</Box>
							</Box>

							<Box className={`${classes.waitingScreenSettingLine} ${classes.finalItem}`} >
								<Box className={`${classes.buttonAreaInputArea} ${classes.inputSpaceRight}`}>
									<Typography>
										PCボタン内余白
									</Typography>
									<Box>
										<input
											value={backButtonTextMarginPC}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeBackButtonTextMarginPC(e)}
											disabled={!backButtonActive}
											type='text'
										/>
									</Box>
								</Box>

								<Box className={classes.buttonAreaInputArea}>
									<Typography>
										スマホボタン内余白
									</Typography>
									<Box>
										<input
											value={backButtonTextMarginSP}
											className={`${classes.inputField} ${classes.fullWidth}`}
											onChange={(e) => handleChangeBackButtonTextMarginSP(e)}
											disabled={!backButtonActive}
											type='text'
										/>
									</Box>
								</Box>
							</Box>
						</>
					}
				</Box>
			</Box>
		</MuiThemeProvider>
	);
};

MultiBtnPageSetting.propTypes =
{
	classes                    : PropTypes.object.isRequired,
	type                       : PropTypes.string,
	bgPCGradient               : PropTypes.string,
	setBgPCGradient            : PropTypes.func.isRequired,
	bgSPGradient               : PropTypes.string,
	setBgSPGradient            : PropTypes.func.isRequired,
	uploadBgPCFile             : PropTypes.any,
	setUploadBgPCFile          : PropTypes.func.isRequired,
	uploadBgPCUrl              : PropTypes.string,
	setUploadBgPCUrl           : PropTypes.func.isRequired,
	uploadBgSPFile             : PropTypes.any,
	setUploadBgSPFile          : PropTypes.func.isRequired,
	uploadBgSPUrl              : PropTypes.string,
	setUploadBgSPUrl           : PropTypes.func.isRequired,
	btnAreaPositionObj         : PropTypes.object.isRequired,
	setBtnAreaPositionObj      : PropTypes.func.isRequired,
	btnPosDirectionSelector    : PropTypes.object.isRequired,
	setBtnPosDirectionSelector : PropTypes.func.isRequired,
	btnAreaContentObj          : PropTypes.object.isRequired,
	setBtnAreaContentObj       : PropTypes.func.isRequired,
	backButtonActive           : PropTypes.bool,
	setBackButtonActive        : PropTypes.func.isRequired,
	backButtonText             : PropTypes.string,
	setBackButtonText          : PropTypes.func,
	backButtonUrl              : PropTypes.string,
	setBackButtonUrl           : PropTypes.func,
	backButtonTextColor        : PropTypes.string,
	setBackButtonTextColor     : PropTypes.func,
	backButtonBGColor          : PropTypes.string,
	setBackButtonBGColor       : PropTypes.func,
	backButtonTextMarginPC     : PropTypes.string,
	setBackButtonTextMarginPC  : PropTypes.func,
	backButtonTextMarginSP     : PropTypes.string,
	setBackButtonTextMarginSP  : PropTypes.func
};

export default withStyles(styles)(MultiBtnPageSetting);
