// React
import React, { useState, useEffect, useCallback } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';

// Message
import { useIntl, FormattedMessage } from 'react-intl';

// Mui styles
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Mui core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

// Firebase
import { auth, functions } from '../../../lib/firebase';

const styles = (theme) =>
	({
		root : {
			display : 'flex',
			width   : '100%',
			height  : '100%'
		},
		dialogPaper : {
			width                          : '25vw',
			background                     : 'transparent',
			boxShadow                      : 'none',
			padding                        : '1%',
			fontFamily                     : '"M PLUS Rounded 1c", sans-serif',
			[theme.breakpoints.down('lg')] : {
				width : '35vw'
			},
			[theme.breakpoints.down('md')] : {
				width : '50vw'
			},
			[theme.breakpoints.down('sm')] : {
				width : '60vw'
			},
			[theme.breakpoints.down('xs')] : {
				width : '90vw'
			}
		},
		header : {
			display        : 'flex',
			alignItems     : 'flex-end',
			justifyContent : 'space-between',
			padding        : '0.5rem 0.8rem'
		},
		logo : {
			width  : 'auto',
			height : '2rem'
		},
		headerTitle : {
			color      : 'var(--text-color)',
			fontSize   : '0.9rem',
			lineHeight : '1rem',
			fontFamily : '"M PLUS Rounded 1c", sans-serif'
		},
		center : {
			width          : '100%',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'center'
		},
		title : {
			color      : 'white',
			fontSize   : '1.5rem',
			margin     : '0.8rem 0',
			fontFamily : '"M PLUS Rounded 1c", sans-serif'
		},
		inputGroup : {
			width         : '50%',
			display       : 'flex',
			flexDirection : 'column',
			alignItems    : 'flex-start'
		},
		contentTitle : {
			color        : 'white',
			fontSize     : '0.7rem',
			marginLeft   : '0rem',
			marginBottom : '0.1rem',
			fontFamily   : '"M PLUS Rounded 1c", sans-serif'
		},
		input : {
			width        : '100%',
			border       : 'none',
			marginBottom : '1.5rem'
		},
		button : {
			backgroundColor : 'var(--submit-button-color)',
			marginTop       : '1rem',
			marginBottom    : '1rem',
			'&:hover'       : {
				backgroundColor : 'var(--hover-button-color)'
			}
		},
		errorMsg : {
			width        : '400px',
			height       : '1.2rem',
			color        : '#FFF',
			marginBottom : '8px',
			fontSize     : '0.8rem',
			textAlign    : 'center'
		},
		loading : {
			width           : '100vw',
			height          : '100vh',
			zIndex          : 9999999,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		}
	});

const theme = createTheme({
	typography : {
		fontFamily : '"Hiragino Kaku Gothic Pro","Hiragino Kaku Gothic ProN","Hiragino Sans","Meiryo",Arial,sans-serif'
	},
	overrides : {
		MuiDialogTitle : {
			root : {
				padding : '0'
			}
		},
		MuiPaper : {
			rounded : {
				borderRadius : '0'
			}
		},
		MuiDialogContent : {
			root : {
				justifyContent : 'center',
				display        : 'flex',
				flexDirection  : 'column',
				alignItems     : 'center',
				overflowY      : 'none',
				padding        : '1rem 0',
				background     : 'var(--text-color)',
				borderRadius   : '0.6rem',
				boxShadow      : '3px 3px 7px #aaa'
			}
		},
		MuiButton : {
			label : {
				marginLeft  : '0.6rem',
				marginRight : '0.6rem'
			}
		},
		MuiOutlinedInput : {
			input : {
				padding         : '0.4rem 0.8rem',
				border          : 'none',
				borderRadius    : '0.3rem',
				backgroundColor : 'white',
				color           : 'var(--text-color)'
			},
			notchedOutline : {
				border : 'none'
			}
		}
	}
});

const AdminUserLoginDialog = ({
	classes,
	setAdminUserLoginDialogOpen,
	setAdminUserIdAndRights,
	setSignedIn
}) =>
{
	const intl = useIntl();
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ errorMsg, setErrorMsg ] = useState('');
	const [ isLoading, setLoading ] = useState(false);

	const handleChangeEmail = (event) =>
	{
		setEmail(event.currentTarget.value);
	};

	const handleChangePassword = (event) =>
	{
		setPassword(event.currentTarget.value);
	};

	const handleSubmit = useCallback(() =>
	{
		setLoading(true);

		const getUserRoleAPI = functions.httpsCallable('getUserRole');

		auth.signInWithEmailAndPassword(email, password)
			.then((userCredential) =>
			{
				if (userCredential)
				{

					const userId = userCredential.user?.uid;

					getUserRoleAPI({ uid: userId })
						.then((response) =>
						{
							let authenticated = false;

							if (response.data.rights.length > 0)
							{
								authenticated = true;
							}

							if (authenticated)
							{
								setAdminUserLoginDialogOpen(false);
								setSignedIn(true);

								const givenRights = {};

								response.data.rights.forEach((right) =>
								{
									givenRights[right] = true;
								});

								setAdminUserIdAndRights({
									userId : userId,
									rights : givenRights
								});
								setLoading(false);
								setErrorMsg('');
							}
							else
							{
								auth.signOut();
								setLoading(false);
								setErrorMsg('ログイン権限がありません');
							}
						})
						.catch(() =>
						{
							auth.signOut();
							setLoading(false);
							setErrorMsg('エラーが発生しました');
						});

				}
			})
			.catch((error) =>
			{
				setLoading(false);
				console.error('signInWithEmailAndPassword error:', error); // eslint-disable-line no-console
				setErrorMsg('ログインに失敗しました。IDとパスワードを確認してください');
			});
	},
	[
		email,
		password,
		setSignedIn,
		setAdminUserIdAndRights,
		setAdminUserLoginDialogOpen,
		setErrorMsg
	]);

	const loginByKeydown = useCallback((e) =>
	{

		if (e.keyCode === 13)
		{
			handleSubmit();
		}
	}, [ handleSubmit ]);

	useEffect(() =>
	{
		document.addEventListener('keydown', loginByKeydown);

		return () =>
		{
			// Clean up the subscription
			document.removeEventListener('keydown', loginByKeydown);
		};
	}, [ loginByKeydown ]);

	return (
		<>
			{
				isLoading &&
					<Box className={classes.loading}>
						<CircularProgress />
					</Box>
			}
			<MuiThemeProvider theme={theme}>
				<Dialog
					open
					classes={{
						paper : classes.dialogPaper
					}}
					BackdropProps={{
						style : {
							backgroundColor : '#e5e5e2'
						}
					}}
				>
					<DialogTitle>
						<Box className={classes.header}>
							<img
								alt='logo'
								src='images/main_logo.png'
								className={classes.logo}
							/>
							<Typography className={classes.headerTitle}>
								セカンドドア システム管理
							</Typography>
						</Box>
					</DialogTitle>
					<DialogContent>
						<Box className={classes.center}>
							<Typography className={classes.title}>
								Login
							</Typography>
						</Box>
						{ errorMsg &&
						<Typography className={classes.errorMsg}>
							{errorMsg}
						</Typography>
						}
						<Box className={classes.center}>
							<Box className={classes.inputGroup}>
								<Typography className={classes.contentTitle}>
									ID
								</Typography>
								<TextField
									autoFocus
									id='email'
									placeholder={intl.formatMessage({
										id             : 'label.username',
										defaultMessage : 'User Name'
									})}
									variant='outlined'
									classes={{
										root : classes.input
									}}
									inputProps={{
										classes : {
											input : classes.input
										},
										style : {
											fontSize : '0.75rem'
										}
									}}
									onChange={(event) => handleChangeEmail(event)}
									autoComplete='off'
								/>
							</Box>
						</Box>
						<Box className={classes.center}>
							<Box className={classes.inputGroup}>
								<Typography className={classes.contentTitle}>
									Password
								</Typography>
								<TextField
									id='password'
									placeholder={intl.formatMessage({
										id             : 'label.password',
										defaultMessage : 'Password'
									})}
									value={password}
									variant='outlined'
									name='password'
									type='password'
									classes={{
										root : classes.input
									}}
									inputProps={{
										classes : {
											input : classes.input
										},
										style : {
											fontSize : '0.75rem'
										}
									}}
									onChange={(event) => handleChangePassword(event)}
									autoComplete='off'
								/>
							</Box>
						</Box>

						<Button
							variant='contained'
							color='secondary'
							classes={{
								root : classes.button
							}}
							onClick={handleSubmit}
						>
							<FormattedMessage
								id='label.login'
								defaultMessage='Login'
							/>
						</Button>
					</DialogContent>
				</Dialog>
			</MuiThemeProvider>

		</>
	);
};

AdminUserLoginDialog.propTypes =
{
	classes                     : PropTypes.object.isRequired,
	setAdminUserLoginDialogOpen : PropTypes.func.isRequired,
	setAdminUserIdAndRights     : PropTypes.func.isRequired,
	setSignedIn                 : PropTypes.func
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setAdminUserLoginDialogOpen : (adminUserLoginDialogOpen) =>
		{
			dispatch(adminActions.setAdminUserLoginDialogOpen(adminUserLoginDialogOpen));
		},
		setAdminUserIdAndRights : (user) =>
		{
			dispatch(adminActions.setAdminUserIdAndRights(user));
		}
	};
};

export default withRoomContext(connect(
	null,
	mapDispatchToProps
)(withStyles(styles)(AdminUserLoginDialog)));
