import { CHAT_FILE, CHAT_TEXT } from '../const';

export function createNewMessage(text, time, sender, name, picture)
{
	return {
		type : CHAT_TEXT,
		text,
		time : time,
		name,
		sender,
		picture
	};
}

export function createNewFileShareMessage(
	sender,
	name,
	picture,
	fileName,
	fileShareUrl,
	time,
	error
)
{

	return {
		type : CHAT_FILE,
		time : time,
		name,
		sender,
		picture,
		fileName,
		fileShareUrl,
		error
	};
}

export function createNewMonitoringMessage(type, text, time, sender, name, picture
)
{
	return {
		type : type,
		text,
		time : time,
		name,
		sender,
		picture
	};
}

export function createNewMonitoringIconsMessage(type, icons, time, sender, name, picture
)
{
	return {
		type : type,
		icons,
		time : time,
		name,
		sender,
		picture
	};
}